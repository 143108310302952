import React, { useState, Fragment, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormGroup, FormControlLabel, RadioGroup, Radio, TextField } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import c from 'classnames';

import { getData } from '../../../utils/functions';
import SelectMenu from '../../Diagnose/BenchmarkBoard/SelectMenu';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../../Diagnose/constants';

function FlagPole({
    leftBenchmarks,
    updateLeftBenchmarks,
    updateRightBenchmarks,
    rightBenchmarks,
    goBack,
    submitReport,
    reportApiParams,
    reportIndex,
    defaultSettings,
    scoreData,
    reportError,
    isCfgClient,
    isNonProfit,
    isIngClient,
    setLastMenu,
}) {
    const { lang, resurvey_settings, ohi_text, survey_version } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUARTILE_RESURVEY } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SUB_INFO,
        PRACTICE_RANKING,
        CHART_PRACTICE_RANKING,
        CHART_PRACTICE_RANKING_BUBBLES,
        SET_FLAGPOLE_CHART,
        SUB_INFO_PRACTICE,
        DISPLAY_TORNADO_CHART,
        BACK,
        SELECT_PRIORITY_PRACTICE,
        CREATE_REPORT,
        SELECT_COMPARISON_BUBBLES,
        DYNAMIC_TEXT_INFO,
        COMPARISON_BUBBLES,
        RESURVEY_DOWNLOAD_TYPE,
        SELECT_RESURVEY_DOWNLOAD_TYPE,
        COMPARISON_BUBBLE,
        FLAGPOLE_CHART,
        PRACTICE_SCREEN,
        CATEGORY_BASED,
        EMPLYEE_EXPEIENCE,
    } = SITE_TEXT || {};
    const { REPORT_TYPE } = DYNAMIC_TEXT_INFO({ ohi_text });
    const priorityPractices = useMemo(() => {
        const mainArr = [];
        const { qbyq = {} } = scoreData;
        const { records = {} } = qbyq;
        const { ohi_score, ...dataToIterate } = records;
        Object.keys(dataToIterate).forEach(innerContent => {
            const { children = {} } = dataToIterate[innerContent];
            Object.keys(children).forEach(childSet => {
                const { display_name: label } = children[childSet];
                mainArr.push({
                    label,
                    value: childSet,
                });
            });
        });
        return mainArr;
    }, [scoreData]);

    const {
        bm_filters: bmFilter,
        need_tornado_chart: tornado_chart,
        options: opt,
        comparisonBubble: cmpBubble,
        resurveyDownloadType: resurveyOldDownloadType,
        practices: practiceArr,
        falgpole_deep_dive: flagpoleDeepDive,
        customDeltaValue: customValue,
        eeCompBubble: eeCmpBubble = '1',
        eeCustomDeltaValue: eeCustomValue,
    } = reportApiParams;
    const { ReportType } = opt;
    const [customDeltaVal, setCustomDeltaValue] = useState('');
    const [eeCustomDeltaVal, setEECustomDeltaValue] = useState('');

    const compBubbleOriginal = resurvey_settings.toLowerCase() === QUARTILE_RESURVEY ? 2 : 1;
    // const eeCompBubbleOriginal = resurvey_settings.toLowerCase() === QUARTILE_RESURVEY ? 2 : 1;

    const [flagPoleData, setFlagPoleData] = useState({
        bm_filters: bmFilter || [],
        practices: practiceArr || [],
        need_tornado_chart: tornado_chart || false,
        options: opt || {
            flagpole_footer: [
                {
                    bmcode: '',
                    bmtext: '',
                },
                {
                    bmcode: '',
                    bmtext: '',
                },
            ],
        },
        comparisonBubble: cmpBubble || `${compBubbleOriginal}`,
        resurveyDownloadType: resurveyOldDownloadType,
        falgpole_deep_dive: flagpoleDeepDive || '0',
        customDeltaValue: customValue || `${customDeltaVal}`,
        eeCompBubble: eeCmpBubble,
        eeCustomDeltaValue: eeCustomValue || `${eeCustomDeltaVal}`,
    });

    const {
        bm_filters,
        need_tornado_chart,
        comparisonBubble,
        practices,
        resurveyDownloadType,
        falgpole_deep_dive,
        customDeltaValue,
        eeCompBubble,
        eeCustomDeltaValue,
    } = flagPoleData;
    const isActive = ReportType === '3' ? comparisonBubble : bm_filters.length > 0;

    const isV4 = survey_version === '4' || survey_version === '3_2';
    const [error, setError] = useState('');
    const showCustomDelta = comparisonBubble === '3';
    const showEECustomDelta = eeCompBubble === '3';
    const activeButton = comparisonBubble === '3' ? customDeltaVal.length > 0 : isActive;

    const handleFlagPoleData = ({ name, value }) => {
        const cloneObj = { ...flagPoleData };
        if (name === 'need_tornado_chart') {
            cloneObj[name] = !need_tornado_chart;
        } else {
            cloneObj[name] = value;
        }
        setFlagPoleData(cloneObj);
    };

    const handleCustomData = val => {
        const { name, value } = val;
        const newValue = value;
        if (newValue === '' || /^[0-9\b]+$/.test(newValue)) {
            const numValue = Number(newValue);
            if (numValue < 1 || numValue >= 99) {
                setError('Value must be between 1 to 99');
            } else {
                setError('');
            }
        }
        if (name === 'customDeltaValue') {
            setCustomDeltaValue(newValue);
        } else if (name === 'eeCustomDeltaValue') {
            setEECustomDeltaValue(newValue);
        }
        const cloneObj = { ...flagPoleData };
        cloneObj[name] = newValue || '';
        setFlagPoleData(cloneObj);
    };

    const getSelectedChild = (item, control) => {
        const { code = '', selectedOption = '' } = item;
        const cloneObj = cloneDeep(flagPoleData);
        const { footer_arr = [], bm_filters_arr = [] } =
            control === 'left'
                ? getData(control, rightBenchmarks, leftBenchmarks, selectedOption, code)
                : getData(control, leftBenchmarks, rightBenchmarks, selectedOption, code);
        cloneObj.options.flagpole_footer = footer_arr;
        cloneObj.bm_filters = bm_filters_arr;
        setFlagPoleData(cloneObj);
    };

    const handlePriortyPractice = ({ value }) => {
        const { practices: priortyPractices } = flagPoleData;
        const index = priortyPractices.indexOf(value);
        if (index > -1) {
            priortyPractices.splice(index, 1);
        } else {
            priortyPractices.push(value);
        }
        setFlagPoleData({ ...flagPoleData, practices: priortyPractices });
    };

    const handleNext = () => {
        const practice = priorityPractices.filter(({ value }) => !practices.includes(value));
        let priorityPractice = '';
        if (practice.length !== priorityPractices.length) {
            priorityPractice = practice.map(({ value }) => value).join('$');
        } else {
            priorityPractice = 'DELETE';
        }
        submitReport({ ...flagPoleData, priorityPractice, bm_filters });
    };

    const renderRadioOptions = () => {
        if (!(survey_version === '4' || survey_version === '3_2')) return null;
        if (!(ReportType === '0' || ReportType === '1' || ReportType === '3')) return null;
        return (
            <div className="heatmapPlacement">
                <div className="headerTitle">{FLAGPOLE_CHART}</div>
                <div className="reportWrapper viewContent">
                    <FormGroup data-test-id="comparisonBubble" classes={{ root: 'radioGroup' }}>
                        <RadioGroup
                            aria-label="non_profit_report_full"
                            name="falgpole_deep_dive"
                            value={falgpole_deep_dive}
                            onChange={({ target }) => handleFlagPoleData(target)}
                            className="outComeVersion"
                        >
                            {[
                                { title: PRACTICE_SCREEN, key: '0' },
                                {
                                    title: CATEGORY_BASED,
                                    key: '1',
                                },
                            ].map(({ title, key }) => (
                                <div className="outcomeContent" key={key}>
                                    <FormControlLabel
                                        key={key}
                                        value={key}
                                        control={<Radio disableRipple />}
                                        label={title}
                                        className="outcomeText"
                                    />
                                </div>
                            ))}
                        </RadioGroup>
                    </FormGroup>
                    {/* <span className="sampleText" onClick={() => handleShowPractice()}>
                            <img src={viewIcon} alt="" />
                            {VIEW_SAMPLE_SLIDE}
                        </span> */}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (isActive) {
            setLastMenu(true);
        } else {
            setLastMenu(false);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <section className="flagPoleSection">
            <div className="flagPoleHeader">
                <h2>{REPORT_TYPE[reportIndex]} - </h2>
                {!isV4 && (
                    <h2 className="subHeading">
                        {ReportType === '3' ? CHART_PRACTICE_RANKING_BUBBLES : CHART_PRACTICE_RANKING}
                    </h2>
                )}
                {isV4 && (
                    <h2 className="subHeading">{ReportType === '3' ? COMPARISON_BUBBLE : CHART_PRACTICE_RANKING}</h2>
                )}
            </div>
            {ReportType === '3' ? (
                <div className="enterpriseWrapper">
                    {isCfgClient || isIngClient || isV4 ? null : (
                        <div>
                            <div className="title">{SELECT_PRIORITY_PRACTICE}</div>
                            <FormGroup classes={{ root: c('enterpriseCheckBox') }} row>
                                {priorityPractices.map(({ label, value }) => (
                                    <FormControlLabel
                                        key={label}
                                        classes={{
                                            root: c({ enterpriseLabel: practices.indexOf(value) > -1 }),
                                        }}
                                        control={
                                            <Checkbox
                                                checked={practices.indexOf(value) > -1}
                                                value={value}
                                                name={label}
                                                disabled={practices.length === 15 && practices.indexOf(value) === -1}
                                                onChange={({ target }) => handlePriortyPractice(target)}
                                                disableRipple
                                            />
                                        }
                                        label={label}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                    )}
                    <div className={c('bubbleWrapper', { isCfgClient })}>
                        <div className="title"> {SELECT_COMPARISON_BUBBLES}</div>
                        <FormGroup data-test-id="comparisonBubble" classes={{ root: 'radioGroup' }}>
                            <RadioGroup
                                aria-label="comparisonBubble"
                                name="comparisonBubble"
                                value={comparisonBubble}
                                onChange={({ target }) => handleFlagPoleData(target)}
                            >
                                {COMPARISON_BUBBLES.map(({ label, key }, i) => (
                                    <FormControlLabel
                                        key={i}
                                        value={key}
                                        control={<Radio disableRipple />}
                                        label={label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormGroup>
                    </div>

                    {showCustomDelta ? (
                        <div className="customReportDeltaContent">
                            <TextField
                                value={customDeltaValue}
                                name="customDeltaValue"
                                placeholder="Enter custom value(n)"
                                onChange={({ target }) => handleCustomData(target)}
                                error={!!error}
                                helperText={error}
                                fullWidth
                                className="select"
                                variant="outlined"
                            />
                        </div>
                    ) : null}

                    <div className={c('bubbleWrapper', { isCfgClient })}>
                        <div className="title">
                            {EMPLYEE_EXPEIENCE} {SELECT_COMPARISON_BUBBLES}
                        </div>
                        <FormGroup data-test-id="comparisonBubble" classes={{ root: 'radioGroup' }}>
                            <RadioGroup
                                aria-label="comparisonBubble"
                                name="eeCompBubble"
                                value={eeCompBubble}
                                onChange={({ target }) => handleFlagPoleData(target)}
                            >
                                {COMPARISON_BUBBLES.filter(({ key }) => key !== '2').map(({ label, key }, i) => (
                                    <FormControlLabel
                                        key={i}
                                        value={key}
                                        control={<Radio disableRipple />}
                                        label={label}
                                    />
                                ))}
                            </RadioGroup>
                        </FormGroup>
                    </div>

                    {showEECustomDelta ? (
                        <div className="customReportDeltaContent">
                            <TextField
                                value={eeCustomDeltaValue}
                                name="eeCustomDeltaValue"
                                placeholder="Enter custom value(n)"
                                onChange={({ target }) => handleCustomData(target)}
                                error={!!error}
                                helperText={error}
                                fullWidth
                                className="select"
                                variant="outlined"
                            />
                        </div>
                    ) : null}

                    {isCfgClient && (
                        <div className="bubbleWrapper">
                            <div className="title"> {SELECT_RESURVEY_DOWNLOAD_TYPE}</div>
                            <FormGroup data-test-id="resurveyDownloadType" classes={{ root: 'radioGroup' }}>
                                <RadioGroup
                                    aria-label="resurveyDownloadType"
                                    name="resurveyDownloadType"
                                    value={resurveyDownloadType}
                                    onChange={({ target }) => handleFlagPoleData(target)}
                                >
                                    {RESURVEY_DOWNLOAD_TYPE.map(({ label, key }, i) => (
                                        <FormControlLabel
                                            key={i}
                                            value={key}
                                            control={<Radio disableRipple />}
                                            label={label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormGroup>
                        </div>
                    )}
                    {reportError && <div className="errorInfo">{reportError}</div>}
                </div>
            ) : null}
            {ReportType < 3 ? (
                <div className="enterpriseWrapper">
                    <div className="title">{SET_FLAGPOLE_CHART}</div>
                    <div className="subInfo flagPoleInfo">{SUB_INFO}</div>
                    <div className="flagPoleSelecttWrapper clearfix">
                        <div>
                            <SelectMenu
                                defaultSettings={defaultSettings}
                                menuClass="reportMenu"
                                key="left"
                                control="left"
                                benchmarks={leftBenchmarks}
                                getSelectedChild={getSelectedChild}
                                updateBenchmarks={updateLeftBenchmarks}
                            />
                        </div>
                        <div>
                            <SelectMenu
                                defaultSettings={defaultSettings}
                                menuClass="reportMenu"
                                key="right"
                                control="right"
                                benchmarks={rightBenchmarks}
                                getSelectedChild={getSelectedChild}
                                updateBenchmarks={updateRightBenchmarks}
                            />
                        </div>
                    </div>
                    {renderRadioOptions()}
                    {!isNonProfit && survey_version !== '4' && (
                        <Fragment>
                            <div className="title clearfix">{PRACTICE_RANKING}</div>
                            <div className="subInfo clearfix">{SUB_INFO_PRACTICE}</div>
                            <FormGroup classes={{ root: c('enterpriseCheckBox') }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={need_tornado_chart}
                                            classes={{ root: c({ enterpriseLabel: !need_tornado_chart }) }}
                                            value={need_tornado_chart}
                                            onChange={({ target }) => handleFlagPoleData(target)}
                                            disableRipple
                                            name="need_tornado_chart"
                                        />
                                    }
                                    label={DISPLAY_TORNADO_CHART}
                                />
                            </FormGroup>
                        </Fragment>
                    )}
                </div>
            ) : null}
            <div className="buttonWrapperReports">
                <div className="outlineBtn" onClick={goBack}>
                    {BACK}
                </div>
                {/* <div className={c('blueButton', { active: isActive })} onClick={handleNext}> */}
                <div className={c('blueButton', { active: activeButton })} onClick={handleNext}>
                    {CREATE_REPORT}
                </div>
            </div>
        </section>
    );
}
FlagPole.propTypes = {
    leftBenchmarks: PropTypes.array.isRequired,
    updateLeftBenchmarks: PropTypes.func.isRequired,
    updateRightBenchmarks: PropTypes.func.isRequired,
    submitReport: PropTypes.func.isRequired,
    rightBenchmarks: PropTypes.array.isRequired,
    goBack: PropTypes.func.isRequired,
    scoreData: PropTypes.object.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    reportIndex: PropTypes.number.isRequired,
    reportError: PropTypes.string.isRequired,
    isCfgClient: PropTypes.bool.isRequired,
    isNonProfit: PropTypes.bool.isRequired,
    isIngClient: PropTypes.bool.isRequired,
    setLastMenu: PropTypes.func.isRequired,
};
export default FlagPole;
