import ChooseReportWhite from './assets/Choose_report.svg';
import ChooseReportBlack from './assets/Choose_report_black.svg';
import GeneralSettingWhite from './assets/General_settings.svg';
import GeneralSettingBlack from './assets/General_settings_black.svg';
import FlagpoleBlack from './assets/Flagpole_black.svg';
import FlagpoleWhite from './assets/Flagpole.svg';
import OptionsBlack from './assets/Demographic_options_black.svg';
import OptionsWhite from './assets/Demographic_options.svg';
import CompareBlack from './assets/Compare_black.svg';
import CompareWhite from './assets/Compare.svg';
import Bubble from './assets/images/bubble.jpg';
import scoreHeatmapImage from './assets/images/scoreHeatmap.jpg';
import Insights from './assets/images/Insights.png';
import DemoSummaryImage from './assets/images/DemoSummaryImage.png';
import PracticeDemo from './assets/images/PracticeDemo.jpg';
import PracticeDemo2 from './assets/images/PracticeDemo2.jpg';
import OutcomeImage from './assets/images/Outcome.jpg';
import PracticeOptionImage from './assets/images/Practice.jpg';
import PracticeOption from './assets/PracticeOption.svg';
import PracticeOptionBlue from './assets/PracticeOptionBlue.svg';
import OutcomeOption from './assets/OutcomeOption.svg';
import OutcomeOptionBlue from './assets/OutcomeOptionBlue.svg';
import Feature1 from './assets/images/feature_1.jpg';
import Feature2 from './assets/images/feature_2.jpg';
import Feature3 from './assets/images/feature_3.jpg';
import outComeVersion1 from './assets/images/outcome_version_1.png';
import outComeVersion2 from './assets/images/outcome_version_2.png';
import outComeVersion3 from './assets/images/outcome_version_3.png';
import practiceVersion1 from './assets/images/practice_version_1.png';
import practiceVersion2 from './assets/images/practice_version_2.png';
import deltaHeatmap1 from './assets/images/delta_heatmap_1.png';
import deltaHeatmap2 from './assets/images/delta_heatmap_2.png';
import SamplePage from './assets/images/SamplePage.png';
import ScoreHeatmap1 from './assets/images/score_heatmap_1.png';
import ScoreHeatmap2 from './assets/images/score_heatmap_2.png';
import ScoreHeatmap3 from './assets/images/score_heatmap_3.png';
import ScoreHeatmap4 from './assets/images/score_heatmap_4.png';
import CohesionHeatmap1 from './assets/images/cohesion_heatmap_1.png';
import CohesionHeatmap2 from './assets/images/cohesion_heatmap_2.png';
import CohesionHeatmap3 from './assets/images/cohesion_heatmap_3.png';
import EmployeeExperience1 from './assets/images/employee_experience_1.png';
import EmployeeExperience2 from './assets/images/employee_experience_2.png';
import EmployeeExperience3 from './assets/images/employee_experience_3.png';
import EmployeeExperience4 from './assets/images/employee_experience_4.png';
import EmployeeExperience5 from './assets/images/employee_experience_5.png';
import EmployeeExperience6 from './assets/images/employee_experience_6.png';
import ResponseRate from './assets/images/response_rate.png';
import PracticeVersionNew1 from './assets/images/practice_version_1.1.png';
import PracticeVersionNew2 from './assets/images/practice_version_2.2.png';
import oldOutcomeVersion1 from './assets/images/old_outcome_version_1.png';
import oldOutcomeVersion2 from './assets/images/old_outcome_version_2.png';
import oldOutcomeVersion3 from './assets/images/old_outcome_version_3.png';
import oldPracticeVersion1 from './assets/images/old_practice_version_1.png';
import oldPracticeVersion2 from './assets/images/old_practice_version_2.png';
import Wrench from './assets/Wrench.svg';
import WrenchActive from './assets/WrenchActive.svg';

export const DELTA_HEATMAP_IMAGES = [deltaHeatmap1, deltaHeatmap2];

export const OUTCOME_IMAGES_VIEW = [outComeVersion1, outComeVersion2, outComeVersion3];
export const PRACTICE_IMAGES_VIEW = [practiceVersion1, PracticeVersionNew1, practiceVersion2, PracticeVersionNew2];

export const SAMPLE_IMAGES = [SamplePage];

export const SCORE_HEATMAP_IMAGES = [ScoreHeatmap1, ScoreHeatmap2, ScoreHeatmap3, ScoreHeatmap4];

export const COHESION_HEATMAP_IMAGES = [CohesionHeatmap1, CohesionHeatmap2, CohesionHeatmap3];

export const EMPLOYEE_EXPERIENCE_IMAGES = [
    EmployeeExperience1,
    EmployeeExperience2,
    EmployeeExperience3,
    EmployeeExperience4,
    EmployeeExperience5,
    EmployeeExperience6,
];

export const RESPONSE_RATE_IMAGES = [ResponseRate];

export const OLD_OUTCOME_IMAGES_VIEW = [oldOutcomeVersion1, oldOutcomeVersion2, oldOutcomeVersion3];
export const OLD_PRACTICE_IMAGES_VIEW = [oldPracticeVersion1, oldPracticeVersion2];

export const NAV_LINKS = [
    {
        imgSrcActive: ChooseReportWhite,
        imgSrc: ChooseReportBlack,
        defaultNav: 0,
        mainIndex: 0,
        index: 0,
    },
    {
        imgSrcActive: GeneralSettingWhite,
        imgSrc: GeneralSettingBlack,
        defaultNav: 0,
        mainIndex: 1,
        index: 1,
    },
    {
        imgSrcActive: CompareWhite,
        imgSrc: CompareBlack,
        defaultNav: 2,
        mainIndex: 2,
        index: 2,
    },
    {
        imgSrcActive: WrenchActive,
        imgSrc: Wrench,
        defaultNav: 0,
        mainIndex: 3,
        checkReportTab: true,
        checkThomsonTabReport: true,
        // new
        checkIngRecent: true,
    },
    {
        imgSrcActive: OptionsWhite,
        imgSrc: OptionsBlack,
        defaultNav: 0,
        mainIndex: 4,
        index: 4,
    },
    {
        imgSrcActive: FlagpoleWhite,
        imgSrc: FlagpoleBlack,
        defaultNav: 2,
        checkIngClient: true,
        checkv4: true,
        mainIndex: 5,
        index: 5,
    },
    {
        imgSrcActive: FlagpoleWhite,
        imgSrc: FlagpoleBlack,
        checkIngClient: true,
        checkFlagpole: true,
        defaultNav: 1,
        mainIndex: 6,
        index: 6,
    },
    {
        imgSrcActive: OutcomeOption,
        imgSrc: OutcomeOptionBlue,
        defaultNav: 3,
        checkAccess: true,
        mainIndex: 7,
        index: 7,
    },
    {
        imgSrcActive: PracticeOption,
        imgSrc: PracticeOptionBlue,
        defaultNav: 3,
        checkAccess: true,
        mainIndex: 8,
        index: 8,
    },
];

export const DEMO_IMAGES = [
    {
        modalNo: 1,
        imgSrc: Bubble,
    },
    {
        modalNo: 2,
        imgSrc: Insights,
    },
    {
        modalNo: 3,
        imgSrc: PracticeDemo,
    },
    {
        modalNo: 3,
        imgSrc: PracticeDemo2,
    },
    {
        modalNo: 4,
        imgSrc: OutcomeImage,
    },
    {
        modalNo: 5,
        imgSrc: PracticeOptionImage,
    },
    {
        modalNo: 6,
        imgSrc: DemoSummaryImage,
    },
    {
        modalNo: 7,
        imgSrc: scoreHeatmapImage,
    },
];

export const NEW_FEATURE_IMAGES = [
    {
        modalNo: 1,
        imgSrc: Feature1,
    },
    {
        modalNo: 2,
        imgSrc: Feature2,
    },
    {
        modalNo: 3,
        imgSrc: Feature3,
    },
];

export const RESOURCE_TYPE = [
    { label: 'OHI', value: 'ohi_data_cube' },
    { label: 'Inclusion', value: 'inclusion' },
    { label: 'EX', value: 'ex_ohi' },
];
export const DOWNLOD_TYPE = [
    { label: 'Excel', checkAccess: false },
    { label: 'PPT', checkAccess: true },
];
export const LEVEL_TYPE = [1, 2, 3, 4, 5, 6];

export const REPORT_API_PARAMS = {
    ohid: '',
    lang: 1033,
    filters: [],
    year: '',
    report_type: '',
    bm_filters: [],
    benchmarks: '',
    threshold: 10,
    fill_threshold_with: '',
    n_size: '',
    report_name: '',
    heatmap_demographic: new Map(),
    sort_by: '',
    others: 0,
    need_tornado_chart: false,
    export_type: 'enterprise',
    compare_pos: 1,
    comparison_names: '',
    resource: 'ohi',
    level: 1,
    resurveyDownloadType: 'ppt',
    non_profit_report_full: '1',
    outcomePptVersion: '1',
    practicePptVersion: '1',
    comparison_heatmap: false,
    includeOhi4Demo: false,
    ohi4_incDemographics: new Map(),
    unmapped_demos: new Map(),
    options: {
        SURVEYSENT_RR: '',
        BENCHMARK_NAME: '',
        FilterSummary: '',
        BU_FilterSummary: '',
        BottomQuartileColor: '#D9D9D9',
        ThirdQuartileColor: '#9FB9BD',
        SecondQuartileColor: '#128FA7',
        TopQuartileColor: '#002960',
        TopDecileColor: '#002960',
        TopDecileBorder: '#FFD700',
        ReportType: '',
        TopDecileToggle: true,
        reportLanguage: '1033',
        IsPercentile: false,
        flagpole_footer: [
            {
                bmcode: '',
                bmtext: '',
            },
            {
                bmcode: '',
                bmtext: '',
            },
        ],
    },
    ee_threshold: 10,
    fill_ee_threshold_with: '',
};

export const RESURVEY_PARAMS = {
    ohid: '',
    lang: '',
    filters: [],
    year: '',
    report_type: '',
    benchmarks: '',
    threshold: '',
    report_name: '',
    export_type: '',
    options: {
        Resurvey_footer_BM: '',
        Resurvey_years: '',
        Resurvey_SigVsQuartile: '1',
        Resurvey_IsResurvey: 'TRUE',
        reportLanguage: '',
        ReportType: '3',
        Resurvey_Stats: '',
        SURVEYSENT_RR: '',
        resurvey_custom_delta: '',
    },
    heatmap_demographic: {},
    benchmark_str: '',
    comparison_names: '',
    compare_ids: [],
    PRIORITY_PRAC_ORDER: '',
    BENCHMARK_NAME: '',
};

export const BUILD_REPORT_PAYLOAD = {
    Intro_Contents: {
        keep: true,
    },
    Scorecard_Contents: {
        keep: true,
    },
    Outcome_Scores_Contents: {
        keep: true,
    },
    Practice_rank_score_Contents: {
        keep: true,
    },
    Cohesion_Contents: {
        keep: true,
    },
    Power_practice_recipie_Contents: {
        keep: true,
    },
    Benchmarks_Contents: {
        keep: true,
    },
    Demographic_heatmap_Contents: {
        keep: true,
    },
    Employee_Experience_Contents: {
        keep: true,
    },
    Workforce_flexibility_Contents: {
        keep: true,
    },
    Org_values_Contents: {
        keep: true,
    },
    QbyQ_Contents: {
        keep: true,
    },
    Resp_rate_Contents: {
        keep: true,
    },
    Definitions_Contents: {
        keep: true,
    },
    Practice_Scores_Contents: {
        keep: true,
    },
};

export const BUILD_REPORT_DISABLED_PAYLOAD = {
    Power_practice_recipie_Contents: {
        keep: false,
    },
    Cohesion_Contents: {
        keep: false,
    },
};

export const BUILD_REPORT_OPTION = [
    {
        label: 'Introduction to organizational health',
        key: 'Intro_Contents',
        disabled_ids: [],
    },
    { label: 'OHI scorecard', key: 'Scorecard_Contents', disabled_ids: [] },
    {
        label: 'Organizational health deep dive',
        children: [
            { label: 'Cohesion', key: 'Cohesion_Contents', disabled_ids: [1] },
            {
                label: 'Practice ranking',
                key: 'Practice_rank_score_Contents',
                disabled_ids: [],
            },
            { label: 'Outcome scores', key: 'Outcome_Scores_Contents', disabled_ids: [] },
            { label: 'Practice scores', key: 'Practice_Scores_Contents', disabled_ids: [] },
            {
                label: 'Power Practices and Recipes',
                key: 'Power_practice_recipie_Contents',
                disabled_ids: [1],
            },
            { label: 'Benchmarks', key: 'Benchmarks_Contents', disabled_ids: [] },
            {
                label: 'Demographic heat maps',
                key: 'Demographic_heatmap_Contents',
                disabled_ids: [],
            },
        ],
    },
    {
        label: 'Deep dives',
        children: [
            { label: 'Employee Experience', key: 'Employee_Experience_Contents', disabled_ids: [] },
            {
                label: 'Workplace Flexibility demographics',
                key: 'Workforce_flexibility_Contents',
                disabled_ids: [],
            },
            { label: 'Organizational values', key: 'Org_values_Contents', disabled_ids: [] },
        ],
    },
    { label: 'Question level results', key: 'QbyQ_Contents', disabled_ids: [] },
    { label: 'Response rate statistics', key: 'Resp_rate_Contents', disabled_ids: [] },
    { label: 'Definitions', key: 'Definitions_Contents', disabled_ids: [] },
];

export const DATACUBE_REPORT = 'data_cube';
export const OP_REPORT = 'op_model_excel';
export const GET_REPORT = 'GET_REPORT';
export const GET_RESURVEY_EXCEL = 'GET_RESURVEY_EXCEL';
export const GET_MAPPING_EXCEL = 'GET_MAPPING_EXCEL';
export const GET_DATA_CUBE = 'GET_DATA_CUBE';
export const UPDATE_REPORT_API_PARAMS = 'UPDATE_REPORT_API_PARAMS';
export const UPDATE_RESPONDENTS = 'UPDATE_RESPONDENTS';
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
export const UPDATE_BU_FILTERS = 'UPDATE_BU_FILTERS';
export const UPDATE_PRACTICE_BU_FILTERS = 'UPDATE_PRACTICE_BU_FILTERS';
export const SET_BULK_TOGGLE = 'SET_BULK_TOGGLE';
export const UPDATE_BULK_FILTERS = 'UPDATE_BULK_FILTERS';
export const UPDATE_BENCHMARK_DATA_FETCHED = 'UPDATE_BENCHMARK_DATA_FETCHED';
export const UPDATE_DEMOGRAHIC_DATA_FETCHED = 'UPDATE_DEMOGRAHIC_DATA_FETCHED';
export const UPDATE_FILTER_INDEX = 'UPDATE_FILTER_INDEX';
export const GET_PAST_REPORT = 'GET_PAST_REPORT';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const GET_PPT_RAW_DATA = 'GET_PPT_RAW_DATA';
export const GET_INCLUSION_DATA = 'GET_INCLUSION_DATA';
export const GET_GAP_DATA = 'GET_GAP_DATA';
export const GET_GRAPH_GAP_DATA = 'GET_GRAPH_GAP_DATA';
export const UPDATE_GRAPH_GAP_DATA = 'UPDATE_GRAPH_GAP_DATA';
export const GET_GAP_DATA_ALL = 'GET_GAP_DATA_ALL';
export const GET_RES_GAP_DATA_ALL = 'GET_RES_GAP_DATA_ALL';
export const DELETE_RESURVEY_MAPPING = 'DELETE_RESURVEY_MAPPING';
export const DELETE_SCORE_COMPARISON = 'DELETE_SCORE_COMPARISON';
export const GET_QBYQ_NSIZE = 'GET_QBYQ_NSIZE';
export const ADD_PENDING_REPORT = 'ADD_PENDING_REPORT';
export const PENDING_REPORTS_STATUS = 'PENDING_REPORTS_STATUS';
export const GET_RESPONDENTS_DATA = 'GET_RESPONDENTS_DATA';
export const UPDATE_PREV_RESPONDENTS = 'UPDATE_PREV_RESPONDENTS';
export const GET_RESURVEY_MAPPING = 'GET_RESURVEY_MAPPING';
export const GET_IA_MAPPED_DEMOGRAPHICS = 'GET_IA_MAPPED_DEMOGRAPHICS';
export const UPDATE_RESURVEY_MAPPING_FETCHED = 'UPDATE_RESURVEY_MAPPING_FETCHED';
export const GET_INCLUSION_DATA_RES = 'GET_INCLUSION_DATA_RES';
export const GET_RES_GAP_DATA = 'GET_RES_GAP_DATA';
export const PUT_PAST_REPORT = 'PUT_PAST_REPORT';
export const SET_BM_VERSION_IN_USE = 'SET_BM_VERSION_IN_USE';
export const SET_AVG_GAP_TOGGLE = 'SET_AVG_GAP_TOGGLE';
export const UPDATE_TASK_ID = 'UPDATE_TASK_ID';
export const UPDATE_PAST_REPORTS = 'UPDATE_PAST_REPORTS';
export const GET_MULTI_PPT_DATA = 'GET_MULTI_PPT_DATA';
export const SAVE_PPT_ZIP = 'SAVE_PPT_ZIP';
export const RESET_PENDING_REPORT = 'RESET_PENDING_REPORT';
export const UPDATE_COMP_PREVIOUS_RESPONDENTS = 'UPDATE_COMP_PREVIOUS_RESPONDENTS';
export const GET_BULK_SCORE_DATA = 'GET_BULK_SCORE_DATA';
export const RESET_ASSIGN_VALID = 'RESET_ASSIGN_VALID';
export const SET_ORG_LEVEL_TOGGLE = 'SET_ORG_LEVEL_TOGGLE';
