import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    getIeHeatmap,
    sortIeHeatmap,
    getEmpExpResurveyHeatmapData,
    getDemographics,
    getBenchmarks,
    updateHeatMapInfo,
    getMappedDemographics,
    getEmpExpResurveyData,
    resetEmpExpResDataFetched,
    updateFilters,
} from '../../actions';

import {
    selectApiCount,
    selectApiParams,
    selectEmpExpResurveyData,
    selectEmpExpResurveyDatFetched,
    selectFilters,
    selectProjects,
    selectProjectsFetched,
    selectSingleQuestion,
} from '../../selector';

import QuestionsBoard from './QuestionsBoard';
import { getDefaultSettings } from '../../../Login/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    defaultSettings: getDefaultSettings(),
    apiParams: selectApiParams(),
    projects: selectProjects(),
    singleQuestion: selectSingleQuestion(),
    empExpResurveyData: selectEmpExpResurveyData(),
    empExpResurveyDataFetched: selectEmpExpResurveyDatFetched(),
    projectsFetched: selectProjectsFetched(),
    filters: selectFilters(),
});

const dispatchToProps = {
    getIeHeatmap,
    sortIeHeatmap,
    getEmpExpResurveyHeatmapData,
    // resetReSurveyDataFetched,
    getDemographics,
    getBenchmarks,
    updateHeatMapInfo,
    getMappedDemographics,
    getEmpExpResurveyData,
    resetEmpExpResDataFetched,
    updateFilters,
};

export default connect(stateToProps, dispatchToProps)(QuestionsBoard);
