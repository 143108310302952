import React, { useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getStaticText } from '../../../utils/constants';
// import { chartConfig, chartConfigBenchmark } from '../constants';
import './index.scss';

function DemographicCharts({ categories, series, withBenchmark }) {
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        VIEW_BY,
        PRACTICES_SELECT,
        OUTCOME_SELECT,
        DEMOGRAPHICS_GRAPH_CLASSIFICATION,
        pcLegends,
        outLegends,
        SCORE_CLASSIFICATION_TEXT,
    } = SITE_TEXT;
    const [graphType, setGraphType] = useState(1);
    // const chartConfigToUse = withBenchmark ? chartConfigBenchmark : chartConfig;
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const data = categories[graphType].map((category, index) => {
        const dataPoint = { name: category };
        series[graphType].forEach(serie => {
            dataPoint[serie.name] = serie.data[index];
        });
        return dataPoint;
    });

    const plotBands = [
        { from: 0, to: 50, color: 'rgba(251, 187, 168, 0.5)' },
        { from: 50, to: 60, color: 'rgba(255, 204, 176, 0.5)' },
        { from: 60, to: 70, color: 'rgba(255, 241, 210, 0.5)' },
        { from: 70, to: 80, color: 'rgba(180, 230, 206, 0.5)' },
        { from: 80, to: 100, color: 'rgba(148, 218, 178, 0.5)' },
    ];

    return (
        <div className="chartSection clearfix">
            <section className="tabBar clearfix">
                <div className="buttonWrapper">
                    <span>{`${VIEW_BY} :`}</span>
                    <div className="buttonContent" value={graphType}>
                        <button
                            type="button"
                            className={c('separateButton', { isActive: graphType === 1 })}
                            onClick={() => setGraphType(1)}
                        >
                            {OUTCOME_SELECT}
                        </button>
                        <button
                            type="button"
                            className={c('separateButton', { isActive: graphType === 2 })}
                            onClick={() => setGraphType(2)}
                        >
                            {PRACTICES_SELECT}
                        </button>
                    </div>
                </div>
            </section>
            {!withBenchmark && (
                <section className="legendContent clearfix">
                    <ul className="classification clearfix">
                        <li className="normalWeight">{SCORE_CLASSIFICATION_TEXT} </li>
                        {DEMOGRAPHICS_GRAPH_CLASSIFICATION.map(({ title, background }) => (
                            <li key={title}>
                                <div style={{ background }} />
                                <div>{title}</div>
                            </li>
                        ))}
                    </ul>
                </section>
            )}

            <section className="chartContent clearfix">
                <ResponsiveContainer width="100%" height={500}>
                    <LineChart data={data} margin={{ top: 20, right: 10, left: 0, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" interval={0} />
                        <YAxis domain={[0, 100]} tickInterval={10}>
                            {plotBands.map((band, index) => (
                                <linearGradient key={index} id={`colorUv${index}`} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor={band.color} stopOpacity={1} />
                                    <stop offset="100%" stopColor={band.color} stopOpacity={0.5} />
                                </linearGradient>
                            ))}
                        </YAxis>
                        <Tooltip />
                        <Legend />
                        {series[graphType].map((serie, index) => (
                            <Line
                                key={index}
                                // type="monotone"
                                dataKey={serie.name}
                                stroke={serie.color || COLORS[index % COLORS.length]}
                                activeDot={{ r: 8 }}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </section>
            <section className="subLegends clearfix">
                {graphType === 1 ? (
                    <ul>
                        {Object.keys(pcLegends).map(pcKey => (
                            <li key={pcKey}>
                                <span> {pcKey} :</span>
                                {pcLegends[pcKey]}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <ul>
                        {Object.keys(outLegends).map(outKey => (
                            <li key={outKey}>
                                <span>{outKey} :</span>
                                {outLegends[outKey]}
                            </li>
                        ))}
                    </ul>
                )}
            </section>
        </div>
    );
}

DemographicCharts.propTypes = {
    categories: PropTypes.array.isRequired,
    series: PropTypes.array.isRequired,
    withBenchmark: PropTypes.bool.isRequired,
};

export default DemographicCharts;
