import Peers from './assets/Peers.svg';
import Leaders from './assets/Leaders.svg';
import Systems from './assets/Systems.svg';
import Overview from './assets/Overview_Deselected.svg';
import Overview_active from './assets/Overview_selected.svg';
import Scorecard from '../Diagnose/assets/Scorecard.svg';
import Scorecard_active from '../Diagnose/assets/Scorecard_active.svg';
import Demographic from '../Diagnose/assets/Demographic.svg';
import Demographic_active from '../Diagnose/assets/demographic_active.svg';
import Benchmark from '../Diagnose/assets/Benchmark.svg';
import Benchmark_active from '../Diagnose/assets/benchmark_active.svg';
import Questions from '../Diagnose/assets/Questions.svg';
import Questions_active from '../Diagnose/assets/questions_active.svg';
import PracticeRanking from '../Diagnose/assets/PracticeRanking.svg';
import PracticeRanking_active from '../Diagnose/assets/practiceranking_active.svg';
import colors from '../../sass/colors';

export const NAV_LINKS = [
    {
        imgSrc: Overview,
        imgSrcActive: Overview_active,
        itemIndex: 0,
        checkToShow: true,
    },
    {
        imgSrc: Benchmark,
        imgSrcActive: Benchmark_active,
        itemIndex: 4,
        checkToShow: true,
    },
    {
        imgSrc: Scorecard,
        imgSrcActive: Scorecard_active,
        itemIndex: 1,
    },
    {
        imgSrc: Demographic,
        imgSrcActive: Demographic_active,
        itemIndex: 3,
    },
    {
        imgSrc: Questions,
        imgSrcActive: Questions_active,
        itemIndex: 2,
    },
    {
        imgSrc: PracticeRanking,
        imgSrcActive: PracticeRanking_active,
        itemIndex: 5,
        checkToShow: true,
    },
];

export const IMAGE_PRACTICES = {
    Peer: Peers,
    Leader: Leaders,
    Systems,
};

export const PPT_PARAMS = {
    template_file_name: 'Inclusion_Template_R8_common.pptx',
    output_file_name: 'inclusion.pptx',
};

export const PPT_RES_PARAMS = {
    template_file_name: 'Inclusion_Resurvey_Template_R1.pptx',
    output_file_name: 'inclusion_resurvey.pptx',
};

export const PPT_PRAMS_CRH = {
    template_file_name: 'Inclusion_Template_R8_coach_2_0.pptx',
    output_file_name: 'inclusion.pptx',
};

export const PPT_PARAMS_CRH_OHI14649 = {
    template_file_name: 'Inclusion_Template_R8_common_CRH_OHI14649.pptx',
    output_file_name: 'inclusion.pptx',
};

export const PPT_RES_PARAMS_CRH_OHI14649 = {
    template_file_name: 'Inclusion_Resurvey_Template_R1_CRH_OHI14649.pptx',
    output_file_name: 'inclusion_resurvey.pptx',
};

export const DEMOGRAPHIC_SCORE_COLOR = [
    {
        bgColor: colors.$demoScoreColor[1][0],
        textColor: colors.$demoScoreColor[1][1],
    },
    {
        bgColor: colors.$demoScoreColor[2][0],
        textColor: colors.$demoScoreColor[2][1],
    },
    {
        bgColor: colors.$demoScoreColor[3][0],
        textColor: colors.$demoScoreColor[3][1],
    },
    {
        bgColor: colors.$demoScoreColor[4][0],
        textColor: colors.$demoScoreColor[4][1],
    },
    {
        bgColor: colors.$demoScoreColor[5][0],
        textColor: colors.$demoScoreColor[5][1],
    },
];

export const getInnerChartArr = (great = true, metaArr, key, innerArr) => {
    const condition = great ? metaArr.slice(0, metaArr.length - 3) : metaArr.slice(metaArr.length - 3);
    const setmeta = key === 'fairness' ? condition : metaArr;
    const innerChartArr = [];
    setmeta.forEach(
        ({ score: inner_score = '', na_score = '', bottom_2_score = '', meta = '', neutral_score = '' }, index) => {
            innerArr.push({
                name: `${meta}-na_score`,
                value: na_score,
            });

            innerChartArr.push(
                {
                    series: 0,
                    index,
                    value: neutral_score * -1,
                    label: neutral_score,
                },
                {
                    series: 1,
                    index,
                    value: bottom_2_score * -1,
                    label: bottom_2_score,
                },
                {
                    series: 2,
                    index,
                    value: inner_score,
                    label: inner_score,
                }
            );
        }
    );
    return innerChartArr;
};

export const getMetaScore = (meta_scores, innerArr, chartArr) => {
    meta_scores.forEach(({ score = '', na_score = '', bottom_2_score = '', meta = '', neutral_score = '' }, index) => {
        innerArr.push({
            name: `${meta}-na_score`,
            value: na_score,
        });
        chartArr.push(
            {
                series: 0,
                index,
                value: neutral_score * -1,
                label: neutral_score,
            },
            {
                series: 1,
                index,
                value: bottom_2_score * -1,
                label: bottom_2_score,
            },
            {
                series: 2,
                index,
                value: score,
                label: score,
            }
        );
    });
};

export const SET_CURRENT_OPTION = 'SET_CURRENT_OPTION';
export const SET_GAP_DATA_FETCHED = 'SET_GAP_DATA_FETCHED';
export const SET_DROPDOWN_VALUE = 'SET_DROPDOWN_VALUE';
export const SET_RESURVEY_DROPDOWN_VALUE = 'SET_RESURVEY_DROPDOWN_VALUE';
export const SET_SCORE_COLOR = 'SET_SCORE_COLOR';
export const SET_INCLUSION_BENCHMARK = 'SET_INCLUSION_BENCHMARK';
export const chartConfig = {
    title: { text: '' },
    chart: {
        height: 500,
    },
    credits: { enabled: false },
    yAxis: [
        {
            min: 0,
            max: 100,
            title: '',
            tickInterval: 10,
            gridLineColor: '#FFFFFF',
            plotBands: [
                {
                    from: 0,
                    to: 50,
                    color: 'rgba(251, 187, 168, 0.5)',
                },
                {
                    from: 50,
                    to: 60,
                    color: 'rgba(255, 204, 176, 0.5)',
                },
                {
                    from: 60,
                    to: 70,
                    color: 'rgba(255, 241, 210, 0.5)',
                },
                {
                    from: 70,
                    to: 80,
                    color: 'rgba(180, 230, 206, 0.5)',
                },
                {
                    from: 80,
                    to: 100,
                    color: 'rgba(148, 218, 178, 0.5)',
                },
            ],
        },
    ],
};

export const chartConfigBenchmark = {
    title: { text: '' },
    chart: {
        height: 500,
    },
    credits: { enabled: false },
    yAxis: [
        {
            min: 0,
            max: 100,
            title: '',
            tickInterval: 10,
            gridLineColor: '#dedede',
            plotBands: [
                {
                    from: 0,
                    to: 50,
                    color: '#FFFFFF',
                },
                {
                    from: 50,
                    to: 60,
                    color: '#FFFFFF',
                },
                {
                    from: 60,
                    to: 70,
                    color: '#FFFFFF',
                },
                {
                    from: 70,
                    to: 80,
                    color: '#FFFFFF',
                },
                {
                    from: 80,
                    to: 100,
                    color: '#FFFFFF',
                },
            ],
        },
    ],
};

export const pieChartConfig = {
    title: { text: '' },
    credits: { enabled: false },
    legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        x: 0,
        itemWidth: 200,
        itemMarginBottom: 10,
        itemMarginTop: 10,
        itemStyle: { color: 'grey', fontWeight: '600' },
        labelFormatter: function labelFormatter() {
            return `${this.name} - ${Math.round(this.y * 100) / 100}%`;
        },
    },
    tooltip: {
        pointFormat: '<b></b>',
    },
    plotOptions: {
        pie: {
            cursor: 'pointer',
            size: '100%',
            dataLabels: {
                distance: -50,
                enabled: true,
                format: '{point.percentage:.1f} %',
            },
            showInLegend: true,
        },
    },
};

export const scoreHeatMapChunk = 13;
export const resurveyHeatMapChunk = 9;
export const gapHeatMapChunk = 14;
export const graphChunk = 16;
export const resChunk = 20;

export const getStyles = [
    {
        bgcolor: '#00B050',
        text_color: '#FFFFFF',
    },
    {
        text_color: '#ffffff',
        bgcolor: '#92D050',
    },
    {
        text_color: '#000000',
        bgcolor: '#FFC000',
    },
    {
        text_color: '#ffffff',
        bgcolor: '#FC6B08',
    },
    {
        text_color: '#ffffff',
        bgcolor: '#DF2D4B',
    },
    {
        bgcolor: '#E9E0F4',
        text_color: '#000000',
    },
    {
        text_color: '#ffffff',
        bgcolor: '#9669CD',
    },
    {
        text_color: '#ffffff',
        bgcolor: '#3C1A56',
    },
    {
        text_color: '#ffffff',
        bgcolor: '#ffffff',
    },
];

export const slidesArrayObject = (inclusionData = [], shapeValue = '', styleArr) => {
    return {
        identify_shape_name: 'checkme',
        identify_shape_value: `${shapeValue}`,
        styles: styleArr,
        data: inclusionData,
    };
};

export const DEFAULT_STYLE = { bgcolor: '#ffffff', text_color: '#000000', border_color: '#ffffff' };

export const UPDATE_INCLUSION_FILTERS = 'UPDATE_INCLUSION_FILTERS';
export const UPDATE_INCLUSION_RES_FILTERS = 'UPDATE_INCLUSION_RES_FILTERS';
export const GET_ALL_GAP_INCLUSION_DATA = 'GET_ALL_GAP_INCLUSION_DATA';
export const GET_RES_ALL_GAP_INCLUSION_DATA = 'GET_RES_ALL_GAP_INCLUSION_DATA';
export const SET_PAGE_DATA_FETCHED = 'SET_PAGE_DATA_FETCHED';
export const GET_MAPPED_PROJECTS = 'GET_MAPPED_PROJECTS';
export const SET_PREVIOUS_SURVEY = 'SET_PREVIOUS_SURVEY';
export const SET_DEMO_COMPARISON_SURVEY = 'SET_DEMO_COMPARISON_SURVEY';
export const SLICE_VALUE_GAP = 7;
export const SLICE_MAX_VALUE = 8;
export const SLICE_VALUE = 6;
export const RES_SLICE_VALUE = 4;
export const RES_GAP_SLICE_VALUE = 4;
export const MAX_LENGTH_SUBSTRING = 24;
export const IA_CUSTOM_ERROR =
    'We were unable to process your request. Please refresh this page or contact OHI helpdesk for assistance';
export const CRH_CLIENT = 'OHI08445';
export const CRH_CLIENT_OHI12922 = 'OHI12922';
