import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

import { Box, MenuItem, Select } from '@material-ui/core';
import { getStaticText } from '../../../../utils/constants';
import { getColor, getNegColor } from '../../../../utils/functions';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
import Down from '../../assets/Down.svg';
import getStaticTextDiagnose from '../../constants';

const showProgress = (value, isNegative, IND_EXP_SCORE_CLASSIFICATION) => {
    const index = getColor(value, [], false, true);
    const indexNeg = getNegColor(value, [], false, true);
    const { positiveBg = '#000000', negativeBg = '#ffffff' } =
        IND_EXP_SCORE_CLASSIFICATION[[isNegative ? indexNeg : index]] || {};
    const progessStyle = {
        background: `
                    radial-gradient(closest-side, #ffffff 65%, transparent 0% 100%),
                    conic-gradient(${isNegative ? negativeBg : positiveBg} ${value}%, rgb(239, 238, 239) 0%)`,
    };
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className="progress-bar" style={progessStyle}>
                <progress value={value} min="0" max="100" style={{ height: 0, width: 0 }} />
                <div className="percentValue">{`${value}%`}</div>
            </div>
        </Box>
    );
};

function OrgHealth({
    defaultSettings,
    orgHealth,
    singleQuestion,
    getOrgHealthData,
    filterParams,
    orgHealthFetched,
    errorInfo,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    pptDownload,
    activeResetFilter,
    crossTabDemo,
    setCrossTabDemo,
}) {
    const { lang, ohid, threshold, engagement = '' } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { IND_EXP_SCORE_CLASSIFICATION, SITE_TEXT, DEEP_DIVE_TYPE } = staticText;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { IE_CROSSTAB_OPTIONS } = staticTextDiagnose;
    const {
        QUESTION_HEAD,
        OVERALL_SCORED,
        RESPONDENTS_WHO_RESPONDED,
        PERCEIVE_THE_ORGANIZATION_AS,
        HEALTHY,
        UNHEALTHY,
        EXRTREMELY_LIKELY,
        AGREE_OR_HIGHER,
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        NON_PROFIT_SURVEY,
    } = SITE_TEXT || {};

    const { records = {} } = orgHealth || {};
    const { qbyq = {}, size = '' } = singleQuestion || {};
    const { records: qbyqRecords = {} } = qbyq || {};
    const isNonProfit = engagement === NON_PROFIT_SURVEY;

    useEffect(() => {
        if (!orgHealthFetched) {
            getOrgHealthData({ ohid, lang, threshold, filters: filterParams });
        }
        // eslint-disable-next-line
    }, [orgHealthFetched]);

    return (
        <div className="scoreBoard addOns clearfix">
            <div className="indExpBoard">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{EMPLYEE_EXPEIENCE}</div>
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {!errorInfo && (
                            <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>
                {appliedFiltersInfo()}

                <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                    <div>
                        <ul className="leftNav">
                            {IND_EXP_LINKS.map((tabName, index) => {
                                return (
                                    <li
                                        data-testid={tabName}
                                        key={tabName}
                                        className={c({ activeLink: addOnTab === index })}
                                        onClick={() => setAddOnTabs(index)}
                                    >
                                        {tabName}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="deepDiveHeading">
                    {IE_CROSSTAB_OPTIONS.filter(({ code }) => code === crossTabDemo)[0].label}
                </div>
                <div className="buttonWrapper">
                    <div className="buttonContent" />
                    <div className="rightOptions">
                        <span>
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                value={crossTabDemo}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                onChange={({ target: { value: code } }) => setCrossTabDemo(code)}
                            >
                                {IE_CROSSTAB_OPTIONS.map(({ label, code, nonProfitAccess }) => {
                                    if (nonProfitAccess && isNonProfit) {
                                        return null;
                                    }

                                    return (
                                        <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </span>
                    </div>
                </div>
                <div className="demographicBoard orgHealthBoard clearfix clearPadding">
                    <div className="orgHealth">
                        <div className="orgHealthHeader">
                            <div className="col1">{QUESTION_HEAD}</div>
                            <div className="col2">{OVERALL_SCORED}</div>
                            <div className="col3">{RESPONDENTS_WHO_RESPONDED} …</div>
                            <div className="col4" />
                            <div className="col5">
                                …{PERCEIVE_THE_ORGANIZATION_AS}… <br />
                                <ul className="clearfix">
                                    <li>
                                        {' '}
                                        <span className="color healthy" />
                                        {HEALTHY}
                                    </li>
                                    <li>
                                        <span className="color unhealthy" />
                                        {UNHEALTHY}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="orgHealthBody">
                            {Object.keys(records).map((key, i) => {
                                const { corelation, healthy, unhealthy } = records[key] || {};
                                const objToUse =
                                    key === 'enps' ? qbyqRecords.endorsing_organization_to_others : qbyqRecords[key];
                                const { meta_scores, score = '' } = objToUse || {};
                                const { qtext: question = '' } = meta_scores[0] || {};
                                const sup = DEEP_DIVE_TYPE[question];
                                return (
                                    <div className="body" key={key}>
                                        <div className="col1">
                                            {question}
                                            <sup>{sup}</sup>
                                        </div>
                                        <div className="col2 percentProgress">
                                            {showProgress(
                                                score,
                                                key === 'intent_to_leave',
                                                IND_EXP_SCORE_CLASSIFICATION
                                            )}
                                        </div>
                                        <div className="col3">
                                            {i === 1 ? `"9" or "10" (${EXRTREMELY_LIKELY})` : `"4" ${AGREE_OR_HIGHER}`}
                                        </div>
                                        <div className="col4">
                                            r<sub>xy</sub>={corelation} <sup>3</sup>
                                        </div>
                                        <div className="col5">
                                            <div className="metaScore">
                                                <div className="chart">
                                                    <span
                                                        className={c({
                                                            gray: key !== 'intent_to_leave',
                                                            negative: true,
                                                        })}
                                                        style={{
                                                            width: `${unhealthy}%`,
                                                        }}
                                                    >
                                                        {unhealthy}
                                                    </span>
                                                </div>
                                                <div className="chart">
                                                    <span
                                                        className={c({ gray: key === 'intent_to_leave' })}
                                                        style={{
                                                            width: `${healthy}%`,
                                                        }}
                                                    >
                                                        {healthy}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

OrgHealth.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    orgHealth: PropTypes.object.isRequired,
    singleQuestion: PropTypes.object.isRequired,
    getOrgHealthData: PropTypes.func.isRequired,
    filterParams: PropTypes.array.isRequired,
    orgHealthFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setCrossTabDemo: PropTypes.func.isRequired,
    crossTabDemo: PropTypes.string.isRequired,
};

export default OrgHealth;
