let AUTH0 = {
    apiBasePath: 'https://stg3ohi-dev.orglabsolutions.com/api/v1',
    pptBasePath: 'https://solutions.mckinsey.com/ppservice-stg/core/api',
    clientId: '10cb236a-805a-473f-bece-8ad4b3fad13e',
    redirectBaseUrl: 'https://stg3ohi-dev.orglabsolutions.com',
    oidcConfigUrl: 'https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration',
    textAnalyticsUrl: 'https://solutions.mckinsey.com/ta-dev/',
    gsodBaseUrl: 'https://stg3ohi-dev.orglabsolutions.com/gsod/api',
};

const ENV = process.env.REACT_APP_ENVIRONMENT;
const INFRA = process.env.REACT_APP_INFRA;

if (INFRA == 'ECS') {
    if (ENV == 'dev') {
        AUTH0 = {
            apiBasePath: 'https://stg3ohi-dev.orglabsolutions.com/api/v1',
            pptBasePath: 'https://solutions.mckinsey.com/ppservice-stg/core/api',
            clientId: '10cb236a-805a-473f-bece-8ad4b3fad13e',
            redirectBaseUrl: 'https://stg3ohi-dev.orglabsolutions.com',
            oidcConfigUrl: 'https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration',
            textAnalyticsUrl: 'https://solutions.mckinsey.com/ta-dev/',
            gsodBaseUrl: 'https://stg3ohi-dev.orglabsolutions.com/gsod/api',
        };
    }
    if (ENV == 'stg') {
        AUTH0 = {
            apiBasePath: 'https://stg-analytics.orglabsolutions.com/api/v1',
            pptBasePath: 'https://solutions.mckinsey.com/ppservice-stg/core/api',
            clientId: 'ba3ce4c6-9199-4eab-b6c6-9bd7ac833242',
            redirectBaseUrl: 'https://stg-analytics.orglabsolutions.com',
            oidcConfigUrl: 'https://auth.int.mckinsey.id/auth/realms/r/.well-known/openid-configuration',
            textAnalyticsUrl: 'https://solutions.mckinsey.com/ta-dev/',
            gsodBaseUrl: 'https://stg-analytics.orglabsolutions.com/gsod/api',
        };
    }
    if (ENV == 'prod') {
        AUTH0 = {
            apiBasePath: 'https://analytics.orglabsolutions.com/api/v1',
            pptBasePath: 'https://solutions.mckinsey.com/ppservice-stg/core/api',
            clientId: '0edfd9b0-83b0-4055-965f-832f9d2ad779',
            redirectBaseUrl: 'https://analytics.orglabsolutions.com',
            oidcConfigUrl: 'https://auth.mckinsey.id/auth/realms/r/.well-known/openid-configuration',
            textAnalyticsUrl: 'https://solutions.mckinsey.com/textanalytics/',
            gsodBaseUrl: 'https://analytics.orglabsolutions.com/gsod/api',
        };
    }
} else {
    AUTH0 = {
        apiBasePath: process.env.REACT_APP_API_BASEPATH,
        pptBasePath: process.env.REACT_APP_PPT_BASEPATH,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectBaseUrl: process.env.REACT_APP_REDIRECT_BASE_URL,
        oidcConfigUrl: process.env.REACT_APP_OIDC_CONFIG_URL,
        textAnalyticsUrl: process.env.REACT_APP_TEXT_ANALYTICS_BASE_URL,
        gsodBaseUrl: process.env.REACT_APP_GSOD_API_BASEPATH,
    };
}

export default AUTH0;
