import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import AuthStore from '../../../common/AuthStore';
import { getStaticText } from '../../../utils/constants';
import { getFiltersAppliedText, getSortedChild } from '../../../utils/functions';
import { PPT_PARAMS, resChunk } from '../constants';
import FilterSvg from '../../Diagnose/assets/Filter.svg';
import ActiveFilter from '../../Diagnose/assets/ActiveFilter.svg';
import Edit from '../../Reports/assets/Edit.svg';
import CircleCheck from '../../Reports/assets/CircleCheck.svg';
import DownloadIcon from '../../Diagnose/assets/Download.svg';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import PieCharts from './PieChart';

function Respondents({
    activeFilter,
    toggleFilterView,
    inclusionqbyq,
    respondent,
    comments,
    ohid,
    lang,
    filters,
    getRespondents,
    respondentData,
    respondentFetched,
    updateRespondents,
    updateComments,
    defaultSettings,
    getPptData,
    inclusion_threshold,
    benchmarkName,
    demographics,
    benchmarkVersions,
    bmVersionInUse,
}) {
    const [isDisabled, setIsDisabled] = useState(true);
    const [isCommentsDisabled, setCommentsDisabled] = useState(true);
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const {
        FILTERS,
        RESPONDENTS_TEXT,
        INVITED_TEXT,
        RES_TO_TEXT,
        COMMENTS_TEXT,
        DOWNLOAD,
        NUMBER_TEXT,
        TOTAL_RES_TEXT,
        FILTER_HOVER_TXT,
        FILTERS_APPLIED,
        BENCHMARKS_APPLIED,
        NUMBER_CAP_SURVEY_TEXT,
        NUMBER_CAP_TEXT,
        NONE,
        INCLUSION_RESPONDENTS,
    } = SITE_TEXT;
    const { message = '', qbyq = {} } = inclusionqbyq;
    const { size = 0 } = qbyq;
    const percentage = Math.ceil((size / respondent) * 100);
    const checkLength = Object.keys(qbyq).length;
    const { report_name_or_client_name: rName = '' } = defaultSettings;
    const { pptAccessToken = '' } = AuthStore;
    const mainKeys = Object.keys(respondentData);
    const selectedBm = benchmarkVersions.filter(
        ({ BenchmarkPreCalculationSubVersionId }) => BenchmarkPreCalculationSubVersionId === bmVersionInUse
    )[0];
    const { BenchmarkPreCalculationSubVersionName: bmName = '', SurveyCount = '', RespondentCount = '' } =
        selectedBm || {};
    const benchmarkText = `${NUMBER_CAP_TEXT} = ${RespondentCount} | ${NUMBER_CAP_SURVEY_TEXT} : ${SurveyCount} | ${bmName}`;
    useEffect(() => {
        if (!respondentFetched) {
            getRespondents({
                ohid,
                filters,
                lang,
                inclusion_threshold,
            });
        }
        // eslint-disable-next-line
    }, [respondentFetched]);

    const getDataForPpt = () => {
        const commmonEle = [
            {
                name: 'Source',
                value: `${rName} (${NUMBER_TEXT} = ${size})`,
            },
            {
                name: 'checkme',
                is_delete: true,
            },
            {
                name: 'ReportName',
                value: rName,
            },
        ];
        const slides = [];
        for (let i = 0; i < mainKeys.length; i += 2) {
            const data = [];
            const arrayToIterate = mainKeys.slice(i, i + 2);
            arrayToIterate.forEach((res, resIndex) => {
                const { children = [], display_name: mainResName = '' } = respondentData[res] || {};
                const chart_data = [];
                const table_data = [];
                const { valuesToUse, othersText } = getSortedChild(children, resChunk);

                valuesToUse.forEach(({ display_name, n_size }, index) => {
                    chart_data.push(
                        {
                            index,
                            value: display_name,
                            row_index: index + 1,
                            column_index: 0,
                        },
                        {
                            series: 0,
                            index,
                            value: n_size,
                            label: n_size,
                            row_index: index + 1,
                            column_index: 1,
                        }
                    );
                    table_data.push(
                        {
                            row_index: index + 1,
                            column_index: 1,
                            value: display_name,
                        },
                        {
                            row_index: index + 1,
                            column_index: 2,
                            value: n_size,
                        }
                    );
                });

                if (table_data.length / 2 < 20)
                    table_data.push(
                        {
                            row_index: Math.floor(table_data.length / 2) + 1,
                            column_index: 0,
                            bgcolor: 'ffffff',
                            rowspan: 21 - Math.floor(table_data.length / 2),
                        },
                        {
                            row_index: Math.floor(table_data.length / 2) + 1,
                            column_index: 1,
                            bgcolor: 'ffffff',
                            rowspan: 21 - Math.floor(table_data.length / 2),
                        },
                        {
                            row_index: Math.floor(table_data.length / 2) + 1,
                            column_index: 2,
                            bgcolor: 'ffffff',
                            rowspan: 21 - Math.floor(table_data.length / 2),
                        }
                    );

                data.push(
                    {
                        name: `Demo_parent${resIndex + 1}`,
                        value: mainResName,
                    },
                    {
                        name: `Demo_parent${resIndex + 1}_chart`,
                        type: 'chart',
                        sub_type: 'Doughnut',
                        chart_data,
                    },
                    {
                        name: `demo_parent${resIndex + 1}_Table`,
                        table_data,
                    },
                    {
                        name: `demo_parent_${resIndex + 1}_others`,
                        value: othersText,
                    }
                );
            });

            if (arrayToIterate.length === 1) {
                data.push(
                    {
                        name: 'Demo_parent2',
                        is_delete: true,
                    },
                    {
                        name: 'Demo_parent2_chart',
                        type: 'chart',
                        sub_type: 'Doughnut',
                        is_delete: true,
                    },
                    {
                        name: 'demo_parent2_Table',
                        is_delete: true,
                    },
                    {
                        name: 'demo_parent2_info',
                        is_delete: true,
                    },
                    {
                        name: 'demo_parent_2_others',
                        is_delete: true,
                    }
                );
            }
            slides.push({
                identify_shape_name: 'checkme',
                identify_shape_value: 'survey_participation_statistics_2',
                is_copy: true,
                is_delete: true,
                data: [...commmonEle, ...data],
            });
        }

        let filterText = '';
        for (let i = 0; i < filters.length; i += 1) {
            const parentKey = filters[i].split(':')[0];
            const parent = respondentData[parentKey].display_name;
            let children = '';
            const arr = respondentData[parentKey].children;
            arr.forEach((item, index) => {
                if (index !== arr.length - 1) {
                    children += item.display_name + ', ';
                } else {
                    children += item.display_name;
                }
            });
            filterText = filterText + parent + ': ' + children + '\n';
        }
        slides.push({
            identify_shape_name: 'checkme',
            identify_shape_value: 'filters_applied',
            is_copy: true,
            is_delete: true,
            data: [
                {
                    name: 'Source',
                    value: `${rName} (${NUMBER_TEXT} = ${size})`,
                },
                {
                    name: 'checkme',
                    is_delete: true,
                },
                {
                    name: 'filtertext',
                    value: filterText,
                },
            ],
        });

        const output = {
            ...PPT_PARAMS,
            slides,
        };
        getPptData(output, pptAccessToken, 'Inclusion_Respondents');
    };

    return (
        <div className="questionBoard respondents clearfix">
            <Helmet>
                <title>{INCLUSION_RESPONDENTS}</title>
            </Helmet>
            <div className="underline">
                <div className="optionWrapper">
                    <div>
                        <h2>{RESPONDENTS_TEXT}</h2>
                    </div>
                    <div>
                        <ul className="rightNav">
                            {checkLength ? (
                                <li className="respondents">
                                    {TOTAL_RES_TEXT}
                                    {size}
                                </li>
                            ) : null}
                            {!message && (
                                <li data-testid="getPptBtn" className="separator" onClick={getDataForPpt}>
                                    <img src={DownloadIcon} alt="" /> <span>{DOWNLOAD}</span>
                                </li>
                            )}
                            <Tooltip arrow title={FILTER_HOVER_TXT}>
                                <li
                                    className="separator noBorder"
                                    data-testid="filterButton"
                                    onClick={() => toggleFilterView(true)}
                                >
                                    <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" /> <span>{FILTERS}</span>
                                </li>
                            </Tooltip>
                        </ul>
                    </div>
                </div>
            </div>
            {message ? (
                <ErrorToast message={message} />
            ) : (
                <section className="content clearfix">
                    <section className="headContent clearfix">
                        <div>
                            <div className="inputContent">
                                <span className={c({ activeInput: !isDisabled })}>
                                    <input
                                        data-testid="respondentInput"
                                        onChange={({ target: { value } }) =>
                                            updateRespondents(parseInt(value || 1, 10))
                                        }
                                        disabled={isDisabled}
                                        type="number"
                                        value={respondent}
                                    />
                                </span>
                                {isDisabled ? (
                                    <img
                                        data-testid="resImgActive"
                                        onClick={() => setIsDisabled(false)}
                                        src={Edit}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        data-testid="resImgInActive"
                                        onClick={() => setIsDisabled(true)}
                                        src={CircleCheck}
                                        alt=""
                                    />
                                )}
                            </div>
                            {INVITED_TEXT}
                        </div>
                        <div className="middleContent">
                            <h2>
                                {size} ({percentage}%)
                            </h2>
                            {RES_TO_TEXT}
                        </div>
                        <div>
                            <div className="inputContent">
                                <span className={c({ activeInput: !isCommentsDisabled })}>
                                    <input
                                        data-testid="commentsInput"
                                        className={c({ activeInput: !isCommentsDisabled })}
                                        onChange={({ target: { value } }) => updateComments(parseInt(value || 1, 10))}
                                        disabled={isCommentsDisabled}
                                        type="number"
                                        value={comments}
                                    />
                                </span>
                                {isCommentsDisabled ? (
                                    <img
                                        data-testid="commentsActive"
                                        onClick={() => setCommentsDisabled(false)}
                                        src={Edit}
                                        alt=""
                                    />
                                ) : (
                                    <img
                                        data-testid="commentsInactive"
                                        onClick={() => setCommentsDisabled(true)}
                                        src={CircleCheck}
                                        alt=""
                                    />
                                )}
                            </div>
                            {COMMENTS_TEXT}
                        </div>
                    </section>
                    <PieCharts respondentData={respondentData} mainKeys={mainKeys} />
                    <div className="filterInfoSection">
                        <div>
                            <b>{FILTERS_APPLIED + ' : '}</b>
                            {getFiltersAppliedText(filters, demographics)
                                ? getFiltersAppliedText(filters, demographics)
                                : NONE}{' '}
                            | n = {size}
                        </div>
                        <div>
                            <b>{BENCHMARKS_APPLIED + ' : '}</b> {`${benchmarkName} | ${benchmarkText}`}
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
}

Respondents.defaultProps = {
    benchmarkName: '',
    demographics: [],
};

Respondents.propTypes = {
    respondentData: PropTypes.object.isRequired,
    respondentFetched: PropTypes.bool.isRequired,
    respondent: PropTypes.number.isRequired,
    inclusion_threshold: PropTypes.number.isRequired,
    comments: PropTypes.number.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    inclusionqbyq: PropTypes.object.isRequired,
    ohid: PropTypes.string.isRequired,
    lang: PropTypes.number.isRequired,
    filters: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    getPptData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    updateRespondents: PropTypes.func.isRequired,
    updateComments: PropTypes.func.isRequired,
    getRespondents: PropTypes.func.isRequired,
    benchmarkName: PropTypes.string,
    demographics: PropTypes.array,
    benchmarkVersions: PropTypes.array.isRequired,
    bmVersionInUse: PropTypes.number.isRequired,
};

export default Respondents;
