import React, { useState, useEffect, useMemo } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
// import ReactHighcharts from 'react-highcharts';
import moment from 'moment';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

import { getStaticText } from '../../utils/constants';
import { RESET_TAB_REDUX } from './constants';
import DownloadIcon from '../Diagnose/assets/Download.svg';
import FilterSvg from '../Diagnose/assets/Filter.svg';
import ActiveFilter from '../Diagnose/assets/ActiveFilter.svg';
import GraphMenu from '../Inclusion/assets/GraphMenu.svg';
import GraphMenuWhite from '../Inclusion/assets/GraphMenuWhite.svg';
import Menu from '../Inclusion/assets/Menu.svg';
import MenuWhite from '../Inclusion/assets/MenuWhite.svg';
// import colors from '../../sass/colors';
import MyChart from './Charts';

function DailyResponses({
    errorInfo,
    activeFilter,
    toggleFilterView,
    apiParams,
    renderDemographicFilter,
    surveyLoadCount,
    dailyResFetched,
    getDailyResponses,
    dailyResponses,
    getExport,
    originalInd,
    setIndGlobal,
    addCustomInput,
    reloadTabData,
    defaultSettings,
    showUpdateTarget,
}) {
    const { lang, rr_threshold } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        SURVEY_LINKS,
        DOWNLOAD,
        FILTERS,
        INVITED_TEXT,
        DATE,
        COMPLETE_RES_TEXT,
        INCOMPLETE_RES_TEXT,
        INCLUSION_COMPLETED,
        PEOPLE_RES_TEXT,
        DAILY_RES_TEXT,
        TOTAL_RES_TEXT,
        SURVEY_TYPE,
        SWITCH_VIEW,
        FILTERS_MAIN,
        // PEOPLE_NUM_RES_TEXT,
        DEMOGRAPHIC_FILTERS,
        UPDATE_TARGET,
        RR_SURVEY_OPTIONS,
    } = SITE_TEXT;
    const [showGraphContent, toggleGraphContent] = useState(true);
    // const { $blue400 } = colors;
    const { result = [], n_size = 0 } = dailyResponses;
    const { ind_global } = apiParams;

    useEffect(() => {
        return () => reloadTabData(RESET_TAB_REDUX[0]);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!dailyResFetched && !surveyLoadCount) {
            getDailyResponses({ ...apiParams, threshold: rr_threshold });
        }
        // eslint-disable-next-line
    }, [dailyResFetched, apiParams]);

    const updatedData = useMemo(() => {
        const data = [];
        const metaData = [];
        let pointStart = 0;
        let pointEnd = 0;
        let percentage = 0;
        let completedRes = 0;

        result.forEach(({ response_rate = 0, completed = 0, display_name = '', total }, ind) => {
            if (!ind) {
                pointStart = moment.utc(display_name).valueOf();
            }
            if (ind === result.length - 1) {
                pointEnd = moment.utc(display_name).valueOf();
                percentage = response_rate;
                completedRes = completed;
            }
            data.push(response_rate);
            metaData.push({
                completed,
                total,
                date: moment.utc(display_name).valueOf(),
                Completed: [0, response_rate],
            });
        });
        return { data, pointStart, pointEnd, completedRes, percentage, metaData };
    }, [result]);

    const { completedRes, percentage, metaData } = updatedData;

    // const series = [
    //     {
    //         data,
    //         metaData,
    //         fillColor: $blue400,
    //         color: $blue400,
    //         marker: {
    //             fillColor: $blue400,
    //             enabled: false,
    //             radius: 2,
    //         },
    //         type: 'area',
    //         name: 'Complete',
    //         pointStart,
    //         pointInterval: 24 * 3600 * 1000,
    //     },
    // ];

    return (
        <div className="tabContent clearfix" data-testid="dailyResContent">
            <h2 className="clearfix">
                {SURVEY_LINKS[0]}
                <ul className="rightSideNav clearfix">
                    <li>
                        {TOTAL_RES_TEXT} {n_size}
                    </li>
                    {originalInd === RR_SURVEY_OPTIONS[1].code && (
                        <li>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">{SURVEY_TYPE}</InputLabel>
                                <Select
                                    value={ind_global}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        classes: { paper: 'demoDropdown' },
                                    }}
                                    onChange={({ target: { value } }) => setIndGlobal(value)}
                                >
                                    {RR_SURVEY_OPTIONS.map(({ code = '', label = '' }) => (
                                        <MenuItem key={code} value={code}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </li>
                    )}
                    {showUpdateTarget && (
                        <li onClick={() => addCustomInput(true)}>
                            <span className="updateTargetBtn">{UPDATE_TARGET}</span>
                        </li>
                    )}
                    {!errorInfo[0] && (
                        <li data-testid="getExportBtn" onClick={getExport}>
                            <img src={DownloadIcon} alt="" /> {DOWNLOAD}
                        </li>
                    )}
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" />
                        {FILTERS}
                    </li>
                </ul>
            </h2>
            {errorInfo[0] && <div className="error">{errorInfo[0]} </div>}

            {result.length ? (
                <section className="mainContent clearfix">
                    <section className="headContent clearfix">
                        <div>
                            <h2>{n_size}</h2>
                            {INVITED_TEXT}
                        </div>
                        <div className="middleContent">
                            <h2>
                                {completedRes} <span>({percentage}%)</span>
                            </h2>
                            {COMPLETE_RES_TEXT}
                        </div>
                        <div>
                            <h2>
                                {n_size - completedRes} <span>({100 - percentage}%)</span>
                            </h2>
                            {INCOMPLETE_RES_TEXT}
                        </div>
                    </section>
                    <section className="dataContent clearfix">
                        <div className="upperHeader clearfix">
                            <div className="leftHeader">
                                <h3>{DAILY_RES_TEXT}</h3>
                                <p>{PEOPLE_RES_TEXT}</p>
                            </div>
                            <div className="rightHeader">
                                <div className="switchGraph">
                                    <div
                                        data-testid="hideChartsBtn"
                                        className={c({ isActive: !showGraphContent })}
                                        onClick={() => toggleGraphContent(false)}
                                    >
                                        <img src={showGraphContent ? Menu : MenuWhite} alt="" />
                                    </div>
                                    <div
                                        data-testid="showChartsBtn"
                                        className={c({ isActive: showGraphContent })}
                                        onClick={() => toggleGraphContent(true)}
                                    >
                                        <img src={showGraphContent ? GraphMenuWhite : GraphMenu} alt="" />
                                    </div>
                                </div>
                                <div className="switchView">{SWITCH_VIEW}</div>
                            </div>
                        </div>

                        {/* {showGraphContent ? (
                            <ReactHighcharts
                                config={{
                                    ...chartConfig,
                                    series,
                                    yAxis: [
                                        {
                                            title: { text: PEOPLE_NUM_RES_TEXT },
                                            gridZIndex: 5,
                                            labels: {
                                                format: '{value}%',
                                            },
                                            min: 0,
                                            max: 100,
                                            tickInterval: 10,
                                        },
                                    ],
                                    xAxis: {
                                        type: 'datetime',
                                        min: pointStart,
                                        max: pointEnd,
                                        gridLineWidth: 1,
                                        gridZIndex: 5,
                                    },
                                }}
                            />
                        ) : null} */}
                        {showGraphContent ? <MyChart data={metaData} defaultSettings={defaultSettings} /> : null}
                        <table id="tableData" className={c('tableData', { showTable: !showGraphContent })}>
                            <tbody style={{ display: 'none' }}>
                                <tr>
                                    <th>{FILTERS_MAIN}</th>
                                </tr>
                                <tr>
                                    <td>{DEMOGRAPHIC_FILTERS}</td>
                                    {renderDemographicFilter()}
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <th>{DATE}</th>
                                    <th>{COMPLETE_RES_TEXT}</th>
                                    <th>{INCOMPLETE_RES_TEXT}</th>
                                    <th>{INCLUSION_COMPLETED}</th>
                                </tr>
                                {result.map(({ display_name, total, completed, response_rate }, parentInd) => (
                                    <tr key={parentInd}>
                                        <td>{display_name}</td>
                                        <td>{completed}</td>
                                        <td>{total - completed}</td>
                                        <td>{response_rate}%</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </section>
            ) : null}
        </div>
    );
}

DailyResponses.propTypes = {
    errorInfo: PropTypes.array.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    dailyResFetched: PropTypes.bool.isRequired,
    showUpdateTarget: PropTypes.bool.isRequired,
    originalInd: PropTypes.number.isRequired,
    surveyLoadCount: PropTypes.number.isRequired,
    apiParams: PropTypes.object.isRequired,
    dailyResponses: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getDailyResponses: PropTypes.func.isRequired,
    getExport: PropTypes.func.isRequired,
    renderDemographicFilter: PropTypes.func.isRequired,
    setIndGlobal: PropTypes.func.isRequired,
    reloadTabData: PropTypes.func.isRequired,
    addCustomInput: PropTypes.func.isRequired,
};

export default DailyResponses;
