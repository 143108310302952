import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// import { getIeHeatmap, sortIeHeatmap } from '../../actions';

import {
    selectApiCount,
    getErrorInfo,
    selectEmpExpResurveyData,
    selectEmpExpResurveyDatFetched,
    selectProjects,
    selectProjectsFetched,
    selectApiParams,
    selectFilters,
} from '../../selector';

import IndExpScorecard from './IndExpScorecard';
import { getDefaultSettings } from '../../../Login/selector';
import {
    getBenchmarks,
    getDemographics,
    getEmpExpResurveyData,
    resetEmpExpResDataFetched,
    updateFilters,
} from '../../actions';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    empExpResurveyData: selectEmpExpResurveyData(),
    empExpResurveyDataFetched: selectEmpExpResurveyDatFetched(),
    projects: selectProjects(),
    projectsFetched: selectProjectsFetched(),
    apiParams: selectApiParams(),
    filters: selectFilters(),
});

const dispatchToProps = {
    getEmpExpResurveyData,
    resetEmpExpResDataFetched,
    getDemographics,
    getBenchmarks,
    updateFilters,
};

export default connect(stateToProps, dispatchToProps)(IndExpScorecard);
