import { call, put, takeLatest, takeEvery, select, all } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import AuthStore from '../../common/AuthStore';
import {
    getScoreDataSuccess,
    getScoreDataFailed,
    getDemographicsSuccess,
    getDemographicsFailed,
    getBenchmarksSuccess,
    getBenchmarksFailed,
    getHeatMapOutcomeSuccess,
    getHeatMapOutcomeFailed,
    getPracticeDataSuccess,
    getPracticeDataFailed,
    getHeatMapInclusionSuccess,
    getHeatMapInclusionFailed,
    getHeatMapPracticeSuccess,
    getHeatMapPracticeFailed,
    getProjectsDataSuccess,
    getProjectsDataFailed,
    getReSurveyDataSuccess,
    getReSurveyDataFailed,
    getBuDataSuccess,
    getBuDataFailed,
    getPptDataSuccess,
    getPptDataFailed,
    getPptDataStatusSuccess,
    getPptDataStatusFailed,
    getToolDataSuccess,
    getToolDataFailed,
    createThemeDataSuccess,
    createThemeDataFailed,
    deleteThemeFailed,
    deleteThemeSuccess,
    getCohesionDataSuccess,
    getCohesionDataFailed,
    updateHeatMapPracticeSuccess,
    setPracticePageNo,
    setOutcomePageNo,
    setHeatMapOutcomeData,
    setHeatMapPracticeData,
    updateHeatMapOutcomeSuccess,
    setOutcomeRetrieved,
    setPracticeRetrieved,
    getValuesDataSuccess,
    getValuesDataFailed,
    resPptData,
    resPptDataSuccess,
    resPptDataFailed,
    setCohesionSortSuccess,
    setCohesionSortFailed,
    getPracticeBuDataSuccess,
    getPracticeBuDataFailed,
    getIaBenchmarksSuccess,
    getIaBenchmarksFailed,
    getTextCommentsSuccess,
    getTextCommentsFailed,
    getMappedDemographicsSuccess,
    getMappedDemographicsFailed,
    getHeatMapResurvey,
    getHeatMapResurveyOutcomeSuccess,
    getHeatMapResurveyOutcomeFailed,
    getHeatMapResurveyPracticeSuccess,
    getHeatMapResurveyPracticeFailed,
    getBenchmarkVersionSuccess,
    getBenchmarkVersionFailed,
    getNPSDataSuccess,
    getNPSDataFailed,
    getNPSDemographicDataSuccess,
    getNPSDemographicDataFailed,
    getEngagementDataSuccess,
    getEngagementDataFailed,
    getEngagementDemographicDataSuccess,
    getEngagementDemographicDataFailed,
    getClientEngagementDataSuccess,
    getClientEngagementDataFailed,
    getSingleQuestionSuccess,
    getSingleQuestionFailed,
    getIeHeatmapSuccess,
    getIeHeatmapFailed,
    getEmpExpFactorSuccess,
    getEmpExpFactorFailed,
    getOhiScoreForEmpExpSuccess,
    getOhiScoreForEmpFailed,
    getCustomBuScoreDataSuccess,
    getCustomBuScoreDataFailed,
    getCustomBuLangDataSuccess,
    getCustomBuLangDataFailed,
    getTlnxEngagementDataSuccess,
    getTlnxEngagementDataFailed,
    getCustomBuScoreData,
    getPastReportSuccess,
    getPastReportFailed,
    getOrgHealthDataSuccess,
    getOrgHealthDataFailed,
    getRankingHeatMapFailed,
    setRankingHeatmapRetrieved,
    setHeatMapRankingData,
    getRankingHeatMapSuccess,
    updateHeatMapRankingSuccess,
    setRankingPageNo,
    getComparisonYrPracticeDataSuccess,
    getComparisonYrPracticeDataFailed,
    getScoreDataForHybridSuccess,
    getScoreDataForHybridFailed,
    getLocalPracticeSuccess,
    getLocalPracticeFailed,
    sendFeedbackSuccess,
    sendFeedbackFailed,
    getFeedbackListSuccess,
    getFeedbackListFailed,
    getFeedbackDetailsSuccess,
    getFeedbackDetailsFailed,
    refreshFeedbackListSuccess,
    refreshFeedbackListFailed,
    getFeedbackList,
    getTargetScoreDataSuccess,
    getTargetScoreDataFailed,
    getEmpExpResurveyDataSuccess,
    getEmpExpResurveyDataFailed,
    getEmpExpResurveyHeatmapSuccess,
    getEmpExpResurveyHeatmapFailed,
} from './actions';
import getStaticTextDiagnose from './constants';
import {
    getScoreDataUrl,
    getFiltersUrl,
    getBenchmarksUrl,
    getHeatMapUrl,
    getHeatMapResurveyUrl,
    getPracticeRankingUrl,
    getHeatMapInlcusionUrl,
    getPracticeRankingBuUrl,
    getProjectsUrl,
    reSurveyUrl,
    buPathUrl,
    savePptUrl,
    responsePptUrl,
    getPptStatusUrl,
    getToolThemeUrl,
    createToolThemeUrl,
    deleteToolThemeUrl,
    cohesionUrl,
    cohesionUserUrl,
    getValuesDataUrl,
    getTextCommentsUrl,
    getMappedDemographicsUrl,
    getIaBenchmarksUrl,
    getNpsUrl,
    getEngagementUrl,
    getSingleScoreUrl,
    getIeHeatmapUrl,
    getTlxEngagementUrl,
    getOrgHealthUrl,
    getRankingHeatMapUrl,
    getFeedbackUrl,
    getFeedbackListUrl,
    putFeedbackListUrl,
    getEmpExpResurvey,
    getEmpExpResurveyHeatmap,
} from './apis';
import { base64ToArrayBuffer, getEngagementQuartile, parseJwt } from '../../utils/functions';
import { getStaticText } from '../../utils/constants';
import { getPptAccessApiUrl } from '../Login/apis';
import { exportPathUrl } from '../Reports/apis';
import { setBmVersionInUse } from '../Inclusion/actions';

const staticTextDiagnose = getStaticTextDiagnose();
const {
    GET_SCORE_DATA,
    GET_DEMOGRAPHICS,
    GET_BENCHMARKS,
    GET_HEATMAP_OUTCOME,
    GET_PRACTICE_DATA,
    GET_PRACTICE_BU_DATA,
    GET_HEATMAP_PRACTICE,
    GET_HEATMAP_INCLUSION,
    GET_PROJECTS,
    GET_RESURVEY_DATA,
    GET_BU_DATA,
    RES_PPT_DATA,
    GET_PPT_DATA,
    GET_PPT_STATUS,
    GET_TOOLBOX_THEME,
    CREATE_THEME,
    DELETE_THEME,
    GET_COHESION_DATA,
    UPDATE_HEAT_MAP_PRACTICE,
    UPDATE_HEAT_MAP_OUTCOME,
    GET_VALUES_DATA,
    GET_TEXT_COMMENTS,
    PERCENTILE,
    SET_COHESION_SORT,
    GET_IA_BENCHMARK,
    GET_MAPPED_DEMOGRAPHICS,
    GET_RESURVEY_HEATMAP_OUTCOME,
    OUTCOME_VAR,
    GET_BENCHMARK_VERSION,
    GET_NPS_DATA,
    GET_NPS_DEMOGRAPHIC_DATA,
    GET_ENGAGEMENT_DATA,
    GET_ENGAGEMENT_DEMOGRAPHIC_DATA,
    GET_CLIENT_ENGAGEMENT_DATA,
    GET_SINGLE_QUESTION,
    GET_IE_HEATMAP,
    GET_EMP_EXP_HEATMAP,
    GET_OHI_EMP_EXP_HEATMAP,
    GET_CUSTOM_BU_SCORE_DATA,
    GET_CUSTOM_BU_LANG_DATA,
    GET_TLNX_ENGAGEMENT_DATA,
    GET_SINGLE_PAST_REPORT,
    GET_ORGHEALTH_DATA,
    PR_VAR,
    GET_RANKING_HEATMAP,
    UPDATE_HEAT_MAP_RANKING,
    GET_COMP_YR_PRACTICE_DATA,
    GET_SCORE_DATA_HYBRID,
    GET_LOCAL_PRACTICE,
    SEND_FEEDBACK,
    GET_FEEDBACK_LIST,
    GET_FEEDBACK_DETAILS,
    REFRESH_FEEDBACK_LIST,
    GET_TARGET_SCORE_DATA,
    GET_EMP_EXP_RES_DATA,
    GET_EMP_EXP_RES_HEATMAP_DATA,
    ENTERGY_OHI10147,
} = staticTextDiagnose;

export function* getScoreData({ payload }) {
    try {
        const { data = {} } = yield call(getScoreDataUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getScoreDataSuccess(scoreData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getScoreDataFailed({ message, error, status }));
    }
}

export function* getValueDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getValuesDataUrl, payload);
        const { data: valueData = {} } = data;
        yield put(getValuesDataSuccess(valueData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getValuesDataFailed({ message, error, status }));
    }
}

export function* getTextCommentsSaga({ payload }) {
    try {
        const { data = {} } = yield call(getTextCommentsUrl, payload);
        const { data: commentsData = {} } = data;
        yield put(getTextCommentsSuccess(commentsData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getTextCommentsFailed({ message, error, status }));
    }
}

export function* getDemographicsSaga({ payload, indexToUpdate }) {
    try {
        const { ohid, lang } = payload;
        const { data = {} } = yield call(getFiltersUrl, ohid, lang);
        const { data: demographics = [] } = data;
        yield put(getDemographicsSuccess({ indexToUpdate, demographics }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getBenchmarksFailed({ message, error, status, indexToUpdate }));
        yield put(getDemographicsFailed({ message, error, status, indexToUpdate }));
    }
}

export function* getBenchmarksSaga({ payload, indexToUpdate }) {
    try {
        const { ohid, year, lang } = payload;
        const staticText = getStaticText('1033');
        const { NON_PROFIT_SURVEY, ING_REPORT_ID } = staticText;
        const { data } = yield call(getBenchmarksUrl, { ohid, year, lang });
        let flagpoleOriginalBench = [];
        let flagpoleBenchmarks = [];
        let rightFlagBenchmarks = [];
        if (!indexToUpdate) {
            const { data: flagBenchData } = yield call(getBenchmarksUrl, { ohid, year, type: 'flagpole', lang });
            const { data: flagBenchmarks } = flagBenchData;
            flagpoleOriginalBench = cloneDeep(flagBenchmarks);
            flagpoleBenchmarks = cloneDeep(flagBenchmarks);
            rightFlagBenchmarks = cloneDeep(flagBenchmarks);
        }
        const { data: benchmarks } = data;
        const state = yield select();
        const apiParams =
            state
                .get('diagnose')
                .get('apiParams')
                .toJS() || {};
        const { engagement } = state.get('diagnose').toJS() || {};
        const { targetBm = '' } = state.get('diagnose').toJS() || {};
        const { report_type = '', benchmarks: defaultBench = '', flagPoleBench = '6003:6' } = apiParams;
        const { report_id } = AuthStore;
        const isIng = report_id === ING_REPORT_ID;
        const defaultSplit = defaultBench.split(':');
        const defaultCode = defaultSplit[1];

        const flagPoleSplit = isIng ? flagPoleBench.split(':') : defaultSplit;
        const flagPoleDefaultCode = isIng ? flagPoleSplit[1] : defaultCode;

        const targeBmArr = targetBm.split(':');
        const targetBmDefaultCode = targeBmArr[1];

        let benchmarksToUse = cloneDeep(benchmarks);
        const targetBenchmarks = cloneDeep(benchmarks);
        if (report_type === PERCENTILE) {
            benchmarksToUse = benchmarks.filter(({ code }) => code === parseInt(defaultCode, 10));
            flagpoleBenchmarks = benchmarks.filter(({ code }) => code === parseInt(defaultCode, 10));
        }
        const indexToUse = benchmarksToUse.findIndex(({ code }) => code === parseInt(defaultCode, 10));
        const flagIndexToUse = flagpoleBenchmarks.findIndex(({ code }) => code === parseInt(flagPoleDefaultCode, 10));
        const targetBenchIndexToUse = targetBenchmarks.findIndex(
            ({ code }) => code === parseInt(targetBmDefaultCode, 10)
        );

        if (indexToUse !== -1) {
            const defaultChildCode = defaultSplit[0] || '';
            const { options = [] } = benchmarksToUse[indexToUse];
            const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
            const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
            benchmarksToUse[indexToUse].selectedOption = benchmarksToUse[indexToUse].options[selectedChildIndex].code;
        }
        if (flagIndexToUse !== -1) {
            const defaultChildCode = flagPoleSplit[0] || '';
            const { options = [] } = flagpoleBenchmarks[flagIndexToUse];
            const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
            const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
            flagpoleBenchmarks[flagIndexToUse].selectedOption =
                flagpoleBenchmarks[flagIndexToUse].options[selectedChildIndex].code;
        }
        if (targetBenchIndexToUse !== -1) {
            const defaultChildCode = targeBmArr[0] || '';
            const { options = [] } = targetBenchmarks[targetBenchIndexToUse];
            const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
            const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
            targetBenchmarks[targetBenchIndexToUse].selectedOption =
                benchmarksToUse[targetBenchIndexToUse].options[selectedChildIndex].code;
        }
        if (engagement === NON_PROFIT_SURVEY && rightFlagBenchmarks[0].options[0].code === 1001) {
            rightFlagBenchmarks[0].selectedOption = 1001;
        } else {
            rightFlagBenchmarks = flagpoleBenchmarks;
        }

        yield put(
            getBenchmarksSuccess({
                indexToUpdate,
                benchmarks: benchmarksToUse,
                defaultBenchmarks: benchmarks,
                flagpoleOriginalBench,
                flagpoleBenchmarks,
                rightFlagBenchmarks,
                targetBenchmarks,
            })
        );
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getBenchmarksFailed({ message, error, status, indexToUpdate }));
    }
}

export function* getIaBenchmarksSaga({ payload, indexToUpdate = 0 }) {
    try {
        const { ohid, year, lang } = payload;
        const { data } = yield call(getBenchmarksUrl, { ohid, year, type: 'benchmark', resource: 'inclusion', lang });
        const { data: benchmarks } = data;
        yield put(
            getIaBenchmarksSuccess({
                indexToUpdate,
                benchmarks,
                defaultBenchmarks: benchmarks,
            })
        );
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getIaBenchmarksFailed({ message, error, status, indexToUpdate }));
    }
}

export function* getBenchmarkVersionSaga({ payload, indexToUpdate = 0 }) {
    try {
        const { ohid, year, version, getVersion } = payload;
        const { data } = yield call(getIaBenchmarksUrl, ohid, year, 'benchmark', 'inclusion', version, getVersion);
        const { data: benchmarks } = data;
        yield put(
            getBenchmarkVersionSuccess({
                indexToUpdate,
                benchmarks,
                defaultBenchmarks: benchmarks,
            })
        );
        if (ohid === ENTERGY_OHI10147) {
            yield put(setBmVersionInUse(1));
        }
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getBenchmarkVersionFailed({ message, error, status, indexToUpdate }));
    }
}

export function* updateHeatMapSaga({ payload }) {
    try {
        const { type = '', ...rest } = payload || {};
        let data = {};
        if (type === PR_VAR) {
            const { data: resData = {} } = yield call(getRankingHeatMapUrl, rest);
            data = resData;
        } else {
            const { data: resData = {} } = yield call(getHeatMapUrl, payload);
            data = resData;
        }
        const { data: heatMapData = {} } = data;
        const { header = [], items = [] } = heatMapData;
        if (payload.type === 'outcome') {
            yield put(updateHeatMapOutcomeSuccess({ items, isRetrieved: true }));
            if (items.length >= 50) {
                yield put(setHeatMapOutcomeData(true));
            } else {
                yield put(setOutcomeRetrieved(false));
                yield put(setOutcomePageNo(1));
            }
        } else if (payload.type === PR_VAR) {
            yield put(updateHeatMapRankingSuccess({ ...heatMapData, items: items.reverse() }));
            if (header.length >= 50) {
                yield put(setHeatMapRankingData(true));
            } else {
                yield put(setRankingHeatmapRetrieved(false));
                yield put(setRankingPageNo(1));
            }
        } else {
            yield put(updateHeatMapPracticeSuccess(heatMapData));
            if (header.length >= 50) {
                yield put(setHeatMapPracticeData(true));
            } else {
                yield put(setPracticeRetrieved(false));
                yield put(setPracticePageNo(1));
            }
        }
    } catch ({ response = {} }) {
        yield put(setHeatMapOutcomeData(false));
        yield put(setHeatMapPracticeData(false));
    }
}

export function* getHeatMapSaga({ payload }) {
    try {
        const { type = '', ...rest } = payload || {};
        let data = {};
        if (type === PR_VAR) {
            const { data: resData = {} } = yield call(getRankingHeatMapUrl, rest);
            data = resData;
        } else {
            const { data: resData = {} } = yield call(getHeatMapUrl, payload);
            data = resData;
        }
        const { data: heatMapData = {} } = data;
        if (payload.type === OUTCOME_VAR) {
            yield put(getHeatMapOutcomeSuccess({ items: heatMapData, isRetrieved: true }));
            if (heatMapData.items.length >= 50) {
                yield put(setHeatMapOutcomeData(true));
                yield put(setOutcomeRetrieved(true));
            } else {
                yield put(setOutcomeRetrieved(false));
            }
        } else if (payload.type === PR_VAR) {
            const { header = '', items = [] } = heatMapData;
            yield put(getRankingHeatMapSuccess({ ...heatMapData, items: items.reverse() }));
            if (header.length >= 50) {
                yield put(setHeatMapRankingData(true));
                yield put(setRankingHeatmapRetrieved(true));
            } else {
                yield put(setRankingHeatmapRetrieved(false));
            }
        } else {
            const { header = '' } = heatMapData;
            yield put(getHeatMapPracticeSuccess(heatMapData));
            if (header.length >= 50) {
                yield put(setHeatMapPracticeData(true));
                yield put(setPracticeRetrieved(true));
            } else {
                yield put(setPracticeRetrieved(false));
            }
        }
    } catch ({ response = {} }) {
        const { type } = payload;
        const { data: error = {}, status = '' } = response;
        if (type === OUTCOME_VAR) {
            yield put(getHeatMapOutcomeFailed({ ...error, status }));
        } else if (type === PR_VAR) {
            yield put(getRankingHeatMapFailed({ ...error, status }));
        } else {
            yield put(getHeatMapPracticeFailed({ ...error, status }));
        }
    }
}

export function* getResurveyHeatMapSaga({ payload }) {
    try {
        const { data = {} } = yield call(getHeatMapResurveyUrl, payload);
        const { data: heatMapData = {} } = data;
        const { type, page } = payload;
        if (type === OUTCOME_VAR) {
            const { items = [] } = heatMapData;
            const loadMoreData = items.length >= 50;
            yield put(getHeatMapResurveyOutcomeSuccess({ ...heatMapData, loadMoreData, page }));
            if (loadMoreData) {
                yield put(
                    getHeatMapResurvey({
                        ...payload,
                        page: page + 1,
                    })
                );
            }
        } else {
            const { header = [] } = heatMapData;
            const loadMoreData = header.length >= 50;
            yield put(getHeatMapResurveyPracticeSuccess({ ...heatMapData, loadMoreData, page }));
            if (loadMoreData) {
                yield put(
                    getHeatMapResurvey({
                        ...payload,
                        page: page + 1,
                    })
                );
            }
        }
    } catch ({ response = {} }) {
        const { data: error = {}, status = '' } = response;
        const { type } = payload;
        if (type === OUTCOME_VAR) {
            yield put(getHeatMapResurveyOutcomeFailed({ ...error, status }));
        } else {
            yield put(getHeatMapResurveyPracticeFailed({ ...error, status }));
        }
    }
}

export function* getHeatMapInclusionSaga({ payload }) {
    try {
        const { data = {} } = yield call(getHeatMapInlcusionUrl, payload);
        const { data: heatMapInclusion = {} } = data;
        yield put(getHeatMapInclusionSuccess(heatMapInclusion));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getHeatMapInclusionFailed({ message, error, status }));
    }
}

export function* getPracticesSaga({ payload }) {
    try {
        const { data = {} } = yield call(getPracticeRankingUrl, payload);
        const { data: practiceData = [] } = data;
        yield put(getPracticeDataSuccess(practiceData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getPracticeDataFailed({ message, error, status }));
    }
}

const LOCAL_PRACTICE_MAP = {
    'Consequence Management': {
        CountryWorkLocation_17: 'Interhyp',
        CountryWorkLocation_36: 'Turkey',
    },
    'Career Opportunities': {
        CountryWorkLocation_29: 'Romania',
        CountryWorkLocation_12: 'Hubs Romania',
        CountryWorkLocation_10: 'Hubs Philippines',
        CountryWorkLocation_11: 'Hubs Poland',
        CountryWorkLocation_13: 'Hubs Slovakia',
    },
    'Rewards & Recognition': {
        CountryWorkLocation_32: 'Spain',
        CountryWorkLocation_28: 'Portugal',
        CountryWorkLocation_2: 'Austria',
        CountryWorkLocation_5: 'Bulgaria',
        CountryWorkLocation_7: 'Czechia',
        CountryWorkLocation_15: 'Hungary',
        CountryWorkLocation_31: 'Slovakia',
        CountryWorkLocation_37: 'Ukraine',
    },
    'Meaningful Values': {
        CountryWorkLocation_8: 'France',
    },
    'Inspirational Leaders': {
        CountryWorkLocation_40: 'United States of America',
        CountryWorkLocation_27: 'Poland',
    },
};

export function* getLocalPracticeSaga({ payload }) {
    try {
        const { data = {} } = yield call(getHeatMapUrl, payload);
        const { data: heatMapData = {} } = data || {};
        const { header = [], items = [] } = heatMapData;
        const obj = {};
        const { items: nSizeItems = [] } = items[0] || {};
        Object.keys(LOCAL_PRACTICE_MAP).forEach(key => {
            const innerObj = LOCAL_PRACTICE_MAP[key];
            obj[key] = 0;
            Object.keys(innerObj).forEach(innerKey => {
                const index = header.findIndex(elem => elem === innerObj[innerKey]);
                const { score = 0 } = nSizeItems[index] || {};
                obj[key] += score;
            });
        });
        let str = '';
        Object.entries(obj).forEach(([key, value], i, arr) => {
            str += `${key} = ${value.toLocaleString('en-US')}${i !== arr.length - 1 ? ', ' : ''}`;
        });
        yield put(getLocalPracticeSuccess(str));
    } catch ({ response = {} }) {
        const { data = {}, status = '' } = response;
        const { error } = data;
        yield put(getLocalPracticeFailed({ error, status }));
    }
}

export function* getPracticeRankBuSaga({ payload }) {
    try {
        const { data = {} } = yield call(getPracticeRankingBuUrl, payload);
        const { data: buData = [] } = data;
        yield put(getPracticeBuDataSuccess(buData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getPracticeBuDataFailed({ message, error, status }));
    }
}

export function* getProjectsSaga({ payload }) {
    try {
        const { data = [] } = yield call(getProjectsUrl, payload);
        const { data: projects = [] } = data;
        yield put(getProjectsDataSuccess(projects));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getProjectsDataFailed({ message, error, status }));
    }
}

export function* getReSurveyData({ payload }) {
    try {
        const { data = {} } = yield call(reSurveyUrl, payload);
        const { data: reSruveyInfo = [] } = data;
        yield put(getReSurveyDataSuccess(reSruveyInfo));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getReSurveyDataFailed({ message, error, status }));
    }
}

export function* getBuDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(buPathUrl, payload);
        const { data: buData = {} } = data;
        yield put(getBuDataSuccess(buData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getBuDataFailed({ message, error, status }));
    }
}

export function* savePptSaga({ payload, token, pptName, countToAdd, taskId, report_name, downloadType }) {
    try {
        const { pptTokenExp = 0 } = AuthStore;
        const currentTime = Math.floor(Date.now() / 1000);
        let newToken = '';
        if (currentTime > pptTokenExp) {
            const { data: pptAccessData = {} } = yield call(getPptAccessApiUrl);
            const { data: accessData = {} } = pptAccessData;
            const { id_token, token_type } = accessData;
            newToken = `${token_type} ${id_token}`;
            const { exp = 0 } = parseJwt(newToken);
            AuthStore.pptAccessToken = newToken;
            AuthStore.pptTokenExp = exp;
        }
        const tokenToUse = newToken || token;
        const response = yield call(savePptUrl, payload, tokenToUse, taskId, downloadType);
        const { data = {} } = response;
        const saveByteArray = (() => {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style = 'display: none';
            return (dataToUse, name) => {
                const blob = new Blob(dataToUse, { type: 'octet/stream' });
                const url = window.URL.createObjectURL ? window.URL.createObjectURL(blob) : '';
                a.href = url;
                a.download = name;
                a.click();
                if (window.URL.revokeObjectURL) {
                    window.URL.revokeObjectURL(url);
                }
            };
        })();
        const { FileData = '' } = data;
        const sampleBytes = base64ToArrayBuffer(FileData);
        saveByteArray([sampleBytes], `${pptName}${downloadType === 'pdf' ? '.pdf' : '.pptx'}`);
        yield put(getPptDataSuccess({ response, countToAdd }));
    } catch ({ response }) {
        const { data: errorData = {}, status = '' } = response || {};
        const { message = {}, error = '' } = errorData;
        if (response) {
            yield put(getPptDataFailed({ message, error, status, countToAdd }));
        } else {
            const { userName: full_name = '', userEmail: email = '' } = AuthStore;
            const resData = {
                task_id: taskId,
                email,
                full_name,
                report_name,
                response_code: '504',
                response_message: 'Gateway Timeout',
            };
            yield put(getPptDataFailed({ message, error, status: 504, countToAdd }));
            yield put(resPptData(resData));
        }
    }
}

export function* resPptSaga({ payload }) {
    try {
        const { pptAccessToken = '' } = AuthStore;
        const { data = {} } = yield call(responsePptUrl, payload, pptAccessToken);
        yield put(resPptDataSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(resPptDataFailed({ message, error, status }));
    }
}

export function* getPptStatus({ token, taskId }) {
    try {
        const { data = {} } = yield call(getPptStatusUrl, token, taskId);
        yield put(getPptDataStatusSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getPptDataStatusFailed({ message, error, status }));
    }
}

export function* getToolThemeSaga() {
    try {
        const { data = {} } = yield call(getToolThemeUrl);
        const { data: toolData = [] } = data;
        yield put(getToolDataSuccess(toolData.reverse()));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getToolDataFailed({ message, error, status }));
    }
}

export function* createThemeSaga({ payload }) {
    try {
        const { data = {} } = yield call(createToolThemeUrl, payload);
        const { data: themeData = {} } = data;
        const { id = '' } = themeData;
        yield put(createThemeDataSuccess({ ...payload, id }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(createThemeDataFailed({ message, error, status }));
    }
}

export function* deleteThemeSaga({ payload, indexToRemove }) {
    try {
        yield call(deleteToolThemeUrl, payload);
        yield put(deleteThemeSuccess(indexToRemove));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(deleteThemeFailed({ message, error, status }));
    }
}

export function* getCohesionSaga({ payload, updateScore }) {
    try {
        const { data = {} } = yield call(cohesionUrl, payload);
        const { data: cohesionData = {} } = data;
        yield put(
            getCohesionDataSuccess({
                data: cohesionData,
                updateScore,
            })
        );
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getCohesionDataFailed({ message, error, status }));
    }
}

export function* setCohesionSortSaga({ payload }) {
    try {
        yield call(cohesionUserUrl, payload);
        yield put(setCohesionSortSuccess());
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(setCohesionSortFailed({ message, error, status }));
    }
}

export function* getReportDemographics({ payload, indexToUse }) {
    try {
        const { data = {} } = yield call(getMappedDemographicsUrl, payload);
        const { data: mappedDemos = [] } = data;
        yield put(getMappedDemographicsSuccess({ mappedDemos, indexToUse }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getMappedDemographicsFailed({ message, error, status, indexToUse }));
    }
}

export function* getNPSDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getNpsUrl, payload);
        const { data: { scores: npsData = [] } = {} } = data;
        yield put(getNPSDataSuccess(npsData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getNPSDataFailed({ message, error, status }));
    }
}

export function* getNPSDemographicDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getNpsUrl, payload);
        const { data: { scores: npsData = [] } = {} } = data;
        yield put(getNPSDemographicDataSuccess(npsData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getNPSDemographicDataFailed({ message, error, status }));
    }
}

export function* getEngagementDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getEngagementUrl, payload);
        const { data: engagementData = {} } = data;
        yield put(getEngagementDataSuccess(engagementData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getEngagementDataFailed({ message, error, status }));
    }
}

export function* getEngagementDemographicDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getEngagementUrl, payload);
        const { data: engagementData } = data;
        yield put(getEngagementDemographicDataSuccess(engagementData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getEngagementDemographicDataFailed({ message, error, status }));
    }
}

export function* getClientEngagementSaga({ payload }) {
    try {
        const { data = {} } = yield call(getHeatMapUrl, payload);
        const { data: heatMapData = {} } = data;
        const { items = [] } = heatMapData;
        const { items: compData = [] } = items.filter(({ sub_demo }) => sub_demo === 'company')[0] || {};
        const { items: engagedData = [] } = items.filter(({ sub_demo }) => sub_demo === 'Engageclassify_1')[0] || {};
        const { score: totalSize = 0 } = compData[0] || {};
        const { score: engagedSize = 0 } = engagedData[0] || {};
        const engagedScore = Math.round((engagedSize * 100) / totalSize);
        const clientData = {
            score: engagedScore,
            title: 'Engagement Score',
            quartile: getEngagementQuartile(engagedScore),
        };
        yield put(getClientEngagementDataSuccess(clientData));
    } catch ({ response = {} }) {
        const { data: error = {}, status = '' } = response;
        yield put(getClientEngagementDataFailed({ ...error, status }));
    }
}

export function* getSingleQuestionSaga({ payload }) {
    try {
        const { data = {} } = yield call(getSingleScoreUrl, payload);
        const { data: quesData = {} } = data;
        yield put(getSingleQuestionSuccess(quesData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getSingleQuestionFailed({ message, error, status }));
    }
}

export function* getIeHeatmapSaga({ payload }) {
    try {
        const { data = {} } = yield call(getIeHeatmapUrl, payload);
        const { data: ieHeatmapData = {} } = data;
        yield put(getIeHeatmapSuccess(ieHeatmapData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getIeHeatmapFailed({ message, error, status }));
    }
}

export function* getEmpExpFactorSaga({ payload }) {
    try {
        const { demographic = '' } = payload || {};
        const state = yield select();
        const diagnose = state.get('diagnose').toJS() || {};
        const { empExpHeatmaps } = diagnose || {};
        const { data = {} } = yield call(getIeHeatmapUrl, payload);
        const { data: empExpData = {} } = data;
        yield put(getEmpExpFactorSuccess({ ...empExpHeatmaps, [demographic]: empExpData }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getEmpExpFactorFailed({ message, error, status }));
    }
}

export function* getOhiScoreForEmpExpSaga({ payload }) {
    try {
        const { demographic = '' } = payload || {};
        const state = yield select();
        const diagnose = state.get('diagnose').toJS() || {};
        const { empExpOhiHeatmaps } = diagnose || {};
        const { data = {} } = yield call(getHeatMapUrl, payload);
        const { data: empExpData = {} } = data;
        yield put(getOhiScoreForEmpExpSuccess({ ...empExpOhiHeatmaps, [demographic]: empExpData }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getOhiScoreForEmpFailed({ message, error, status }));
    }
}

export function* getCustomBuScoreDataSaga({ payload }) {
    try {
        const { payloads = [], level = '', calls = 0 } = payload;
        const state = yield select();
        const diagnose = state.get('diagnose').toJS() || {};
        const { filterColumns } = diagnose || {};
        if (!calls) {
            const obj = { [`level${level}`]: [] };
            const results = [];
            const currYearResult = yield call(getScoreDataUrl, payloads[0]);
            results.push(currYearResult);
            try {
                const prevYearResult = yield call(getScoreDataUrl, payloads[1]);
                results.push(prevYearResult);
            } catch (error) {
                results.push({});
            }
            for (let i = 0; i < results.length; i += 1) {
                const { data: columnData = {} } = results[i];
                const { data = {} } = columnData || {};
                const { qbyq: scoreData = {} } = data || {};
                obj[`level${level}`].push(scoreData);
            }
            yield put(getCustomBuScoreDataSuccess({ ...filterColumns, ...obj }));
        } else {
            const { payloads: payloadArr = [] } = payload;
            const { level: innerLevel = '', payloads: payloadToUse = [] } = payloadArr[payloadArr.length - 1 - calls];
            const results = yield all(payloadToUse.map(pld => call(getScoreDataUrl, pld)));
            const obj = { [`level${innerLevel}`]: [] };
            const nextCall = calls - 1;
            for (let i = 0; i < results.length; i += 1) {
                const { data = {} } = results[i].data || {};
                const { qbyq: scoreData = {} } = data || {};
                obj[`level${innerLevel}`].push(scoreData);
            }
            yield put(getCustomBuScoreDataSuccess({ ...filterColumns, ...obj }));
            if (!nextCall) {
                yield put(getCustomBuScoreData({ ...payloadArr[payloadArr.length - 1 - nextCall] }));
            } else {
                yield put(getCustomBuScoreData({ ...payload, calls: nextCall }));
            }
        }
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getCustomBuScoreDataFailed({ message, error, status }));
    }
}

export function* getCustomBuLangData({ payload }) {
    try {
        const { data = {} } = yield call(getScoreDataUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getCustomBuLangDataSuccess([scoreData]));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getCustomBuLangDataFailed({ message, error, status }));
    }
}

export function* getTalnxEngagementData({ payload }) {
    try {
        const { data = {} } = yield call(getTlxEngagementUrl, payload);
        const { data: scoreData = {} } = data;
        const { scores = [] } = scoreData || {};
        yield put(getTlnxEngagementDataSuccess(scores));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getTlnxEngagementDataFailed({ message, error, status }));
    }
}

export function* getExportSaga({ payload }) {
    try {
        const { data } = yield call(exportPathUrl, payload);
        const { data: pastData = {} } = data;
        const { records = [] } = pastData;
        pastData.records = [...records].reverse();
        yield put(getPastReportSuccess(pastData.records[0]));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {} } = errorData;
        yield put(getPastReportFailed({ message, status }));
    }
}

export function* getOrgHealthData({ payload }) {
    try {
        const { data = {} } = yield call(getOrgHealthUrl, payload);
        const { data: orgData = {} } = data;
        yield put(getOrgHealthDataSuccess(orgData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getOrgHealthDataFailed({ message, error, status }));
    }
}

export function* getCompYrPracticesSaga({ payload }) {
    const state = yield select();
    const diagnoseData = state.get('diagnose').toJS() || {};
    const { practiceDataCompYr = {} } = diagnoseData || {};
    try {
        const { ohid = '' } = payload || {};
        const { data = {} } = yield call(getPracticeRankingUrl, payload);
        const { data: practiceData = {} } = data;
        yield put(getComparisonYrPracticeDataSuccess({ data: practiceData, ohid, prevData: practiceDataCompYr }));
    } catch ({ response = {} }) {
        const { ohid = '' } = payload || {};
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getComparisonYrPracticeDataFailed({ message, error, status, ohid, prevData: practiceDataCompYr }));
    }
}

export function* getScoreDataForHybrid({ payload }) {
    try {
        const { data = {} } = yield call(getScoreDataUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getScoreDataForHybridSuccess(scoreData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getScoreDataForHybridFailed({ message, error, status }));
    }
}

export function* sendFeedbackSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFeedbackUrl, payload);

        const { data: feedbackData = {} } = data || {};
        yield put(sendFeedbackSuccess(feedbackData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(sendFeedbackFailed({ message, error, status }));
    }
}

export function* getFeedbackListSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFeedbackListUrl, payload);
        const { data: feedbackData = [] } = data;
        yield put(getFeedbackListSuccess(feedbackData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFeedbackListFailed({ message, error, status }));
    }
}

export function* getFeedbackDetailsSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFeedbackListUrl, payload);
        const { data: feedbackDetails = {} } = data;
        yield put(getFeedbackDetailsSuccess(feedbackDetails));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFeedbackDetailsFailed({ message, error, status }));
    }
}

export function* refreshFeedbackListSaga({ payload }) {
    try {
        const { feedback_id, user_id } = payload || {};
        const { data } = yield call(putFeedbackListUrl, payload);
        const { data: feedbackDetails = {} } = data;
        yield put(refreshFeedbackListSuccess({ data: feedbackDetails, type: feedback_id === 0 ? 0 : 1 }));
        if (feedback_id === 0) {
            yield put(getFeedbackList({ user_id }));
        }
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(refreshFeedbackListFailed({ message, error, status }));
    }
}

export function* getTargetScoreData({ payload }) {
    try {
        const { data = {} } = yield call(getScoreDataUrl, payload);
        const { data: scoreData = {} } = data;
        yield put(getTargetScoreDataSuccess(scoreData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getTargetScoreDataFailed({ message, error, status }));
    }
}

export function* getEmpExpResurveySaga({ payload }) {
    try {
        const { data = {} } = yield call(getEmpExpResurvey, payload);
        const { data: empExpData = {} } = data;
        yield put(getEmpExpResurveyDataSuccess(empExpData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getEmpExpResurveyDataFailed({ message, error, status }));
    }
}

export function* getEmpExpResurveyHeatmapSaga({ payload }) {
    try {
        const { data = {} } = yield call(getEmpExpResurveyHeatmap, payload);
        const { data: heatmapData = {} } = data;
        yield put(getEmpExpResurveyHeatmapSuccess(heatmapData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getEmpExpResurveyHeatmapFailed({ message, error, status }));
    }
}

const diagnoseSagas = [
    takeLatest(`${GET_SCORE_DATA}_PENDING`, getScoreData),
    takeEvery(`${GET_DEMOGRAPHICS}_PENDING`, getDemographicsSaga),
    takeEvery(`${GET_BENCHMARKS}_PENDING`, getBenchmarksSaga),
    takeEvery(`${GET_IA_BENCHMARK}_PENDING`, getIaBenchmarksSaga),
    takeEvery(`${GET_BENCHMARK_VERSION}_PENDING`, getBenchmarkVersionSaga),
    takeLatest(`${GET_HEATMAP_OUTCOME}_PENDING`, getHeatMapSaga),
    takeLatest(`${GET_HEATMAP_PRACTICE}_PENDING`, getHeatMapSaga),
    takeLatest(`${GET_RANKING_HEATMAP}_PENDING`, getHeatMapSaga),
    takeLatest(`${GET_RESURVEY_HEATMAP_OUTCOME}_PENDING`, getResurveyHeatMapSaga),
    takeLatest(`${GET_HEATMAP_INCLUSION}_PENDING`, getHeatMapInclusionSaga),
    takeLatest(`${GET_PRACTICE_DATA}_PENDING`, getPracticesSaga),
    takeLatest(`${GET_PRACTICE_BU_DATA}_PENDING`, getPracticeRankBuSaga),
    takeLatest(`${GET_PROJECTS}_PENDING`, getProjectsSaga),
    takeLatest(`${GET_RESURVEY_DATA}_PENDING`, getReSurveyData),
    takeLatest(`${GET_BU_DATA}_PENDING`, getBuDataSaga),
    takeLatest(`${GET_PPT_DATA}_PENDING`, savePptSaga),
    takeLatest(`${RES_PPT_DATA}_PENDING`, resPptSaga),
    takeLatest(`${GET_PPT_STATUS}_PENDING`, getPptStatus),
    takeLatest(`${GET_TOOLBOX_THEME}_PENDING`, getToolThemeSaga),
    takeLatest(`${CREATE_THEME}_PENDING`, createThemeSaga),
    takeLatest(`${DELETE_THEME}_PENDING`, deleteThemeSaga),
    takeLatest(`${GET_COHESION_DATA}_PENDING`, getCohesionSaga),
    takeLatest(`${UPDATE_HEAT_MAP_PRACTICE}_PENDING`, updateHeatMapSaga),
    takeLatest(`${UPDATE_HEAT_MAP_OUTCOME}_PENDING`, updateHeatMapSaga),
    takeLatest(`${UPDATE_HEAT_MAP_RANKING}_PENDING`, updateHeatMapSaga),
    takeLatest(`${GET_VALUES_DATA}_PENDING`, getValueDataSaga),
    takeLatest(`${GET_TEXT_COMMENTS}_PENDING`, getTextCommentsSaga),
    takeLatest(`${SET_COHESION_SORT}_PENDING`, setCohesionSortSaga),
    takeLatest(`${GET_MAPPED_DEMOGRAPHICS}_PENDING`, getReportDemographics),
    takeLatest(`${GET_NPS_DATA}_PENDING`, getNPSDataSaga),
    takeLatest(`${GET_NPS_DEMOGRAPHIC_DATA}_PENDING`, getNPSDemographicDataSaga),
    takeLatest(`${GET_ENGAGEMENT_DATA}_PENDING`, getEngagementDataSaga),
    takeLatest(`${GET_ENGAGEMENT_DEMOGRAPHIC_DATA}_PENDING`, getEngagementDemographicDataSaga),
    takeLatest(`${GET_CLIENT_ENGAGEMENT_DATA}_PENDING`, getClientEngagementSaga),
    takeLatest(`${GET_SINGLE_QUESTION}_PENDING`, getSingleQuestionSaga),
    takeLatest(`${GET_IE_HEATMAP}_PENDING`, getIeHeatmapSaga),
    takeLatest(`${GET_EMP_EXP_HEATMAP}_PENDING`, getEmpExpFactorSaga),
    takeLatest(`${GET_OHI_EMP_EXP_HEATMAP}_PENDING`, getOhiScoreForEmpExpSaga),
    takeLatest(`${GET_CUSTOM_BU_SCORE_DATA}_PENDING`, getCustomBuScoreDataSaga),
    takeLatest(`${GET_CUSTOM_BU_LANG_DATA}_PENDING`, getCustomBuLangData),
    takeLatest(`${GET_TLNX_ENGAGEMENT_DATA}_PENDING`, getTalnxEngagementData),
    takeLatest(`${GET_SINGLE_PAST_REPORT}_PENDING`, getExportSaga),
    takeLatest(`${GET_ORGHEALTH_DATA}_PENDING`, getOrgHealthData),
    takeLatest(`${GET_COMP_YR_PRACTICE_DATA}_PENDING`, getCompYrPracticesSaga),
    takeLatest(`${GET_SCORE_DATA_HYBRID}_PENDING`, getScoreDataForHybrid),
    takeLatest(`${GET_LOCAL_PRACTICE}_PENDING`, getLocalPracticeSaga),
    takeLatest(`${SEND_FEEDBACK}_PENDING`, sendFeedbackSaga),
    takeLatest(`${GET_FEEDBACK_LIST}_PENDING`, getFeedbackListSaga),
    takeLatest(`${GET_FEEDBACK_DETAILS}_PENDING`, getFeedbackDetailsSaga),
    takeLatest(`${REFRESH_FEEDBACK_LIST}_PENDING`, refreshFeedbackListSaga),
    takeLatest(`${GET_TARGET_SCORE_DATA}_PENDING`, getTargetScoreData),
    takeLatest(`${GET_EMP_EXP_RES_DATA}_PENDING`, getEmpExpResurveySaga),
    takeLatest(`${GET_EMP_EXP_RES_HEATMAP_DATA}_PENDING`, getEmpExpResurveyHeatmapSaga),
];

export default diagnoseSagas;
