import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import c from 'classnames';
import { ArrowBack } from '@material-ui/icons';
import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import Info from '../../assets/C-info.svg';
import UpArrow from '../../assets/arrow-up.svg';
import DownArrow from '../../assets/arrow-down.svg';
import SquareMinus from '../../assets/minus-square.svg';
import NoSig from '../../assets/no_significance.svg';
import '../index.scss';
import { getEEBoxStyle } from '../../../../utils/functions';
import MetaChartModal from '../../MetaChartModal';
import FilterSvg from '../../assets/Filter.svg';
import ActiveFilters1 from '../../assets/ActiveFilters1.svg';
// import BulletList from '../../assets/BulletList.svg';
// import Down from '../../assets/Down.svg';

const renderIcon = (sig, diff) => {
    const diffToUse = sig;
    if (isNaN(parseInt(sig, 10)) && !isNaN(parseInt(diff, 10))) return NoSig;
    if (!diff) return null;
    if (diffToUse === 0) {
        return DownArrow;
    }
    if (diffToUse === 1) {
        return UpArrow;
    }
    if (diffToUse === 2) {
        return SquareMinus;
    }
    return null;
};

const showProgress = ([value, value1], significance, diff, isNegative) => {
    // console.log(significance, diff);
    const { positiveBg = '#000000', negativeBg = '#ffffff' } = getEEBoxStyle(value, isNegative) || {};
    const { positiveBg: positiveBg1 = '#000000', negativeBg: negativeBg1 = '#ffffff' } =
        getEEBoxStyle(value1, isNegative) || {};
    const progessStyle = {
        background: `
                    radial-gradient(closest-side, #ffffff 65%, transparent 0% 100%),
                    conic-gradient(${isNegative ? negativeBg : positiveBg} ${value}%, rgb(239, 238, 239) 0%)`,
    };

    const progessStyle1 = {
        background: `
                    radial-gradient(closest-side, #ffffff 65%, transparent 0% 100%),
                    conic-gradient(${isNegative ? negativeBg1 : positiveBg1} ${value1}%, rgb(239, 238, 239) 0%)`,
    };

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <div className="progress-bar" style={progessStyle}>
                <progress value={value} min="0" max="100" style={{ height: 0, width: 0 }} />
                <div className="percentValue">{`${value}%`}</div>
            </div>
            <div className="progress-bar" style={progessStyle1}>
                <progress value={value1} min="0" max="100" style={{ height: 0, width: 0 }} />
                <div className="percentValue">{`${value1}%`}</div>
            </div>
            <div className="diffContainer">
                <div className="diff">
                    {diff}
                    <span
                        className={c('difference', {
                            negative: diff < 0 && significance[0] === '',
                        })}
                    >
                        <img src={renderIcon(significance[0], diff)} alt="" />
                    </span>
                </div>
            </div>
        </Box>
    );
};

function EmpExpResurvey({
    // qbyqData,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
    // getEmpExpResurveyData,
    empExpResurveyData,
    // empExpResurveyDataFetched,
    // projects,
    // selectedIds,
    // apiParams,
    addOnTab,
    setAddOnTabs,
    appliedFiltersInfo,
    toggleFilterView,
    // pptDownload,
    activeResetFilter,
    setReport,
    years,
    // filters,
    ohids,
}) {
    const { lang = '1033', report_type: surveyType = '', year } = defaultSettings;
    // const { filters = [] } = apiParams || {};
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUESTIONS } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const { NEG_IND_QUES, QUES_LEGEND_TYPE, SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const [showMetaChart, setShowMetaChart] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [isPracticeMeta, setPraticeMeta] = useState(false);

    const toggleMetaDataPopup = (modalState = false, scores = {}, isPractice = false) => {
        setShowMetaChart(modalState);
        setMetaData(scores);
        setPraticeMeta(isPractice);
    };

    const { qbyq: empData = {} } = empExpResurveyData || {};
    const { records: qbyq = {}, size = '' } = empData || {};
    const checkLength = !!Object.keys(qbyq).length;
    const {
        SINGLE_QUESTIONS_LEGEND,
        QUESTION_SCORE,
        IND_EXP_SCORE_PAGE_TEXT_NOTES,
        EMPLOYEE_NET_PROMOTER_SCORE,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
        IND_EXP_LINKS,
        FILTERS,
        // DOWNLOAD,
        // NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        // RESURVEY_TEXT,
        SCORECARD,
        STATISTICAL_DIFFERENCE,
        SCORECARD_RESURVEY_SIGNIFICANCE,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
    } = SITE_TEXT || {};

    // useEffect(() => {
    //     if (!empExpResurveyDataFetched) {
    //         getEmpExpResurveyData(getResurveyParams(filters[1]));
    //     }
    // }, [empExpResurveyDataFetched]);

    const sigValToUse = SCORECARD_RESURVEY_SIGNIFICANCE;

    return (
        <Fragment>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="breadCrumb">
                        <span data-testid="setScoreFromBuBtn" onClick={() => setReport(0)}>
                            {SCORECARD}
                        </span>{' '}
                        {' > '}
                        {'Resurvey'}
                    </div>
                    <h2 className="clearfix">
                        <span data-testid="setBackFromBuBtn" onClick={() => setReport(0)}>
                            <ArrowBack />
                            Resurvey {EMPLYEE_EXPEIENCE}
                        </span>

                        <ul className="rightSideNav">
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </h2>
                    {/* <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">Resurvey {EMPLYEE_EXPEIENCE}</div>
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        <li
                            data-testid="setReSurveyModalBtn"
                            // onClick={() => setReSurveyModal(true)}
                            className="fImage resurveyImg"
                        >
                            <img src={BulletList} alt="" />
                            <span className="upperHead">{RESURVEY_TEXT}</span>
                        </li>
                        {!errorInfo && (
                            <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeResetFilter ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div> */}
                    {appliedFiltersInfo()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {/* {renderRightOptions()} */}
                    </div>
                    <div className="legendCard">
                        <div className="title">Resurvey Years</div>
                        <div className="compYears">
                            <div>
                                <div>{ohids[0]}</div>
                                <div>{year}</div>
                            </div>
                            <div>
                                <div>{ohids[1]}</div>
                                <div>{years[1]}</div>
                            </div>
                        </div>
                    </div>
                    <Fragment>
                        <div className="indExpScorecard empExpResurvey">
                            {checkLength &&
                                QUESTIONS.map(({ title, children, imgSrc }) => {
                                    return (
                                        <div className="cardWrapper">
                                            <div className="headerContent">
                                                <h2>
                                                    <span>
                                                        <img src={imgSrc} alt={title} />
                                                    </span>
                                                    {title}
                                                </h2>
                                            </div>
                                            {children.map(key => {
                                                const {
                                                    quartile,
                                                    range_start,
                                                    range_end,
                                                    display_name: childTitle = '',
                                                    score = ['', ''],
                                                    meta_scores = [],
                                                    significance = [],
                                                    differences = [],
                                                } = qbyq[key] || {};
                                                const negativeTrait = NEG_IND_QUES.includes(key);
                                                const sup = QUES_LEGEND_TYPE[key] || '';
                                                const { qtext = '', bottom2_score = [0, 0], nuetral_score = [0, 0] } =
                                                    meta_scores[0] || {};
                                                const enps = score[0] - bottom2_score[0];
                                                const positiveNps = enps > 0;
                                                const enps1 = score[1] - bottom2_score[1];
                                                const positiveNps1 = enps1 > 0;
                                                const childSet = {
                                                    meta_scores,
                                                    display_name: childTitle,
                                                    score,
                                                    quartile,
                                                    range_start,
                                                    range_end,
                                                };
                                                if (!score.length) return null;
                                                return (
                                                    <div className={c({ hoverElement: sup === 2 }, 'cardData')}>
                                                        <div
                                                            onClick={() => toggleMetaDataPopup(true, childSet, true)}
                                                            className="cardResurveyHeading"
                                                        >
                                                            {childTitle}
                                                            <sup>{sup}</sup>
                                                            {sup === 2 ? (
                                                                <span>
                                                                    <img src={Info} alt="" />
                                                                </span>
                                                            ) : null}
                                                        </div>

                                                        <div className="percentProgress">
                                                            {showProgress(
                                                                score,
                                                                significance,
                                                                differences,
                                                                negativeTrait
                                                            )}
                                                        </div>
                                                        {sup === 2 ? (
                                                            <div className="detailedInfo">
                                                                <div className="questionSection">
                                                                    <div className="practiceSet clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <b>{QUESTION_SCORE}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="metaText">{qtext}</div>
                                                                        </div>
                                                                        <div>{IND_EXP_SCORE_PAGE_TEXT_NOTES}</div>
                                                                    </div>
                                                                    <div className="practiceSet clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <div className="metaScore chart">
                                                                                    <div>
                                                                                        {SINGLE_QUESTIONS_LEGEND.map(
                                                                                            (legendText, i) => {
                                                                                                const sectionWidth =
                                                                                                    i === 1
                                                                                                        ? nuetral_score
                                                                                                        : score;
                                                                                                const width = !i
                                                                                                    ? bottom2_score
                                                                                                    : sectionWidth;
                                                                                                return (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: `${width -
                                                                                                                0.5}%`,
                                                                                                        }}
                                                                                                    >
                                                                                                        <span />{' '}
                                                                                                        {legendText}
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="metaScore">
                                                                                <div className="chart">
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${bottom2_score[0] -
                                                                                                0.5}%`,
                                                                                        }}
                                                                                    >
                                                                                        {bottom2_score[0]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${nuetral_score[0] -
                                                                                                0.5}%`,
                                                                                        }}
                                                                                    >
                                                                                        {nuetral_score[0]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${score[0] - 0.5}%`,
                                                                                        }}
                                                                                    >
                                                                                        {score[0]}
                                                                                    </span>
                                                                                    <div className="year">{year}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="metaSection">
                                                                            <div className="metaText" />
                                                                            <div className="metaScore">
                                                                                <div className="chart">
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${bottom2_score[1] -
                                                                                                0.5}%`,
                                                                                        }}
                                                                                    >
                                                                                        {bottom2_score[1]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${nuetral_score[1] -
                                                                                                0.5}%`,
                                                                                        }}
                                                                                    >
                                                                                        {nuetral_score[1]}
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            width: `${score[1] - 0.5}%`,
                                                                                        }}
                                                                                    >
                                                                                        {score[1]}
                                                                                    </span>
                                                                                    <div className="year">
                                                                                        {years[1]}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="metaInfo" />
                                                                <div className="questionSection">
                                                                    <div className="practiceSet bottom clearfix">
                                                                        <div className="metaSection">
                                                                            <div className="metaText">
                                                                                <div>
                                                                                    <div>
                                                                                        <strong>eNPS</strong>
                                                                                    </div>
                                                                                    <div>
                                                                                        {EMPLOYEE_NET_PROMOTER_SCORE}
                                                                                    </div>
                                                                                </div>
                                                                                {/* <span>{`= ${score}% - ${bottom2_score}%`}</span> */}
                                                                            </div>
                                                                            <div className="metaScore">
                                                                                <div className="chart bottomChart">
                                                                                    <div className="axis horizontal" />
                                                                                    <div className="axis vertical" />
                                                                                    <span />
                                                                                    <span className="section2">
                                                                                        <span
                                                                                            className={c(
                                                                                                {
                                                                                                    negative: !positiveNps,
                                                                                                },
                                                                                                'capsule'
                                                                                            )}
                                                                                            style={{
                                                                                                width: `${
                                                                                                    positiveNps
                                                                                                        ? enps / 2
                                                                                                        : -enps / 2
                                                                                                }%`,
                                                                                            }}
                                                                                        >
                                                                                            {enps + '%'}
                                                                                        </span>
                                                                                    </span>
                                                                                    <div className="year">{year}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="metaSection">
                                                                            <div className="metaText" />
                                                                            <div className="metaScore">
                                                                                <div className="diff">
                                                                                    <span>{differences[0]}</span>
                                                                                    <span className="difference">
                                                                                        <img
                                                                                            src={renderIcon(
                                                                                                significance[0],
                                                                                                differences[0]
                                                                                            )}
                                                                                            alt=""
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="metaSection">
                                                                            <div className="metaText" />
                                                                            <div className="metaScore">
                                                                                <div className="chart bottomChart">
                                                                                    <div className="axis horizontal" />
                                                                                    <div className="axis vertical" />
                                                                                    <span />
                                                                                    <span className="section2">
                                                                                        <span
                                                                                            className={c(
                                                                                                {
                                                                                                    negative: !positiveNps1,
                                                                                                },
                                                                                                'capsule'
                                                                                            )}
                                                                                            style={{
                                                                                                width: `${
                                                                                                    positiveNps1
                                                                                                        ? enps1 / 2
                                                                                                        : -enps1 / 2
                                                                                                }%`,
                                                                                            }}
                                                                                        >
                                                                                            {enps1 + '%'}
                                                                                        </span>
                                                                                    </span>
                                                                                    <div className="year">
                                                                                        {years[1]}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            {showMetaChart && (
                                <MetaChartModal
                                    metaData={metaData}
                                    closeModal={toggleMetaDataPopup}
                                    isPracticeMeta={isPracticeMeta}
                                    totalSize={size.toLocaleString('en-US')}
                                    defaultSettings={defaultSettings}
                                    isEE
                                />
                            )}
                        </div>

                        {/* {!apiLoadingCount && !errorInfo && (
                        <div className="note">
                            <b>{NOTE}:</b>
                            <br />
                            {IND_EXP_QUESTION_NOTE[0]}
                            <br />
                            {IND_EXP_QUESTION_NOTE[1]}
                            <br />
                            {surveyType === 'percent favorable' ? <div>3. {EE_PERCENT_FAVORABLE}</div> : ''}
                            {surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                        </div>
                    )} */}

                        {!apiLoadingCount && !errorInfo && (
                            <div className="note">
                                <b>{NOTE}:</b>
                                {surveyType === 'percent favorable' ? <div>1. {EE_PERCENT_FAVORABLE}</div> : ''}
                                {surveyType === 'percentile' ? <div>1. {EE_PERCENTILE}</div> : ''}
                                {IND_EXP_QUESTION_NOTE[0]}
                                <br />
                                {IND_EXP_QUESTION_NOTE[1]}
                                <br />
                            </div>
                        )}
                    </Fragment>
                </div>
            </div>
            <ul className="fixedScoreCardSurvey">
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix">
                    <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                    {IND_EXP_SCORE_CLASSIFICATION.map(({ titleNeg: title, negativeBg: background }) => (
                        <li key={title}>
                            <span style={{ background, border: 'none' }} />
                            {title}
                        </li>
                    ))}
                </ul>
                <ul className="benchmark clearfix change">
                    <li>{STATISTICAL_DIFFERENCE} : </li>
                    {sigValToUse.map(({ title, imgSrc }) => (
                        <li key={title}>
                            <span>
                                <img src={imgSrc} alt="" />
                            </span>
                            {title}
                        </li>
                    ))}
                </ul>
            </ul>
        </Fragment>
    );
}

EmpExpResurvey.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    // projects: PropTypes.array.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
    // getEmpExpResurveyData: PropTypes.func.isRequired,
    empExpResurveyData: PropTypes.object.isRequired,
    // empExpResurveyDataFetched: PropTypes.bool.isRequired,
    // selectedIds: PropTypes.array.isRequired,
    // apiParams: PropTypes.object.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    // pptDownload: PropTypes.func.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setReport: PropTypes.func.isRequired,
    years: PropTypes.array.isRequired,
    ohids: PropTypes.array.isRequired,
};

export default EmpExpResurvey;
