import { fromJS } from 'immutable';
import { cloneDeep } from 'lodash';

import { getStaticText } from '../../utils/constants';
import getStaticTextDiagnose from './constants';
import { GET_DEFAULT_SETTINGS, UPDATE_DEFAULT_SETTINGS, REMOVE_SAVED_TOKEN } from '../Login/constants';
import { UPDATE_INCLUSION_FILTERS } from '../Inclusion/constants';
import { UPDATE_SETTINGS } from '../Settings/constants';
import { getBenchmarkString, setTargetSettingBm } from '../../utils/functions';

const staticText = getStaticText();
const { API_DUMMY_PARAMS, NON_PROFIT_SURVEY, ING_REPORT_ID } = staticText;

const staticTextDiagnose = getStaticTextDiagnose();
const {
    GET_SCORE_DATA,
    GET_PRACTICE_DATA,
    GET_DEMOGRAPHICS,
    GET_BENCHMARKS,
    UPDATE_LEFT_BENCHMARKS,
    UPDATE_RIGHT_BENCHMARKS,
    GET_HEATMAP_OUTCOME,
    UPDATE_API_PARAMS,
    SET_SELECTED_DEMOGRAPHIC,
    GET_HEATMAP_PRACTICE,
    GET_HEATMAP_INCLUSION,
    SET_DEMOGRAPHIC_CURRENT_TAB,
    UPDATE_HEAT_MAP_OUTCOME,
    UPDATE_HEAT_MAP_PRACTICE,
    SORT_HEAT_MAP_OUTCOME,
    GET_PROJECTS,
    GET_RESURVEY_DATA,
    GET_BU_DATA,
    GET_PRACTICE_BU_DATA,
    UPDATE_FILTERS,
    RESET_RESURVEY_FETCHED,
    RESET_BU_FETCHED,
    RESET_PRACTICE_BU_FETCHED,
    GET_PPT_DATA,
    GET_PPT_STATUS,
    RESET_FILTER_INDEX,
    GET_TOOLBOX_THEME,
    CREATE_THEME,
    DELETE_THEME,
    TOGGLE_CREATE_THEME,
    SET_THEME,
    SET_PRACTICE_DISPLAY,
    GET_COHESION_DATA,
    SET_COHESION_DEMOGRAPHIC,
    SET_PRACTICE_PAGE_NO,
    SET_OUTCOME_PAGE_NO,
    SET_HEATMAP_PRACTICE_DATA,
    SET_HEATMAP_OUTCOME_DATA,
    SET_PRACTICE_RETRIEVED,
    SET_OUTCOME_RETRIVED,
    GET_VALUES_DATA,
    GET_TEXT_COMMENTS,
    TOGGLE_PROGRESS_BAR,
    PERCENTILE,
    SET_COHESION_SORT,
    OUTCOME_VAR,
    GET_MAPPED_DEMOGRAPHICS,
    UPDATE_HEATMAP_RESURVEY,
    SET_RESURVEY_HEATMAP_TAB,
    GET_RESURVEY_HEATMAP_OUTCOME,
    GET_RESURVEY_HEATMAP_PRACTICE,
    GET_NPS_DATA,
    GET_NPS_DEMOGRAPHIC_DATA,
    SET_NPS_DROPDOWN,
    GET_ENGAGEMENT_DATA,
    GET_ENGAGEMENT_DEMOGRAPHIC_DATA,
    SET_ENGAGEMENT_DROPDOWN,
    GET_CLIENT_ENGAGEMENT_DATA,
    GET_SINGLE_QUESTION,
    GET_IE_HEATMAP,
    SORT_IE_HEAT_MAP,
    GET_EMP_EXP_HEATMAP,
    GET_OHI_EMP_EXP_HEATMAP,
    SET_FILTER_COLUMNS,
    GET_CUSTOM_BU_SCORE_DATA,
    GET_CUSTOM_BU_LANG_DATA,
    GET_TLNX_ENGAGEMENT_DATA,
    SET_CUSTOM_BU_FILTER,
    GET_SINGLE_PAST_REPORT,
    GET_ORGHEALTH_DATA,
    GET_RANKING_HEATMAP,
    SET_RANKING_HEATMAP_RETRIEVED,
    UPDATE_HEAT_MAP_RANKING,
    SET_HEATMAP_RANKING_DATA,
    SET_RANKING_PAGE_NO,
    SORT_HEAT_MAP_RANKING,
    GET_COMP_YR_PRACTICE_DATA,
    SET_ADD_ON_TAB,
    GET_SCORE_DATA_HYBRID,
    SORT_HEAT_MAP_PRACTICE,
    GET_LOCAL_PRACTICE,
    SEND_FEEDBACK,
    GET_FEEDBACK_LIST,
    GET_FEEDBACK_DETAILS,
    RESET_FEEDBACK,
    REFRESH_FEEDBACK_LIST,
    FEEDBACK_PAYLOAD,
    SET_FEEDBACK_OPTIONS,
    GET_TARGET_SCORE_DATA,
    UPDATE_TARGET_SETTING_BM,
    GET_EMP_EXP_RES_DATA,
    GET_EMP_EXP_RES_HEATMAP_DATA,
    RESET_EMP_EXP_RES_FETCHED,
} = staticTextDiagnose;

export const INITIAL_STATE = fromJS({
    count: 0,
    error: '',
    engagement: '',
    heatMapInclusionError: '',
    scoreData: {},
    scoreDataFetched: false,
    practiceData: {},
    practiceDataFetched: false,
    leftBenchmarks: [],
    rightBenchmarks: [],
    heatMap: {},
    heatMapDataFetched: false,
    heatMapPractice: {},
    heatMapPracticeFetched: false,
    heatMapInclusion: fromJS({}),
    heatMapInclusionFetched: false,
    apiParams: fromJS(API_DUMMY_PARAMS),
    selectedDemographic: '',
    demographicCurrentTab: OUTCOME_VAR,
    projectsFetched: false,
    projects: [],
    reSurveyData: {},
    reSurveyDataFetched: true,
    buData: {},
    buDataFetched: true,
    practiceBuData: [],
    practiceBuDataFetched: true,
    filters: fromJS([]),
    defaultBenchmarks: fromJS([]),
    defaultDemographics: fromJS([]),
    flagpoleOriginalBench: fromJS([]),
    filtersApplied: false,
    toolBox: fromJS([]),
    toolBoxError: '',
    toolBoxFetched: false,
    toolBoxLoading: false,
    enableCreateTheme: false,
    currentTheme: '',
    practiceToDisplay: '',
    isRetrieved: false,
    cohesionScore: 0,
    cohesionDemographic: '',
    cohesionData: {},
    cohesionDataFetched: false,
    cohesionError: '',
    isPractiseRetrived: false,
    isOutcomeRetrieved: false,
    isPracticeRetrived: false,
    isRankingHeatMapRetrived: false,
    showProgressBar: false,
    loadHeatMapPractice: false,
    loadHeatMapOutcome: false,
    loadHeatMapRanking: false,
    practicePageNo: 1,
    outcomePageNo: 1,
    rankingPageNo: 1,
    pptStatus: [],
    pptStatusFetching: false,
    pptError: false,
    valuesData: {},
    valuesDataFetched: false,
    commentsData: {},
    commentsDataFetched: false,
    heatMapResurveyInfo: fromJS({}),
    heatMapResurveyTab: OUTCOME_VAR,
    resurveyPracticeHeatMap: fromJS({}),
    resurveyPracticeHeatMapFetched: false,
    resurveyOutcomeHeatMap: fromJS({}),
    resurveyOutcomeHeatMapFetched: false,
    npsData: [],
    npsDataFetched: false,
    npsDemographicData: [],
    npsDemographicDataFetched: false,
    npsDropdown: '',
    engagementData: fromJS({}),
    engagementDataFetched: false,
    engagementDemographicData: fromJS({}),
    engagementDemographicDataFetched: false,
    engagementDropdown: '',
    clientEngagementData: fromJS({}),
    clientEngagementFetched: false,
    singleQuestion: fromJS({}),
    singleQuestionFetched: false,
    ieHeatmap: fromJS({}),
    ieHeatmapFetched: false,
    empExpHeatmaps: fromJS({}),
    empExpOhiHeatmaps: fromJS({}),
    filterColumns: fromJS({}),
    customBuLang: fromJS([]),
    customBuLangFetched: false,
    tlnxEngagement: fromJS([]),
    tlnxEngagementFetched: false,
    customBuFilters: fromJS([]),
    pastReport: fromJS({}),
    orgHealth: fromJS({}),
    orgHealthFetched: false,
    heatMapRanking: fromJS({}),
    heatMapRankingFetched: false,
    practiceDataCompYr: fromJS({}),
    addOnTab: 0,
    scoreDataHybrid: fromJS({}),
    scoreDataHybridFetched: false,
    enpsFetched: false,
    localPractice: '',
    localPracticeFetched: false,
    feedback: fromJS({}),
    sendFeedbackSuccess: false,
    feedbackList: fromJS([]),
    feedbackListFetched: false,
    feedbackDetails: fromJS({}),
    feedbackDetailsFetched: false,
    feedbackOptions: fromJS(FEEDBACK_PAYLOAD),
    targetScoreData: {},
    targetScoreDataFetched: true,
    targetBenchmarks: fromJS([]),
    targetBm: '1001:1',
    empExpResurveyData: fromJS({}),
    empExpResurveyDataFetched: false,
    empExpResurveyHeatmapData: fromJS({}),
    empExpResurveyHeatmapDataFetched: false,
});

const reducer = (state = INITIAL_STATE, { type = '', payload = {}, countToAdd = 0 }) => {
    switch (type) {
        case `${SET_COHESION_SORT}_PENDING`:
        case `${GET_DEMOGRAPHICS}_PENDING`:
        case `${GET_RESURVEY_DATA}_PENDING`:
        case `${GET_BU_DATA}_PENDING`:
        case `${GET_PRACTICE_BU_DATA}_PENDING`:
        case `${GET_PRACTICE_DATA}_PENDING`:
        case `${GET_VALUES_DATA}_PENDING`:
        case `${GET_TEXT_COMMENTS}_PENDING`:
        case `${GET_SCORE_DATA}_PENDING`:
        case `${GET_HEATMAP_OUTCOME}_PENDING`:
        case `${GET_MAPPED_DEMOGRAPHICS}_PENDING`:
        case `${GET_NPS_DATA}_PENDING`:
        case `${GET_NPS_DEMOGRAPHIC_DATA}_PENDING`:
        case `${GET_ENGAGEMENT_DATA}_PENDING`:
        case `${GET_ENGAGEMENT_DEMOGRAPHIC_DATA}_PENDING`:
        case `${GET_CLIENT_ENGAGEMENT_DATA}_PENDING`:
        case `${GET_SINGLE_QUESTION}_PENDING`:
        case `${GET_IE_HEATMAP}_PENDING`:
        case `${GET_EMP_EXP_HEATMAP}_PENDING`:
        case `${GET_OHI_EMP_EXP_HEATMAP}_PENDING`:
        case `${GET_CUSTOM_BU_SCORE_DATA}_PENDING`:
        case `${GET_TLNX_ENGAGEMENT_DATA}_PENDING`:
        case `${GET_CUSTOM_BU_LANG_DATA}_PENDING`:
        case `${GET_SINGLE_PAST_REPORT}_PENDING`:
        case `${GET_ORGHEALTH_DATA}_PENDING`:
        case `${GET_RANKING_HEATMAP}_PENDING`:
        case `${GET_COMP_YR_PRACTICE_DATA}_PENDING`:
        case `${GET_HEATMAP_PRACTICE}_PENDING`:
        case `${GET_LOCAL_PRACTICE}_PENDING`:
        case `${SEND_FEEDBACK}_PENDING`:
        case `${GET_FEEDBACK_LIST}_PENDING`:
        case `${GET_FEEDBACK_DETAILS}_PENDING`:
        case `${REFRESH_FEEDBACK_LIST}_PENDING`:
        case `${GET_TARGET_SCORE_DATA}_PENDING`:
        case `${GET_SCORE_DATA_HYBRID}_PENDING`:
        case `${GET_EMP_EXP_RES_DATA}_PENDING`:
        case `${GET_EMP_EXP_RES_HEATMAP_DATA}_PENDING`: {
            return state.set('error', '').update('count', count => count + 1);
        }
        case `${GET_RESURVEY_HEATMAP_OUTCOME}_PENDING`: {
            const { page } = payload;
            if (page === 1) {
                return state.set('error', '').update('count', count => count + 1);
            }
            return state.set('error', '');
        }
        case `${UPDATE_HEAT_MAP_PRACTICE}_PENDING`:
        case `${UPDATE_HEAT_MAP_RANKING}_PENDING`:
        case `${UPDATE_HEAT_MAP_OUTCOME}_PENDING`: {
            return state.set('error', '');
        }
        case `${GET_DEFAULT_SETTINGS}_SUCCESS`: {
            const {
                year,
                lang,
                report_type: oldReport,
                cohesion_default_Demographic,
                benchmark = [],
                threshold = 10,
                pss_module,
                engagement,
                report_id,
                survey_version = '',
                user_id = '',
                ohid = '',
            } = payload;
            const isIng = `${report_id}` === ING_REPORT_ID;
            const report_type = oldReport === PERCENTILE ? PERCENTILE : '';
            const benchmarksToUse = oldReport === PERCENTILE ? '1001:1' : benchmark[0] || '';
            const rightBm = engagement === NON_PROFIT_SURVEY ? '1001:1' : benchmarksToUse;

            const leftBmInUse = isIng ? '6003:6' : benchmarksToUse;
            const rightBmInUse = isIng ? '6003:6' : rightBm;

            const dataToUpdate = {
                year: parseInt(year, 10),
                lang: parseInt(lang, 10),
                bm_filters: fromJS([leftBmInUse, rightBmInUse]),
                report_type,
                threshold,
                filters: fromJS([]),
                benchmarks: benchmarksToUse,
                pss_module: pss_module ? 1 : 0,
                is_top_quartile: survey_version === '4' ? 1 : 0,
            };

            const feedbackData = {
                report_id,
                ohid,
                created_by: user_id,
            };

            return state
                .updateIn(['apiParams'], oldSettings => oldSettings.merge(dataToUpdate))
                .set('filters', fromJS([]))
                .set('filtersApplied', false)
                .set('scoreDataFetched', false)
                .set('valuesDataFetched', false)
                .set('practiceDataFetched', false)
                .set('heatMapDataFetched', false)
                .set('heatMapPracticeFetched', false)
                .set('heatMapInclusionFetched', false)
                .set('heatMapRankingFetched', false)
                .set('toolBoxFetched', false)
                .set('projectsFetched', false)
                .set('cohesionDataFetched', false)
                .set('resurveyOutcomeHeatMapFetched', false)
                .set('resurveyPracticeHeatMapFetched', false)
                .set('engagement', engagement)
                .set('cohesionDemographic', cohesion_default_Demographic)
                .set('scoreDataHybridFetched', false)
                .set('empExpHeatmaps', fromJS({}))
                .set('empExpOhiHeatmaps', fromJS({}))
                .set('enpsFetched', false)
                .set('localPracticeFetched', false)
                .set('localPractice', '')
                .set('engagementDataFetched', false)
                .set('npsDataFetched', false)
                .set('engagementDemographicDataFetched', false)
                .set('npsDemographicDataFetched', false)
                .set('clientEngagementFetched', false)
                .set('ieHeatmapFetched', false)
                .set('singleQuestionFetched', false)
                .set('orgHealthFetched', false)
                .set('practiceDataCompYr', fromJS({}))
                .set('selectedDemographic', '')
                .set('commentsDataFetched', false)
                .updateIn(['feedbackOptions'], oldSettings => oldSettings.merge(feedbackData));
        }
        case UPDATE_HEATMAP_RESURVEY: {
            return state
                .set('resurveyOutcomeHeatMapFetched', false)
                .set('resurveyPracticeHeatMapFetched', false)
                .set('empExpResurveyHeatmapDataFetched', false)
                .update('heatMapResurveyInfo', oldData => oldData.merge(payload));
        }
        case `${GET_SCORE_DATA}_SUCCESS`: {
            const { qbyq } = payload || {};
            const { benchmarks_target_setting = '' } = qbyq || {};
            const targetBenchmarks = state.get('targetBenchmarks');
            if (benchmarks_target_setting) {
                const targetBench = setTargetSettingBm(benchmarks_target_setting, targetBenchmarks);
                const bmStr = getBenchmarkString(targetBench);
                return state
                    .update('count', count => count - 1)
                    .set('scoreData', payload)
                    .set('scoreDataFetched', true)
                    .set('targetBenchmarks', targetBench)
                    .set('targetBm', bmStr);
            }
            return state
                .update('count', count => count - 1)
                .set('scoreData', payload)
                .set('scoreDataFetched', true);
        }
        case `${GET_SCORE_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('scoreData', fromJS({}))
                .set('scoreDataFetched', true);
        }
        case `${GET_VALUES_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('valuesData', payload)
                .set('valuesDataFetched', true);
        }
        case `${GET_VALUES_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('valuesData', fromJS({}))
                .set('valuesDataFetched', true);
        }
        case `${GET_CLIENT_ENGAGEMENT_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('clientEngagementData', payload)
                .set('clientEngagementFetched', true);
        }
        case `${GET_CLIENT_ENGAGEMENT_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('clientEngagementData', fromJS({}))
                .set('clientEngagementFetched', true);
        }
        case `${GET_TEXT_COMMENTS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('commentsData', payload)
                .set('commentsDataFetched', true);
        }
        case `${GET_TEXT_COMMENTS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('commentsData', fromJS({}))
                .set('commentsDataFetched', true);
        }
        case `${GET_PRACTICE_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('practiceData', payload)
                .set('practiceDataFetched', true);
        }
        case `${GET_PRACTICE_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('practiceData', fromJS({}))
                .set('practiceDataFetched', true);
        }
        case `${GET_COMP_YR_PRACTICE_DATA}_SUCCESS`: {
            const { data = {}, ohid = {}, prevData = {} } = payload || {};
            const newobj = {
                ...prevData,
                [ohid]: data,
            };
            return state.update('count', count => count - 1).set('practiceDataCompYr', newobj);
        }
        case `${GET_COMP_YR_PRACTICE_DATA}_FAILED`: {
            const { error = {}, ohid = {}, prevData = {} } = payload || {};
            const newobj = {
                ...prevData,
                [ohid]: {},
            };
            return state
                .update('count', count => count - 1)
                .set('error', error)
                .set('practiceDataCompYr', newobj);
        }
        case `${GET_DEMOGRAPHICS}_SUCCESS`: {
            if (!payload.indexToUpdate) {
                return state
                    .update('count', count => count - 1)
                    .set('defaultDemographics', payload.demographics)
                    .setIn(['filters', payload.indexToUpdate, 'demographics'], payload.demographics);
            }
            return state
                .update('count', count => count - 1)
                .setIn(['filters', payload.indexToUpdate, 'demographics'], payload.demographics);
        }
        case `${GET_DEMOGRAPHICS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .setIn(['filters', payload.indexToUpdate, 'demographics'], []);
        }
        case `${GET_BENCHMARKS}_SUCCESS`: {
            if (!payload.indexToUpdate) {
                return state
                    .setIn(['filters', payload.indexToUpdate, 'benchmarks'], payload.benchmarks)
                    .set('defaultBenchmarks', payload.defaultBenchmarks)
                    .set('flagpoleOriginalBench', payload.flagpoleOriginalBench)
                    .set('leftBenchmarks', payload.flagpoleBenchmarks)
                    .set('rightBenchmarks', payload.rightFlagBenchmarks)
                    .set('targetBenchmarks', payload.targetBenchmarks);
            }
            return state.setIn(['filters', payload.indexToUpdate, 'benchmarks'], payload.benchmarks);
        }
        case `${GET_BENCHMARKS}_FAILED`: {
            return state.set('error', payload.error).setIn(['filters', payload.indexToUpdate, 'benchmarks'], []);
        }
        case `${GET_FEEDBACK_LIST}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('feedbackList', payload)
                .set('feedbackListFetched', true);
        }
        case `${GET_FEEDBACK_LIST}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('feedbackList', fromJS([]))
                .set('feedbackListFetched', true);
        }
        case `${GET_FEEDBACK_DETAILS}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('feedbackDetails', payload)
                .set('feedbackDetailsFetched', true);
        }
        case `${GET_FEEDBACK_DETAILS}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('feedbackDetails', fromJS([]))
                .set('feedbackDetailsFetched', true);
        }
        case `${REFRESH_FEEDBACK_LIST}_SUCCESS`: {
            const { data, type: dataType } = payload || {};
            if (dataType === 1) {
                return state.update('count', count => count - 1).set('feedbackDetails', data);
            }
            return state.update('count', count => count - 1);
        }
        case `${REFRESH_FEEDBACK_LIST}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.error);
        }
        case UPDATE_LEFT_BENCHMARKS: {
            return state.set('leftBenchmarks', payload);
        }
        case UPDATE_RIGHT_BENCHMARKS: {
            return state.set('rightBenchmarks', payload);
        }
        case UPDATE_FILTERS: {
            return state
                .set('filters', payload.filters)
                .set('filtersApplied', payload.filtersApplied)
                .set('apiParams', fromJS(payload.apiParams))
                .set('scoreDataFetched', false)
                .set('valuesDataFetched', false)
                .set('practiceDataFetched', false)
                .set('heatMapDataFetched', false)
                .set('cohesionDataFetched', false)
                .set('resurveyOutcomeHeatMapFetched', false)
                .set('resurveyPracticeHeatMapFetched', false)
                .set('heatMapInclusionFetched', false)
                .set('heatMapPracticeFetched', false)
                .set('heatMapRankingFetched', false)
                .set('engagementDataFetched', false)
                .set('npsDataFetched', false)
                .set('engagementDemographicDataFetched', false)
                .set('npsDemographicDataFetched', false)
                .set('clientEngagementFetched', false)
                .set('ieHeatmapFetched', false)
                .set('singleQuestionFetched', false)
                .set('singleQuestionFetched', false)
                .set('orgHealthFetched', false)
                .set('practiceDataCompYr', fromJS({}))
                .set('scoreDataHybridFetched', false)
                .set('empExpHeatmaps', fromJS({}))
                .set('empExpOhiHeatmaps', fromJS({}))
                .set('enpsFetched', false)
                .set('localPracticeFetched', false)
                .set('empExpResurveyHeatmapDataFetched', false);
        }
        case UPDATE_INCLUSION_FILTERS: {
            const apiParams = state.get('apiParams').toJS() || {};
            const { benchmarks: benchM } = apiParams;
            const { with_benchmark, benchmarks, ...rest } = payload.apiParams;
            return state
                .setIn(['filters', 0, 'demographics'], payload.filters[0].demographics)
                .set('filtersApplied', !!payload.apiParams.filters.length)
                .set('apiParams', fromJS({ ...rest, benchmarks: benchM }))
                .set('scoreDataFetched', false)
                .set('valuesDataFetched', false)
                .set('practiceDataFetched', false)
                .set('heatMapDataFetched', false)
                .set('cohesionDataFetched', false)
                .set('resurveyOutcomeHeatMapFetched', false)
                .set('resurveyPracticeHeatMapFetched', false)
                .set('heatMapInclusionFetched', false)
                .set('heatMapPracticeFetched', false)
                .set('heatMapRankingFetched', false)
                .set('scoreDataHybridFetched', false);
        }
        case RESET_FILTER_INDEX: {
            return state.deleteIn(['filters', payload.indexToUpdate]);
        }
        case UPDATE_API_PARAMS: {
            return state
                .updateIn(['apiParams'], oldSettings => oldSettings.merge(payload))
                .set('scoreDataFetched', false)
                .set('valuesDataFetched', false)
                .set('practiceDataFetched', false)
                .set('heatMapDataFetched', false)
                .set('cohesionDataFetched', false)
                .set('resurveyOutcomeHeatMapFetched', false)
                .set('resurveyPracticeHeatMapFetched', false)
                .set('heatMapInclusionFetched', false)
                .set('heatMapPracticeFetched', false)
                .set('heatMapRankingFetched', false)
                .set('scoreDataHybridFetched', false);
        }
        case `${GET_HEATMAP_OUTCOME}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('heatMap', payload.items)
                .set('heatMapDataFetched', true);
        }
        case `${UPDATE_HEAT_MAP_OUTCOME}_SUCCESS`: {
            return state.updateIn(['heatMap', 'items'], items => items.concat(payload.items));
        }
        case `${GET_RESURVEY_HEATMAP_OUTCOME}_SUCCESS`: {
            const { page, ...rest } = payload;
            if (page !== 1) {
                return state
                    .updateIn(['resurveyOutcomeHeatMap', 'loadMoreData'], () => rest.loadMoreData)
                    .updateIn(['resurveyOutcomeHeatMap', 'items'], items => items.concat(rest.items));
            }
            return state
                .update('count', count => count - 1)
                .set('resurveyOutcomeHeatMap', fromJS(rest))
                .set('resurveyOutcomeHeatMapFetched', true);
        }
        case `${GET_RESURVEY_HEATMAP_PRACTICE}_SUCCESS`: {
            const { page, ...rest } = payload;
            if (page !== 1) {
                return state
                    .updateIn(['resurveyPracticeHeatMap', 'loadMoreData'], () => rest.loadMoreData)
                    .updateIn(['resurveyPracticeHeatMap', 'header'], header => header.concat(rest.header))
                    .updateIn(['resurveyPracticeHeatMap', 'items'], practiceItem => {
                        const { items } = payload;
                        if (items.length > 0) {
                            const practiceArr = [];
                            practiceItem.forEach(val => {
                                const practiceObj = {};
                                const index = items.findIndex(item => item.title === val.title);
                                if (index > -1) {
                                    practiceObj.title = items[index].title || '';
                                    practiceObj.parent_name = items[index].parent_name;
                                    practiceObj.items = [...val.items, ...items[index].items];
                                    practiceArr.push(practiceObj);
                                }
                            });
                            return practiceArr;
                        }
                        return practiceItem;
                    });
            }
            return state
                .update('count', count => count - 1)
                .set('resurveyPracticeHeatMap', rest)
                .set('resurveyPracticeHeatMapFetched', true);
        }
        case `${GET_RESURVEY_HEATMAP_OUTCOME}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('resurveyOutcomeHeatMap', payload)
                .set('resurveyOutcomeHeatMapFetched', true);
        }
        case `${GET_RESURVEY_HEATMAP_PRACTICE}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('resurveyPracticeHeatMap', payload)
                .set('resurveyPracticeHeatMapFetched', true);
        }
        case `${UPDATE_HEAT_MAP_PRACTICE}_SUCCESS`: {
            return state
                .updateIn(['heatMapPractice', 'header'], header => header.concat(payload.header))
                .updateIn(['heatMapPractice', 'items'], practiceItem => {
                    const { items } = payload;
                    if (items.length > 0) {
                        const practiceArr = [];
                        practiceItem.forEach(val => {
                            const practiceObj = {};
                            const index = items.findIndex(item => item.title === val.title);
                            if (index > -1) {
                                practiceObj.title = items[index].title || '';
                                practiceObj.parent_name = items[index].parent_name;
                                practiceObj.items = [...val.items, ...items[index].items];
                                practiceArr.push(practiceObj);
                            }
                        });
                        return practiceArr;
                    }
                    return practiceItem;
                });
        }
        case `${UPDATE_HEAT_MAP_RANKING}_SUCCESS`: {
            return state
                .updateIn(['heatMapRanking', 'header'], header => header.concat(payload.header))
                .updateIn(['heatMapRanking', 'items'], practiceItem => {
                    const { items } = payload;
                    if (items.length > 0) {
                        const practiceArr = [];
                        practiceItem.forEach(val => {
                            const practiceObj = {};
                            const index = items.findIndex(item => item.title === val.title);
                            if (index > -1) {
                                practiceObj.title = items[index].title || '';
                                practiceObj.parent_name = items[index].parent_name;
                                practiceObj.items = [...val.items, ...items[index].items];
                                practiceArr.push(practiceObj);
                            }
                        });
                        return practiceArr;
                    }
                    return practiceItem;
                });
        }
        case SORT_HEAT_MAP_OUTCOME: {
            return state.updateIn(['heatMap', 'items'], () => payload);
        }
        case SORT_HEAT_MAP_PRACTICE: {
            return state.update('heatMapPractice', () => payload);
        }
        case SORT_HEAT_MAP_RANKING: {
            const { data, key } = payload;
            return state.updateIn(['heatMapRanking', key], () => data);
        }
        case `${GET_HEATMAP_OUTCOME}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('heatMap', payload)
                .set('heatMapDataFetched', true);
        }
        case SET_SELECTED_DEMOGRAPHIC: {
            return state
                .set('selectedDemographic', payload)
                .set('heatMapDataFetched', false)
                .set('heatMapInclusionFetched', false)
                .set('heatMapPracticeFetched', false)
                .set('heatMapRankingFetched', false);
        }
        case SET_DEMOGRAPHIC_CURRENT_TAB: {
            return state.set('demographicCurrentTab', payload);
        }
        case SET_ADD_ON_TAB: {
            return state.set('addOnTab', payload);
        }
        case SET_RESURVEY_HEATMAP_TAB: {
            return state.set('heatMapResurveyTab', payload);
        }
        case SET_FILTER_COLUMNS: {
            return state.set('filterColumns', payload);
        }
        case `${GET_HEATMAP_PRACTICE}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('heatMapPractice', payload)
                .set('heatMapPracticeFetched', true);
        }
        case `${GET_HEATMAP_PRACTICE}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('heatMapPractice', payload)
                .set('heatMapPracticeFetched', true);
        }
        case `${GET_LOCAL_PRACTICE}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('localPractice', payload)
                .set('localPracticeFetched', true);
        }
        case `${GET_LOCAL_PRACTICE}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('localPracticeFetched', true);
        }
        case `${GET_HEATMAP_INCLUSION}_PENDING`: {
            return state.set('heatMapInclusionError', '').update('count', count => count + 1);
        }
        case `${GET_HEATMAP_INCLUSION}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('heatMapInclusion', payload)
                .set('heatMapInclusionFetched', true);
        }
        case `${GET_HEATMAP_INCLUSION}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('heatMapInclusionError', payload.error)
                .set('heatMapInclusion', fromJS({}))
                .set('heatMapInclusionFetched', true);
        }
        case `${GET_RANKING_HEATMAP}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('heatMapRanking', payload)
                .set('heatMapRankingFetched', true);
        }
        case `${GET_RANKING_HEATMAP}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('heatMapRanking', payload)
                .set('heatMapRankingFetched', true);
        }
        case `${GET_PROJECTS}_SUCCESS`: {
            return state.set('projects', fromJS(payload)).set('projectsFetched', true);
        }
        case `${GET_PROJECTS}_FAILED`: {
            return state.set('error', payload.error).set('projectsFetched', true);
        }
        case RESET_RESURVEY_FETCHED: {
            return state.set('reSurveyDataFetched', payload);
        }
        case `${GET_RESURVEY_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('reSurveyData', payload)
                .set('reSurveyDataFetched', true);
        }
        case `${GET_RESURVEY_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('reSurveyData', fromJS({}))
                .set('reSurveyDataFetched', true);
        }
        case `${GET_BU_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('buData', payload)
                .set('buDataFetched', true);
        }
        case `${GET_BU_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('buData', fromJS({}))
                .set('buDataFetched', true);
        }
        case `${GET_PRACTICE_BU_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('practiceBuData', payload)
                .set('practiceBuDataFetched', true);
        }
        case `${GET_PRACTICE_BU_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('practiceBuData', fromJS([]))
                .set('practiceBuDataFetched', true);
        }
        case SORT_IE_HEAT_MAP: {
            return state.updateIn(['ieHeatmap', 'items'], () => payload);
        }
        case `${GET_IE_HEATMAP}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('ieHeatmap', payload)
                .set('ieHeatmapFetched', true);
        }
        case `${GET_IE_HEATMAP}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('ieHeatmap', fromJS({}))
                .set('ieHeatmapFetched', true);
        }
        case `${GET_EMP_EXP_HEATMAP}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('empExpHeatmaps', payload)
                .set('enpsFetched', true);
        }
        case `${GET_EMP_EXP_HEATMAP}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('enpsFetched', true);
        }
        case `${GET_OHI_EMP_EXP_HEATMAP}_SUCCESS`: {
            return state.update('count', count => count - 1).set('empExpOhiHeatmaps', payload);
        }
        case `${GET_OHI_EMP_EXP_HEATMAP}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('empExpOhiHeatmaps', fromJS({}));
        }
        case `${GET_PPT_DATA}_PENDING`: {
            return state
                .set('error', '')
                .set('showProgressBar', true)
                .set('pptError', false)
                .update('count', count => count + countToAdd)
                .set('pptStatus', fromJS([]));
        }
        case `${GET_PPT_DATA}_SUCCESS`: {
            return state.set('showProgressBar', false).update('count', count => count - payload.countToAdd);
        }
        case `${GET_PPT_DATA}_FAILED`: {
            const showProgressBar = payload.status === 504;
            return state
                .set('pptError', showProgressBar)
                .set('showProgressBar', showProgressBar)
                .update('count', count => count - payload.countToAdd);
        }
        case `${GET_PPT_STATUS}_PENDING`: {
            return state.set('pptStatusFetching', true);
        }
        case `${GET_PPT_STATUS}_SUCCESS`: {
            return state.set('pptStatusFetching', false).set('pptStatus', payload);
        }
        case `${GET_PPT_STATUS}_FAILED`: {
            return state.set('pptStatusFetching', false);
        }
        case RESET_BU_FETCHED: {
            return state.set('buDataFetched', payload);
        }
        case RESET_PRACTICE_BU_FETCHED: {
            return state.set('practiceBuDataFetched', payload);
        }
        case `${GET_TOOLBOX_THEME}_PENDING`: {
            return state.set('toolBoxError', '').set('toolBoxLoading', true);
        }
        case `${GET_TOOLBOX_THEME}_SUCCESS`: {
            return state
                .set('toolBoxLoading', false)
                .set('toolBox', fromJS(payload))
                .set('toolBoxFetched', true);
        }
        case `${GET_TOOLBOX_THEME}_FAILED`: {
            return state
                .set('toolBoxLoading', false)
                .set('toolBoxFetched', true)
                .set('toolBoxError', payload.error);
        }
        case `${DELETE_THEME}_PENDING`:
        case `${CREATE_THEME}_PENDING`: {
            return state.set('toolBoxError', '').update('count', count => count + 1);
        }
        case `${CREATE_THEME}_SUCCESS`: {
            return state.updateIn(['toolBox'], arr => arr.unshift(payload)).update('count', count => count - 1);
        }
        case `${DELETE_THEME}_SUCCESS`: {
            return state.updateIn(['toolBox'], arr => arr.delete(payload)).update('count', count => count - 1);
        }
        case `${DELETE_THEME}_FAILED`:
        case `${CREATE_THEME}_FAILED`: {
            return state.update('count', count => count - 1).set('toolBoxError', payload.error);
        }
        case TOGGLE_CREATE_THEME: {
            return state.set('enableCreateTheme', payload);
        }
        case TOGGLE_PROGRESS_BAR: {
            return state.set('showProgressBar', payload);
        }
        case SET_THEME: {
            return state.set('currentTheme', payload);
        }
        case SET_PRACTICE_DISPLAY: {
            return state.set('practiceToDisplay', payload);
        }
        case SET_HEATMAP_PRACTICE_DATA: {
            return state.set('loadHeatMapPractice', payload);
        }
        case SET_HEATMAP_OUTCOME_DATA: {
            return state.set('loadHeatMapOutcome', payload);
        }
        case SET_HEATMAP_RANKING_DATA: {
            return state.set('loadHeatMapRanking', payload);
        }
        case SET_PRACTICE_PAGE_NO: {
            return state.set('practicePageNo', payload);
        }
        case SET_OUTCOME_PAGE_NO: {
            return state.set('outcomePageNo', payload);
        }
        case SET_RANKING_PAGE_NO: {
            return state.set('rankingPageNo', payload);
        }
        case SET_PRACTICE_RETRIEVED: {
            return state.set('isPracticeRetrived', payload);
        }
        case SET_OUTCOME_RETRIVED: {
            return state.set('isOutcomeRetrieved', payload);
        }
        case SET_RANKING_HEATMAP_RETRIEVED: {
            return state.set('isRankingHeatMapRetrived', payload);
        }
        case SET_NPS_DROPDOWN: {
            return state.set('npsDropdown', payload);
        }
        case SET_ENGAGEMENT_DROPDOWN: {
            return state.set('engagementDropdown', payload);
        }
        case REMOVE_SAVED_TOKEN: {
            if (!payload) {
                return INITIAL_STATE;
            }
            return state;
        }
        case `${GET_COHESION_DATA}_PENDING`: {
            return state.update('count', count => count + 1).set('cohesionError', '');
        }
        case `${GET_COHESION_DATA}_SUCCESS`: {
            const { res_org_value = '' } = payload.data;
            const cohesionScore = payload.updateScore ? res_org_value : state.toJS().cohesionScore;
            return state
                .update('count', count => count - 1)
                .set('cohesionData', payload.data)
                .set('cohesionScore', cohesionScore)
                .set('cohesionDataFetched', true);
        }
        case `${GET_COHESION_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('cohesionError', payload.error)
                .set('cohesionData', fromJS({}))
                .set('cohesionScore', 0)
                .set('cohesionDataFetched', true);
        }
        case UPDATE_DEFAULT_SETTINGS: {
            const { cohesion_default_Demographic, report_type = '', benchmark: defaultBench = [] } = payload;
            const defaultBenchmarks = state.get('defaultBenchmarks');
            const flagpoleOriginalBench = state.get('flagpoleOriginalBench');
            const targetBenchmarks = state.get('targetBenchmarks');
            const targetBm = state.get('targetBm');
            const engagement = state.get('engagement');
            const benchmarks = defaultBenchmarks.length ? defaultBenchmarks : [];
            const flagBenchmarks = flagpoleOriginalBench.length ? flagpoleOriginalBench : [];
            let benchmarksToUse = cloneDeep(benchmarks);
            let flagBenchmarksToUse = cloneDeep(flagBenchmarks);
            let rightFlagBenchmarksToUse = cloneDeep(flagBenchmarks);
            const targetBenchmarksToUse = cloneDeep(targetBenchmarks);

            const defaultSplit = defaultBench[0].split(':');
            const defaultCode = defaultSplit[1];

            if (report_type === PERCENTILE) {
                if (benchmarks.length) {
                    benchmarksToUse = benchmarks.filter(({ code }) => code === parseInt(defaultCode, 10));
                }
                if (flagBenchmarksToUse.length) {
                    flagBenchmarksToUse = flagBenchmarks.filter(({ code }) => code === parseInt(defaultCode, 10));
                }
            }

            const indexToUse = benchmarksToUse.findIndex(({ code }) => code === parseInt(defaultCode, 10));
            const flagIndexToUse = flagBenchmarksToUse.findIndex(({ code }) => code === parseInt(defaultCode, 10));
            const targetBmIndexToUse = targetBenchmarksToUse.findIndex(
                ({ code }) => code === parseInt(defaultCode, 10)
            );

            if (indexToUse !== -1) {
                const defaultChildCode = defaultSplit[0] || '';
                const { options = [] } = benchmarksToUse[indexToUse];
                const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
                const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
                benchmarksToUse[indexToUse].selectedOption =
                    benchmarksToUse[indexToUse].options[selectedChildIndex].code;
            }
            if (flagIndexToUse !== -1) {
                const defaultChildCode = defaultSplit[0] || '';
                const { options = [] } = flagBenchmarksToUse[flagIndexToUse];
                const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
                const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
                flagBenchmarksToUse[flagIndexToUse].selectedOption =
                    flagBenchmarksToUse[flagIndexToUse].options[selectedChildIndex].code;
            }
            if (targetBmIndexToUse !== -1) {
                const targetbmSplit = targetBm.split(':') || [];
                const defaultChildCode = targetbmSplit[0] || '';
                const { options = [] } = targetBenchmarksToUse[targetBmIndexToUse];
                const childIndexToUse = options.findIndex(({ code }) => code === parseInt(defaultChildCode, 10));
                const selectedChildIndex = childIndexToUse !== -1 ? childIndexToUse : 0;
                targetBenchmarksToUse[targetBmIndexToUse].selectedOption =
                    targetBenchmarksToUse[targetBmIndexToUse].options[selectedChildIndex].code;
            }
            if (
                engagement === NON_PROFIT_SURVEY &&
                rightFlagBenchmarksToUse.length &&
                rightFlagBenchmarksToUse[0].options[0].code === 1001
            ) {
                rightFlagBenchmarksToUse[0].selectedOption = 1001;
            } else {
                rightFlagBenchmarksToUse = flagBenchmarksToUse;
            }
            return state
                .set('cohesionDemographic', cohesion_default_Demographic)
                .set('leftBenchmarks', fromJS(flagBenchmarksToUse))
                .set('rightBenchmarks', fromJS(rightFlagBenchmarksToUse))
                .set('targetBenchmarks', fromJS(targetBenchmarksToUse))
                .setIn(['filters', 0, 'benchmarks'], fromJS(benchmarksToUse));
        }
        case SET_COHESION_DEMOGRAPHIC: {
            return state.set('cohesionDemographic', payload);
        }
        case `${SET_COHESION_SORT}_SUCCESS`: {
            return state.update('count', count => count - 1).set('cohesionDataFetched', false);
        }
        case `${SET_COHESION_SORT}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.error);
        }
        case `${GET_MAPPED_DEMOGRAPHICS}_SUCCESS`: {
            const { mappedDemos, indexToUse } = payload;
            return state
                .update('count', count => count - 1)
                .updateIn(['projects', indexToUse], oldData => oldData.merge({ mappedDemos, mappedDemoFetched: true }));
        }
        case `${GET_MAPPED_DEMOGRAPHICS}_FAILED`: {
            const { indexToUse } = payload;
            return state
                .update('count', count => count - 1)
                .updateIn(['projects', indexToUse], oldData =>
                    oldData.merge({ mappedDemos: fromJS([]), mappedDemoFetched: true })
                );
        }
        case `${GET_NPS_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('npsData', payload)
                .set('npsDataFetched', true);
        }
        case `${GET_NPS_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('npsData', fromJS([]))
                .set('npsDataFetched', true);
        }
        case `${GET_SINGLE_QUESTION}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('singleQuestion', payload)
                .set('singleQuestionFetched', true);
        }
        case `${GET_SINGLE_QUESTION}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('singleQuestion', fromJS([]))
                .set('singleQuestionFetched', true);
        }
        case `${GET_NPS_DEMOGRAPHIC_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('npsDemographicData', payload)
                .set('npsDemographicDataFetched', true);
        }
        case `${GET_NPS_DEMOGRAPHIC_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('npsDemographicData', fromJS([]))
                .set('npsDemographicDataFetched', true);
        }
        case `${GET_ENGAGEMENT_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('engagementData', payload)
                .set('engagementDataFetched', true);
        }
        case `${GET_ENGAGEMENT_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('engagementData', fromJS({}))
                .set('engagementDataFetched', true);
        }
        case `${GET_ENGAGEMENT_DEMOGRAPHIC_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('engagementDemographicData', payload)
                .set('engagementDemographicDataFetched', true);
        }
        case `${GET_ENGAGEMENT_DEMOGRAPHIC_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('engagementDemographicData', fromJS({}))
                .set('engagementDemographicDataFetched', true);
        }
        case `${GET_CUSTOM_BU_SCORE_DATA}_SUCCESS`: {
            return state.update('count', count => count - 1).set('filterColumns', payload);
        }
        case `${GET_CUSTOM_BU_SCORE_DATA}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.error);
        }
        case `${GET_CUSTOM_BU_LANG_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('customBuLang', payload)
                .set('customBuLangFetched', true);
        }
        case `${GET_CUSTOM_BU_LANG_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('customBuLangFetched', true)
                .set('error', payload.error);
        }
        case `${GET_TLNX_ENGAGEMENT_DATA}_SUCCESS`: {
            return state.update('count', count => count - 1).set('tlnxEngagement', payload);
        }
        case `${GET_TLNX_ENGAGEMENT_DATA}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.error);
        }
        case `${GET_SINGLE_PAST_REPORT}_SUCCESS`: {
            return state.update('count', count => count - 1).set('pastReport', payload);
        }
        case `${GET_SINGLE_PAST_REPORT}_FAILED`: {
            return state.update('count', count => count - 1).set('error', payload.message);
        }
        case `${GET_ORGHEALTH_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('orgHealth', payload)
                .set('orgHealthFetched', true);
        }
        case `${GET_ORGHEALTH_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('orgHealthFetched', true);
        }
        case SET_CUSTOM_BU_FILTER: {
            return state.set('customBuFilters', payload);
        }
        case `${GET_SCORE_DATA_HYBRID}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('scoreDataHybrid', payload)
                .set('scoreDataHybridFetched', true);
        }
        case `${GET_SCORE_DATA_HYBRID}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('scoreDataHybrid', fromJS({}))
                .set('scoreDataHybridFetched', true);
        }
        case `${SEND_FEEDBACK}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('feedback', payload)
                .set('sendFeedbackSuccess', true);
        }
        case `${SEND_FEEDBACK}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('sendFeedbackSuccess', true)
                .set('error', payload.error);
        }
        case `${UPDATE_SETTINGS}_SUCCESS`: {
            return state
                .set('filters', fromJS([]))
                .set('filtersApplied', false)
                .set('scoreDataFetched', false)
                .set('valuesDataFetched', false)
                .set('practiceDataFetched', false)
                .set('heatMapDataFetched', false)
                .set('cohesionDataFetched', false)
                .set('resurveyOutcomeHeatMapFetched', false)
                .set('resurveyPracticeHeatMapFetched', false)
                .set('heatMapInclusionFetched', false)
                .set('heatMapPracticeFetched', false)
                .set('heatMapRankingFetched', false)
                .set('engagementDataFetched', false)
                .set('npsDataFetched', false)
                .set('engagementDemographicDataFetched', false)
                .set('npsDemographicDataFetched', false)
                .set('clientEngagementFetched', false)
                .set('ieHeatmapFetched', false)
                .set('singleQuestionFetched', false)
                .set('singleQuestionFetched', false)
                .set('orgHealthFetched', false)
                .set('practiceDataCompYr', fromJS({}))
                .set('scoreDataHybridFetched', false);
        }
        case `${GET_TARGET_SCORE_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('targetScoreData', payload)
                .set('targetScoreDataFetched', true);
        }
        case `${GET_TARGET_SCORE_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('targetScoreData', fromJS({}))
                .set('targetScoreDataFetched', true);
        }
        case RESET_FEEDBACK: {
            return state.set('sendFeedbackSuccess', false).set('feedback', '');
        }
        case SET_FEEDBACK_OPTIONS: {
            return state.set('feedbackOptions', payload);
        }
        case UPDATE_TARGET_SETTING_BM: {
            const benchmark = getBenchmarkString(payload);
            return state
                .set('targetBenchmarks', payload)
                .set('targetBm', benchmark)
                .set('targetScoreDataFetched', false)
                .set('practiceDataFetched', false);
        }
        case `${GET_EMP_EXP_RES_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('empExpResurveyData', payload)
                .set('empExpResurveyDataFetched', true);
        }
        case `${GET_EMP_EXP_RES_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('empExpResurveyData', fromJS({}))
                .set('empExpResurveyDataFetched', true);
        }
        case RESET_EMP_EXP_RES_FETCHED: {
            return state.set('empExpResurveyDataFetched', payload);
        }
        case `${GET_EMP_EXP_RES_HEATMAP_DATA}_SUCCESS`: {
            return state
                .update('count', count => count - 1)
                .set('empExpResurveyHeatmapData', payload)
                .set('empExpResurveyHeatmapDataFetched', true);
        }
        case `${GET_EMP_EXP_RES_HEATMAP_DATA}_FAILED`: {
            return state
                .update('count', count => count - 1)
                .set('error', payload.error)
                .set('empExpResurveyHeatmapData', fromJS({}))
                .set('empExpResurveyHeatmapDataFetched', true);
        }
        default:
            return state;
    }
};

export default reducer;
