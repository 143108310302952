import React, { Fragment, useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';
import Lottie from 'react-lottie';
import AuthStore from '../../../common/AuthStore';
import FilterSvg from '../assets/Filter.svg';
import { getStaticText } from '../../../utils/constants';
import { sortArrayOnNumber } from '../../../utils/functions';
import './index.scss';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import Down from '../assets/Down.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import UpArrow from '../assets/arrow-up.svg';
import DownArrow from '../assets/arrow-down.svg';
import SquareMinus from '../assets/minus-square.svg';
import NoSig from '../assets/no_significance.svg';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import BulletList from '../assets/BulletList.svg';
import animationData from '../assets/lottie.json';
import CompareView from '../assets/CompareView.svg';
import SelectModal from '../../../components/SelectModal';
import getStaticTextDiagnose from '../constants';

function ResurveyReport({
    scoreData,
    scoreDataFetched,
    getScoreData,
    toggleFilterView,
    apiParams,
    apiLoadingCount,
    activeFilter,
    errorInfo,
    filters,
    defaultSettings,
    appliedFiltersInfo,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
    getScoreDataForHybrid,
    // scoreDataHybrid,
    scoreDataHybridFetched,
    projectsFetched,
    projects,
    selectedIds,
    callReSurveyApi,
    setReport,
    reSurveyData,
    years,
    getResurveyParams,
    currentView,
}) {
    const {
        report_name_or_client_name: rName = '',
        lang,
        report_id = '',
        // checkme_mapping = {},
        demographic = '',
        quartileColors = {},
        // is_ohi_pss_module,
        is_qbyq_4_vs_3_2_required = false,
        report_type = '',
        threshold,
        resurvey_settings = '',
        resurvey_custom_delta = [],
    } = defaultSettings;
    const staticText = getStaticText(lang);
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { QUARTILE_RESURVEY, CUSTOM_RESURVEY } = staticTextDiagnose;
    const { SITE_TEXT, RESURVEY_EXPORT_PARAMS } = staticText;

    const {
        QUESTION_HEAD,
        PRACTICE_SUBHEAD,
        QUESTIONS_LEGEND,
        QUESTIONS_PRACTICE_LEGEND,
        FILTERS,
        NOTE,
        DOWNLOAD,
        HEALTH_QUESTIONS_BOARD,
        AGGREMENT_LEVEL_PERCENT,
        PERCENT_FAVORABLE,
        PERCENTILES,
        RESURVEY_TEXT,
        DIFF_HEAD,
        DIFFERENCE,
        STATISTICAL_DIFFERENCE,
        QUARTILE_DIFFERENCE,
        CUSTOM_DIFFERENCE,
        QUARTILE_MOVE_LEGEND_TXT,
        CUSTOM_DELTA_LEGEND_TXT,
        QUESTION_SIGNIFICANCE,
        OHI4_RESURVEY_NOTE,
        TOTAL_RES_TEXT,
    } = SITE_TEXT || {};

    // const updatedCheckmeMapping = replacePssKeys(
    //     checkme_mapping,
    //     is_ohi_pss_module,
    //     QUESTION_REPLACEMENTS,
    //     'questions'
    // );
    const { survey_version = '' } = scoreData;
    const { records = {}, sizes = {}, sig_applicable } = reSurveyData;
    const { nSize: n_size = [] } = sizes || {};
    const { ohi_score, ...dataToIterate } = records;
    const [expandedList, updateExpandedList] = useState([]);
    const [dwnld, setdwnld] = useState(false);
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const filterCheck = apiParams.filters || [];
    const { $white, $black } = colors;
    const [isExpanded, setIsExpanded] = useState(false);
    const [showReSurveyModal, setReSurveyModal] = useState(false);
    const [resurveyCustom, setResurveyCustom] = useState([]);
    const isCustomMovement = resurvey_settings.toLowerCase() === CUSTOM_RESURVEY;
    const isQuartMovement = resurvey_settings.toLowerCase() === QUARTILE_RESURVEY;
    useEffect(() => {
        if (resurvey_custom_delta) {
            setResurveyCustom([resurvey_custom_delta]);
        }
        // eslint-disable-next-line
    }, []);
    const range = ['less than -n', 'more than n', 'between -n to n'];
    const generateRanges = n => {
        const ranges = [
            { key: `less than -${n}`, values: [] },
            { key: `more than ${n}`, values: [] },
            { key: `between -${n} to ${n}`, values: [] },
        ];
        return ranges;
    };
    const rangesHere = resurveyCustom[0] !== '' ? generateRanges(resurveyCustom) : [];

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        setIsExpanded(true);
        updateExpandedList(newList);
    };

    useEffect(() => {
        if (!scoreDataFetched && !apiLoadingCount) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, apiParams]);

    useEffect(() => {
        if (!scoreDataHybridFetched && !apiLoadingCount && is_qbyq_4_vs_3_2_required) {
            getScoreDataForHybrid({
                ...apiParams,
                is_4_vs_3: 1,
            });
        }
        // eslint-disable-next-line
    }, [scoreDataHybridFetched, apiParams]);

    const ExpandIcon = () => {
        const defaultOptions = {
            loop: false,
            autoplay: false,
            animationData,
        };

        return (
            <div className="expand-icon">
                <Lottie options={defaultOptions} />
            </div>
        );
    };

    const ExpandIconAnimated = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
        };

        const onClickOptions = {
            ...defaultOptions,
            loop: false,
            autoplay: false,
        };

        const handleClick = () => {
            setIsExpanded(true);
        };

        return (
            <div className="expand-icon" onClick={handleClick}>
                <Lottie options={isExpanded ? onClickOptions : defaultOptions} />
            </div>
        );
    };

    // const v4PptDownload = () => {
    //     const addOns = {
    //         fill_threshold_with: demographic === '1' ? '-' : '',
    //         others: demographic === '3' ? 1 : 0,
    //     };
    //     const { benchmarks = [] } = filters[0];
    //     const label = getBenchmarkName(benchmarks);
    //     const valueToSend = {
    //         ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
    //         ...addOns,
    //         report_name: rName,
    //         ...apiParams,
    //         report_id,
    //         single_page_meta: {
    //             checkme: [...checkmeToUse],
    //             name: 'questions',
    //         },
    //     };
    //     setdwnld(true);
    //     getReport(valueToSend);
    // };

    const v4PptDownload = () => {
        const checkme_4_0 = [
            'QbyQ_resurvey_direction_4_0',
            'QbyQ_resurvey_leadership_4_0',
            'QbyQ_resurvey_work_environment_4_0',
            'QbyQ_resurvey_accountability_4_0',
            'QbyQ_resurvey_coordination_control_4_0',
            'QbyQ_resurvey_capabilities_4_0',
            'QbyQ_resurvey_motivation_4_0',
            'QbyQ_resurvey_innovation_learning_4_0',
            'QbyQ_resurvey_external_orientation_4_0',
        ];
        const checkme_3_2 = [
            'QbyQ_resurvey_direction_3_2',
            'QbyQ_resurvey_leadership_3_2',
            'QbyQ_resurvey_work_environment_3_2',
            'QbyQ_resurvey_accountability_3_2',
            'QbyQ_resurvey_coordination_control_3_2',
            'QbyQ_resurvey_capabilities_3_2',
            'QbyQ_resurvey_motivation_3_2',
            'QbyQ_resurvey_innovation_learning_3_2',
            'QbyQ_resurvey_external_orientation_3_2',
        ];
        const checkmeToUse = survey_version === '4' ? checkme_4_0 : checkme_3_2;
        const { benchmark_str, ohid } = getResurveyParams(filters);
        const { bm_filters } = apiParams;
        const resurveyDemoName1 = AuthStore.resurveyFilter1 || 'Organization';
        const resurveyDemoName2 = AuthStore.resurveyFilter2 || 'Comparison survey';
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const resurveyParams = {
            ...RESURVEY_EXPORT_PARAMS,
            report_name: rName,
            ...addOns,
            options: {
                ...RESURVEY_EXPORT_PARAMS.options,
                survey_names: `${ohid.split(',')[0]}${resurveyDemoName1.split(',')}$#$${
                    ohid.split(',')[1]
                }${resurveyDemoName2.split(',')}`,
            },
            bm_filters,
            benchmarks: benchmark_str,
            threshold,
            report_id,
        };
        const valueToSend = {
            ...resurveyParams,
            ...getResurveyParams(filters),
            single_page_meta: {
                checkme: checkmeToUse,
                name: 'scorecard',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const scoreDifference = (num1, num2) => {
        if (isNaN(parseInt(num1, 10)) || isNaN(parseInt(num2, 10))) {
            return '-';
        }
        return num1 - num2;
    };

    const oldSelectedId = selectedIds.length ? selectedIds[0] : '';

    const selectProject = (ohId, reportType) => {
        setReSurveyModal(false);
        callReSurveyApi([ohId]);
        setReport(reportType);
    };

    const renderIcon = (sig, diff) => {
        const diffToUse = sig;
        if (isNaN(parseInt(sig, 10)) && !isNaN(parseInt(diff, 10))) return NoSig;
        if (!diff) return null;
        if (diffToUse === 0) {
            return DownArrow;
        }
        if (diffToUse === 1) {
            return UpArrow;
        }
        if (diffToUse === 2) {
            return SquareMinus;
        }
        return null;
    };

    const handleResurveyBtn = () => {
        if (currentView === 0) {
            return setReSurveyModal(true);
        }
        return setReport(0);
    };

    const renderFooter = () => {
        if ((isCustomMovement || isQuartMovement) && sig_applicable) {
            return (
                <div className="note">
                    {report_type === 'percent favorable' && (
                        <div>
                            <b>{NOTE} : </b> {PERCENT_FAVORABLE}
                        </div>
                    )}
                    {report_type === 'percentile' && (
                        <div>
                            <b>{NOTE} : </b> {PERCENTILES}
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div className="note">
                {report_type === 'percent favorable' && (
                    <div>
                        <b>{NOTE} : </b> {PERCENT_FAVORABLE}
                    </div>
                )}
                {report_type === 'percentile' && (
                    <div>
                        <b>{NOTE} : </b> {PERCENTILES}
                    </div>
                )}
                {!isQuartMovement ? (
                    <Fragment>
                        {OHI4_RESURVEY_NOTE[0]}
                        <br />
                        {!sig_applicable ? (
                            <Fragment>
                                {OHI4_RESURVEY_NOTE[1]}
                                <br />
                                {OHI4_RESURVEY_NOTE[2]}
                                <br />
                            </Fragment>
                        ) : null}
                    </Fragment>
                ) : (
                    <Fragment>1. {OHI4_RESURVEY_NOTE[3]}</Fragment>
                )}
            </div>
        );
    };

    return (
        <div className="questionBoard resurveyBoard clearfix">
            <Helmet>
                <title>{HEALTH_QUESTIONS_BOARD}</title>
            </Helmet>

            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{QUESTION_HEAD}</div>
                    <div className="nRes">
                        {n_size.length ? (
                            <span>{`${TOTAL_RES_TEXT} ${n_size[0].toLocaleString('en-US')} (${
                                years[0]
                            }), ${n_size[1].toLocaleString('en-US')} (${years[1]})`}</span>
                        ) : null}
                    </div>
                </div>
                <ul className="navScoreList">
                    <li
                        data-testid="setReSurveyModalBtn"
                        onClick={handleResurveyBtn}
                        className={c({ hovered: currentView === 1 }, 'fImage resurveyImg')}
                    >
                        <img src={currentView === 0 ? BulletList : CompareView} alt="" />
                        <span className="upperHead">{RESURVEY_TEXT}</span>
                    </li>
                    {!errorInfo && (
                        <li data-testid="getPptButton" onClick={v4PptDownload} className="resurveyImg">
                            <img src={Down} alt="" />
                            <span className="lineHead upperHead">{DOWNLOAD}</span>
                        </li>
                    )}
                    <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                        <span className="filter">{FILTERS}</span>
                    </li>
                </ul>
            </div>

            {errorInfo && <ErrorToast message={errorInfo} />}
            {dwnld && !reportError && (
                <DwnldModal
                    getRawPptData={getRawPptData}
                    reportId={report_id}
                    open={dwnld}
                    setOpen={setdwnld}
                    getSinglePastReport={getSinglePastReport}
                    singlePastReport={singlePastReport}
                    defaultSettings={defaultSettings}
                />
            )}
            {Object.keys(scoreData).length ? (
                <Fragment>
                    {appliedFiltersInfo({ years })}
                    {Object.keys(dataToIterate).map((dataSet, index) => {
                        const {
                            display_name = '',
                            children = {},
                            meta_scores = [],
                            score: [outcomeScore0, outcomeScore1],
                            quartile: [quartile0, quartile1],
                            significance = [],
                            difference: diffArr = [],
                        } = dataToIterate[dataSet];
                        const diff = diffArr[0];
                        const isNormalStringD = meta_scores.some(({ qtext = '' }) => isNaN(parseInt(qtext[0], 10)));
                        const mScoreInUse = isNormalStringD ? meta_scores : sortArrayOnNumber(meta_scores, 'qtext');
                        const { background = $white, color = $black, border = colors.$grey200 } =
                            quartileColors[quartile0] || {};
                        const ohi4Border = isOhi4 && quartile0 !== '999' && quartile0 !== '-';
                        const ohi4Border1 = isOhi4 && quartile1 !== '999' && quartile1 !== '-';
                        const {
                            background: background1 = $white,
                            color: color1 = $black,
                            border: border1 = colors.$grey200,
                        } = quartileColors[quartile1] || {};
                        return (
                            <Accordion
                                classes={{ root: 'accordionRoot' }}
                                key={index}
                                expanded={expandedList.includes(index)}
                            >
                                <AccordionSummary
                                    data-testid={dataSet}
                                    expandIcon={index === 0 ? <ExpandIconAnimated /> : <ExpandIcon />}
                                    onClick={() => updateExpanded(index)}
                                    classes={{ content: 'accordionHead' }}
                                >
                                    <div className="outcomeContent">
                                        <div className="metaTotalScore year">
                                            <div className="circle">{years[0]}</div>
                                            <div className="circle">{years[1]}</div>
                                        </div>
                                        <div className="metaTotalScore">
                                            <div
                                                className="circle"
                                                style={{
                                                    background: ohi4Border
                                                        ? `linear-gradient(135deg, ${border} 25%, ${background} 25%)`
                                                        : background,
                                                    color,
                                                    borderColor: ohi4Border ? 'transparent' : border,
                                                    borderWidth: quartile0 === '' ? 1 : 2,
                                                    borderStyle: ohi4Border ? 'none' : 'solid',
                                                }}
                                            >
                                                {outcomeScore0}
                                            </div>
                                            <div
                                                className="circle"
                                                style={{
                                                    background: ohi4Border1
                                                        ? `linear-gradient(135deg, ${border1} 25%, ${background1} 25%)`
                                                        : background1,
                                                    color: color1,
                                                    borderColor: ohi4Border1 ? 'transparent' : border1,
                                                    borderWidth: quartile1 === '' ? 1 : 2,
                                                    borderStyle: ohi4Border1 ? 'none' : 'solid',
                                                }}
                                            >
                                                {outcomeScore1}
                                            </div>
                                            <div className="diff">
                                                {diff}
                                                <span
                                                    className={c('difference', {
                                                        negative: diff < 0 && significance[0] === '',
                                                    })}
                                                >
                                                    <img src={renderIcon(significance[0], diff)} alt="" />
                                                </span>
                                            </div>
                                        </div>
                                        {display_name}
                                        <ul className={c({ ohi4Legends: isOhi4 }, 'questionLegends')}>
                                            <li className="listTitle">{AGGREMENT_LEVEL_PERCENT}</li>
                                            <li>
                                                <span />
                                                {QUESTIONS_LEGEND[2]}
                                            </li>
                                            <li className="listTitle">{DIFF_HEAD}</li>
                                            {DIFFERENCE.map(({ legendText, color: cl }) => (
                                                <li key={legendText}>
                                                    <span style={{ background: cl }} />
                                                    {legendText}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <ul className="childContent">
                                        {Object.keys(children).map(childSet => {
                                            const { display_name: childName } = children[childSet];
                                            return <li key={childName}>{childName}</li>;
                                        })}
                                    </ul>
                                </AccordionSummary>
                                <AccordionDetails classes={{ root: 'clearfix' }}>
                                    <section className="leftSection">
                                        <div className="clearfix">
                                            <section className="metaSection clearfix metaHeader">
                                                <div className="metaText" />
                                                <div className={c({ ohi4Meta: isOhi4 }, 'metaScore')}>
                                                    <div className="resurveyMeta">{years[0]}</div>
                                                    <div className="delta">Delta</div>
                                                    <div className="resurveyMeta">{years[1]}</div>
                                                </div>
                                            </section>
                                            {mScoreInUse.map(({ qtext = '', score: [score0, score1] }) => {
                                                return (
                                                    <section key={qtext} className="metaSection clearfix">
                                                        <div className="metaText">{qtext}</div>
                                                        {score0 ? (
                                                            <div
                                                                className={c(
                                                                    {
                                                                        ohi4Meta: isOhi4,
                                                                    },
                                                                    'metaScore'
                                                                )}
                                                            >
                                                                <div className="resurveyMeta">
                                                                    <span style={{ width: `${score0}%` }}>
                                                                        {score0}
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className={c(
                                                                        {
                                                                            neg: score0 < score1,
                                                                            neutral: score0 === score1,
                                                                            pos: score0 > score1,
                                                                        },
                                                                        'delta'
                                                                    )}
                                                                >
                                                                    <span>{scoreDifference(score0, score1)}</span>
                                                                </div>
                                                                <div className="resurveyMeta">
                                                                    <span style={{ width: `${score1}%` }}>
                                                                        {score1}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </section>
                                                );
                                            })}
                                        </div>
                                        <h3 className="clearfix practiceHead">
                                            {display_name} {PRACTICE_SUBHEAD}
                                            <ul className={c({ ohi4Legends: isOhi4 }, 'questionLegends')}>
                                                <li className="listTitle">% of respondents</li>
                                                <li>
                                                    <span />
                                                    {QUESTIONS_PRACTICE_LEGEND[2]}
                                                </li>
                                            </ul>
                                        </h3>
                                        <div className="practiceSet">
                                            {Object.keys(children).map(practiceSet => {
                                                const {
                                                    display_name: practiceSetName = '',
                                                    meta_score: practiceSetScore = [],
                                                    quartile: [prQuartile0, prQuartile1],
                                                    significance: sig = [],
                                                    score: [practiceScore0, practiceScore1],
                                                    difference = [],
                                                } = children[practiceSet];
                                                const diffChild = difference[0];
                                                const isNormalString = practiceSetScore.some(({ qtext = '' }) =>
                                                    isNaN(parseInt(qtext[0], 10))
                                                );
                                                const practiceSetInUse = isNormalString
                                                    ? practiceSetScore
                                                    : sortArrayOnNumber(practiceSetScore, 'qtext');

                                                const {
                                                    background: prBg = $white,
                                                    color: prColor = $black,
                                                    border: prBorder = colors.$grey200,
                                                } = quartileColors[prQuartile0] || {};
                                                const {
                                                    background: prBg1 = $white,
                                                    color: prColor1 = $black,
                                                    border: prBorder1 = colors.$grey200,
                                                } = quartileColors[prQuartile1] || {};
                                                const ohi4PrBorder = isOhi4 && prQuartile0 !== '999';
                                                const ohi4PrBorder1 =
                                                    isOhi4 && prQuartile1 !== '999' && prQuartile1 !== '-';
                                                return (
                                                    <div key={practiceSetName} className="clearfix">
                                                        {practiceSetInUse.map((ques, indexChild) => {
                                                            const {
                                                                qtext: metaPracticeText = '',
                                                                score: [practiceQScore0, practiceQScore1],
                                                            } = ques || {};
                                                            return (
                                                                <Fragment key={metaPracticeText}>
                                                                    {!indexChild ? (
                                                                        <div className="practiceContent">
                                                                            <div className="metaTotalScore">
                                                                                <div
                                                                                    className="circle"
                                                                                    style={{
                                                                                        background: ohi4PrBorder
                                                                                            ? `linear-gradient(135deg, ${prBorder} 25%, ${prBg} 25%)`
                                                                                            : prBg,
                                                                                        color: prColor,
                                                                                        borderColor: ohi4PrBorder
                                                                                            ? 'transparent'
                                                                                            : prBorder,
                                                                                        borderWidth:
                                                                                            quartile0 === '' ? 1 : 2,
                                                                                        borderStyle: ohi4PrBorder
                                                                                            ? 'none'
                                                                                            : 'solid',
                                                                                    }}
                                                                                >
                                                                                    {practiceScore0}
                                                                                </div>
                                                                                <div
                                                                                    className="circle"
                                                                                    style={{
                                                                                        background: ohi4PrBorder1
                                                                                            ? `linear-gradient(135deg, ${prBorder1} 25%, ${prBg1} 25%)`
                                                                                            : prBg1,
                                                                                        color: prColor1,
                                                                                        borderColor: ohi4PrBorder1
                                                                                            ? 'transparent'
                                                                                            : prBorder1,
                                                                                        borderWidth:
                                                                                            quartile0 === '' ? 1 : 2,
                                                                                        borderStyle: ohi4PrBorder1
                                                                                            ? 'none'
                                                                                            : 'solid',
                                                                                    }}
                                                                                >
                                                                                    {practiceScore1}
                                                                                </div>
                                                                                <div className="diff">
                                                                                    {diffChild}
                                                                                    <span
                                                                                        className={c('difference', {
                                                                                            negative:
                                                                                                diffChild < 0 &&
                                                                                                sig[0] === '',
                                                                                        })}
                                                                                    >
                                                                                        <img
                                                                                            src={renderIcon(
                                                                                                sig[0],
                                                                                                diffChild
                                                                                            )}
                                                                                            alt=""
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <h4>{practiceSetName}</h4>
                                                                        </div>
                                                                    ) : null}
                                                                    <section className="metaSection clearfix">
                                                                        <div className="metaText">
                                                                            {metaPracticeText}
                                                                        </div>
                                                                        {practiceScore0 ? (
                                                                            <div
                                                                                className={c(
                                                                                    {
                                                                                        ohi4Meta: isOhi4,
                                                                                    },
                                                                                    'metaScore'
                                                                                )}
                                                                            >
                                                                                <div className="resurveyMeta">
                                                                                    {!isNaN(practiceScore0) && (
                                                                                        <span
                                                                                            style={{
                                                                                                width: `${practiceQScore0}%`,
                                                                                            }}
                                                                                        >
                                                                                            {practiceQScore0}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    className={c(
                                                                                        {
                                                                                            neg:
                                                                                                practiceQScore0 <
                                                                                                practiceQScore1,
                                                                                            neutral:
                                                                                                practiceQScore0 ===
                                                                                                practiceQScore1,
                                                                                            pos:
                                                                                                practiceQScore0 >
                                                                                                practiceQScore1,
                                                                                        },
                                                                                        'delta'
                                                                                    )}
                                                                                >
                                                                                    <span>
                                                                                        {scoreDifference(
                                                                                            practiceQScore0,
                                                                                            practiceQScore1
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="resurveyMeta">
                                                                                    {!isNaN(practiceScore1) && (
                                                                                        <span
                                                                                            style={{
                                                                                                width: `${practiceQScore1}%`,
                                                                                            }}
                                                                                        >
                                                                                            {practiceQScore1}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ) : null}
                                                                    </section>
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </section>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    <ul
                        className={c('bottomList questionsLegend clearfix', {
                            rangeList: isCustomMovement,
                        })}
                    >
                        {resurvey_settings.toLowerCase() === 'statistical significance' && (
                            <li>{STATISTICAL_DIFFERENCE} : </li>
                        )}
                        {resurvey_settings.toLowerCase() === 'quartile movement' && <li>{QUARTILE_DIFFERENCE} : </li>}
                        {resurvey_settings.toLowerCase() === 'custom delta' && <li>{CUSTOM_DIFFERENCE} : </li>}
                        {QUESTION_SIGNIFICANCE.map(({ title, imgSrc, checkSig = false, sup }, i) => {
                            if ((checkSig && !isOhi4) || (checkSig && sig_applicable)) return null;
                            const titleToUse = isQuartMovement ? QUARTILE_MOVE_LEGEND_TXT[i] : title;
                            const supVal = sup && isOhi4 && !isQuartMovement ? sup : false;
                            const newItem = CUSTOM_DELTA_LEGEND_TXT[i];
                            return (
                                <>
                                    {resurvey_settings !== 'custom delta' && (
                                        <li key={title}>
                                            <span>
                                                <img src={imgSrc} alt="" />
                                            </span>
                                            {titleToUse} {supVal && <sup>{sup}</sup>}
                                        </li>
                                    )}
                                    {resurvey_settings.toLowerCase() === 'custom delta' && (
                                        <li key={title}>
                                            <span>
                                                <img src={imgSrc} alt="" />
                                            </span>
                                            {newItem}
                                            {isCustomMovement ? (
                                                <>
                                                    {resurveyCustom[0] !== '' && i !== 3 ? (
                                                        <p style={{ marginLeft: '3px' }}>({rangesHere[i].key})</p>
                                                    ) : (
                                                        <p>{range[i]}</p>
                                                    )}
                                                </>
                                            ) : null}
                                        </li>
                                    )}
                                </>
                            );
                        })}
                    </ul>
                    {/* <div className="note">
                        <b>{NOTE}:</b>
                        <br />
                        1. {QUESTION_NOTE}
                        <br />
                        {report_type === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                        {report_type === 'percentile' && <div>2.{PERCENTILES}</div>}
                    </div> */}
                    {isOhi4 && <Fragment>{renderFooter()}</Fragment>}
                </Fragment>
            ) : null}
            {showReSurveyModal && (
                <SelectModal
                    defaultSettings={defaultSettings}
                    oldSelectedId={oldSelectedId}
                    projects={projects}
                    projectsFetched={projectsFetched}
                    closeModal={setReSurveyModal}
                    selectProject={selectProject}
                />
            )}
        </div>
    );
}

ResurveyReport.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    getScoreData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    getScoreDataForHybrid: PropTypes.func.isRequired,
    scoreDataHybridFetched: PropTypes.bool.isRequired,
    currentView: PropTypes.number.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    callReSurveyApi: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    reSurveyData: PropTypes.object.isRequired,
    years: PropTypes.array.isRequired,
    getResurveyParams: PropTypes.func.isRequired,
};

export default ResurveyReport;
