import Overview from '../assets/overview.svg';
import PracticeRanking from '../../Diagnose/assets/PracticeRanking.svg';
import PracticeRanking_active from '../../Diagnose/assets/practiceranking_active.svg';
import Scorecard from '../../Diagnose/assets/Scorecard.svg';
import Info from '../assets/intro.svg';
import Flagpole from '../assets/Flagpole.svg';
import Clipboard from '../assets/clipboard.svg';
import Team from '../assets/team.svg';
import Average from '../assets/average.svg';
import Office from '../assets/office.svg';
import People from '../assets/people.svg';
import Calendar from '../assets/calendar.svg';
import Chart from '../assets/chart.svg';
import Scorecard_active from '../../Diagnose/assets/Scorecard_active.svg';
import recipe from '../assets/recipe.svg';
import createBm from '../assets/create_bm.svg';
import automaticBox from '../assets/automatic_box.svg';
import summaryBox from '../assets/summary_box.svg';
import ScorecardSS from '../assets/gsod_scoreCard2.svg';
import PracticeRankingSS from '../assets/gsod_practiceRanking1.svg';
import FilterDropdown from '../assets/Filter_Dropdown.svg';
import colors from '../../../sass/colors';
import OverviewDataBase from '../assets/overview_data.svg';
import FlagpoleData from '../assets/flagpole_data.svg';
import DifferentAnalysisImg from '../assets/practice_ranking_inactive.svg';
import CustomBenchmark from '../assets/custom_benchmark.svg';

export const NAV_LINKS = [
    {
        imgSrc: Info,
        imgSrcActive: Info,
        index: 0,
    },
    {
        imgSrc: Overview,
        imgSrcActive: Overview,
        index: 1,
    },
    {
        imgSrc: Scorecard,
        imgSrcActive: Scorecard_active,
        index: 2,
    },
    {
        imgSrc: Flagpole,
        imgSrcActive: Flagpole,
        index: 3,
    },
    {
        imgSrc: PracticeRanking,
        imgSrcActive: PracticeRanking_active,
        index: 4,
    },
    {
        imgSrc: DifferentAnalysisImg,
        imgSrcActive: DifferentAnalysisImg,
        index: 5,
    },
    {
        imgSrc: CustomBenchmark,
        imgSrcActive: CustomBenchmark,
        index: 6,
    },
];

export const OVERVIEW_HEADER = {
    total_survey: {
        label: 'Number of Surveys',
        imgSrc: Clipboard,
    },
    survey_completion: {
        label: 'Total Respondents(n)',
        imgSrc: Team,
    },
    response_rate: {
        label: 'Average response rate',
        imgSrc: Average,
    },
};

export const SIGNIFICANCE = [
    {
        title: 'Negative Differentiator',
        bg: colors.$pink,
        text_color: colors.$white,
        borderWidth: 2,
    },
    {
        title: 'Neutral',
        bg: colors.$yellow200,
        text_color: colors.$white,
        borderWidth: 2,
    },
    {
        title: 'Positive Differentiator',
        bg: colors.$green400,
        text_color: colors.$white,
        borderWidth: 2,
    },
];

export const cardData = [
    {
        title: 'Organizations',
        heading: '>2,500',
        imgSrc: Office,
    },
    {
        title: 'Participants Surveyed',
        heading: '~8M',
        imgSrc: People,
    },
    {
        title: 'Years of usage',
        heading: '>20',
        imgSrc: Calendar,
    },
    {
        title: 'Industries, regional and context benchmarks',
        heading: '~100',
        imgSrc: Chart,
    },
];

export const analyticsPageInfo = [
    {
        tabName: 'Overview',
        tabNo: 1,
        tabDesc: 'Provides an overall view about surveys in our database and statistics by sectors, regions, and years',
        preview: {
            // mainImg: Database,
            mainImg: OverviewDataBase,
            leftRemark: 'Switch between Pointers',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
    {
        tabName: 'Scorecard',
        tabNo: 2,
        tabDesc:
            'Provide an overall view of average overall health, outcome, and practice scores globally, or in specified sector, region, etc.',
        preview: {
            mainImg: ScorecardSS,
            leftRemark: 'Switch between Pointers',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
    {
        tabName: 'Flagpole',
        tabNo: 3,
        tabDesc:
            'Show how organizations’ scores in a given sector or region compare to other organizations in our global benchmark. With these comparisons, you can identify which outcome and practice scores are relatively stronger or weaker in comparison to the median global benchmark score',
        preview: {
            mainImg: FlagpoleData,
            leftRemark: 'Expand to see Practice Scores',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
    {
        tabName: 'Practice Ranking',
        tabNo: 4,
        tabDesc:
            'Provide insight into the management practices that, on average, are most and least emphasized by organizations in a specified sector, region, etc.',
        preview: {
            mainImg: PracticeRankingSS,
            leftRemark: 'Expand to see Practice Scores',
            rightRemark: 'Choose multiple selection filter to for required results',
            rightImg: FilterDropdown,
        },
    },
];

export const upcomingFeatures = [
    {
        imgSrc: recipe,
        description: 'Recipe alignments by benchmarks',
    },
    {
        imgSrc: createBm,
        description: '​Create your own benchmark',
    },
    {
        imgSrc: automaticBox,
        description: '​Automatic year-end benchmark update',
    },
    {
        imgSrc: summaryBox,
        description: 'Summary of score changes by health quartiles',
    },
];

export const PAGE_WISE_HELP = {
    2: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'With this profile and comparison, you can identify which outcome and practice scores are relatively stronger or weaker in the selected sample compared to the median global benchmark score',
        },
        {
            helpQues: 'What are the scores ?',
            helpText: 'Median outcome and practices scores in the selected sample vs. global database.',
        },
    ],
    3: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'With this profile and comparison, you can identify which outcome and practice scores are relatively stronger or weaker in the selected sample compared to the median global benchmark score.',
        },
        {
            helpQues: 'What are the scores ?',
            helpText: 'Median outcome and practices scores in the selected sample vs. global database.',
        },
    ],
    4: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'On average, what practices are the most vs. least emphasized by organizations from the selected sample?',
        },
        {
            helpQues: 'What the numbers are ?',
            helpText: 'Practice rank for organizations from the selected sample.',
        },
    ],
    5: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'We are comparing the practice ranks for organizations falling in the top and bottom quartile of the selected sample. The difference between ranks tells us which practices most differentiate healthy from unhealthy organizations in the selected sample',
        },
        {
            helpQues: 'General rules of thumb to identify differentiators',
            helpText: '',
            helpTexts: [
                {
                    helpQues: 'Positive differentiators are practices healthy organizations uniquely emphasize.',
                    helpText:
                        'To easily identify them: Find practices that rank in the top 12 of healthy organizations and that have a difference of +12',
                },
                {
                    helpQues: 'Negative differentiators are practices that unhealthy organizations uniquely emphasize.',
                    helpText:
                        'To easily identify them: Select the practices that rank in the top 12  of unhealthy organizations and that have difference of -12',
                },
            ],
        },
    ],
    6: [
        {
            helpQues: 'What this analysis shows ?',
            helpText:
                'The scorecard shows the median scores of the surveys in the selected sample (global benchmark by default when you start). The scores change based on the filters applied to get at sub-benchmarks. The quartile colors change based on comparison of the median scores to the global benchmark',
        },
    ],
};

export const SITE_TEXT = {
    INTRO_BTN_TXT: "Let's Start",
    PREV: 'Prev',
    NEXT: 'Next',
    INTRO_HEAD: 'Welcome to the OHI Benchmark Explorer',
    INTRO_BMX: '(OHI BMX)',
    INTRO_TXT:
        'The OHI BMX includes insights from OHI surveys included in the global benchmarks. The dashboard allows teams using OHI surveys to deep-dive into global, sector, regional, and other sub-benchmarks to develop relevant and tailored insights for clients',
    INTRO_TXT_QUES: '​​What is GSOD? ',
    GSOD_OHI_STATS: 'OHI 2024 benchmark',
    GSOD_LEARN_TXT: 'How to navigate',
    MORE_TXT: 'And many more...',
    ERROR_DEFAULT_TXT: 'There is insufficient data to generate the desired result.',
    LOGIN_DEFAULT_TXT:
        'Practice ranking is not a useful analysis at the global level. Please apply any filters to run the analysis.',
    DIFFERENTIATOR_TEXT:
        'Differentiator analysis is not a useful analysis at the global level. Please apply any filters to run the analysis.',
    INSUFFICIENT_DATA: 'Insufficient Data',
    DATA_NOT_AVAILABLE: 'Data Not Available',
    QUICK_HELPT: 'What’s on this page?',
    YEAR: 'Year',
    BM_EXPLORER: 'Introduction to Benchmark Explorer',
    VIEW_ONLINE: 'VIEW ONLINE CLASS ',
    LEARN_MORE: 'To learn more about OHI Methodology, please refer to OHI Methodology e-Learning course',
    LEARN_MORE_LINK:
        'https://mckinseylearning.csod.com/samldefault.aspx?returnUrl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlodetails%2526lo%253d684aef4c-e57c-4d83-bd2a-d2351584451d',

    LINK_FOR_BMX_USER:
        'https://mckinsey.account.box.com/login?redirect_url=https%3A%2F%2Fmckinsey.ent.box.com%2Fs%2Fr5s8kf7i32limsdrdirzrbbtjwvzenpo',

    LINK_FOR_BMX_COMMUNITY: 'https://mckinsey.enterprise.slack.com/signin/find-workspaces',
    ADDITIONAL_DETAILS:
        "for additional details about this tool, including data sharing guidelines to protect the Firm's intellectual property.",
};

export const FLAGPOLE_LINK = {
    LEARN_MORE_FLAGPOLE_LINK:
        'https://mckinseylearning.csod.com/samldefault.aspx?returnUrl=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dlodetails%2526lo%253d684aef4c-e57c-4d83-bd2a-d2351584451d',
};

export const ANALYTICS_QUARTILE_COLORS = [
    {
        title: 'Top decile',
        background: '#061F79',
        border: '#FFA800',
        color: '#ffffff',
        top_position: '19000',
    },
    {
        title: 'Top quartile',
        background: '#061F79',
        border: '#061F79',
        color: '#ffffff',
        top_position: '23500',
    },
    {
        title: 'Second quartile',
        background: '#2251FF',
        border: '#2251FF',
        color: '#ffffff',
        top_position: '31000',
    },
    {
        title: 'Third quartile',
        background: '#99C4FF',
        border: '#99C4FF',
        color: '#000000',
        top_position: '41000',
    },
    {
        title: 'Bottom quartile',
        background: '#CCCCCC',
        border: '#CCCCCC',
        color: '#000000',
        top_position: '51000',
    },
    {
        title: 'Bottom decile',
        background: '#CCCCCC',
        border: '#757575',
        color: '#ffffff',
        top_position: '19000',
    },
];

export const NPS_TABLE_HEADERS = ['N', 'Promoters', 'Passives', 'Detractors', 'Net Score'];
export const ENGAGEMENT_TABLE_HEADERS = [' ', 'Size', 'Disagree', 'Agree', 'Neutral'];

export const GET_DATA_AT_GLANCE = 'GET_DATA_AT_GLANCE';
export const GET_DIFFERENTIAL = 'GET_DIFFERENTIAL';
export const GET_MEDIAN = 'GET_MEDIAN';
export const GET_ANALYTICS_FLAGPOLE = 'GET_GET_ANALYTICS_FLAGPOLE';
export const GET_ANALYTICS_SCORECARD = 'GET_ANALYTICS_SCORECARD';
export const GET_RANKING = 'GET_RANKING';
export const GET_ANALYTICS_CATEGORY = 'GET_ANALYTICS_CATEGORY';
export const GET_FILTERS_DATA = 'GET_FILTERS_DATA';
export const UPDATE_ANALYTICS_FILTERS = 'UPDATE_ANALYTICS_FILTERS';
export const UPDATE_SCORECARD_DROPDOWN = 'UPDATE_SCORECARD_DROPDOWN';
export const UPDATE_ANALYTICS_CATEGORY = 'UPDATE_ANALYTICS_CATEGORY';
export const UPDATE_DIFFERENTIAL_DATA = 'UPDATE_DIFFERENTIAL_DATA ';
export const DEFAULT_FILTER = 'year:2024';
export const GET_FILTERS_MAPPING = 'GET_FILTERS_MAPPING';
export const GET_DEFAULT_YEAR = 'GET_DEFAULT_YEAR';
export const SET_ORG_SIZE_DATA = 'SET_ORG_SIZE_DATA';
export const UPDATE_MEDIAN_DATA = 'UPDATE_MEDIAN_DATA';
export const SHOW_QUARTILE_TAB_DATA = 'SHOW_QUARTILE_TAB_DATA';
export const GET_DIFFERENTIAL_QUARTILE = 'GET_DIFFERENTIAL_QUARTILE';
export const SET_QUARTILE_TOP_BTN = 'SET_QUARTILE_TOP_BTN';
export const SET_MEDIAN_TOP_BTN = 'SET_MEDIAN_TOP_BTN';
export const GET_DIFFERENTIAL_MEDIAN = 'GET_DIFFERENTIAL_MEDIAN';
export const GET_CUSTOM_BM_LIST = 'GET_CUSTOM_BM_LIST';
export const GET_CUSTOM_BM_SURVEY_LIST = 'GET_CUSTOM_BM_SURVEY_LIST';
export const SAVE_CUSTOM_BM = 'SAVE_CUSTOM_BM';
export const GET_CUSTOM_BM_DETAIL = 'GET_CUSTOM_BM_DETAIL';
export const SET_CREATE_CUSTOM_BM_PAYLOAD = 'SET_CREATE_CUSTOM_BM_PAYLOAD';
export const SET_CUSTOM_BM_FILTER = 'SET_CUSTOM_BM_FILTER';
export const DELETE_CUSTOM_BM = 'DELETE_CUSTOM_BM';
export const UPDATE_CUSTOM_BM = 'UPDATE_CUSTOM_BM';
export const CHECK_NAME_EXIST = 'CHECK_NAME_EXIST';
export const SET_CUSTOM_BM_MSG = 'SET_CUSTOM_BM_MSG';
export const SEND_CUSTOM_BM_REQUEST = 'SEND_CUSTOM_BM_REQUEST';
export const SET_ACTION_CODE = 'SET_ACTION_CODE';
export const GET_APPROVAL_REQUESTS = 'GET_APPROVAL_REQUESTS';
export const GET_REJECTED_REQUESTS = 'GET_REJECTED_REQUESTS';
export const APPROVE_REQUEST = 'APPROVE_REQUEST';
export const REJECT_REQUEST = 'REJECT_REQUEST';
export const GET_APPROVED_REQUESTS = 'GET_APPROVED_REQUESTS';
export const SAVE_AND_PUBLISH = 'SAVE_AND_PUBLISH';
export const CREATE_CUSTOM_BM = {
    name: '',
    type: '',
    year: 2024,
    filter_str: '',
    survey_ids: [],
};
export const DUPLICATE_BM = 'DUPLICATE_BM';
export const SET_EDIT_CUSTOM_BM_PAYLOAD = 'SET_EDIT_CUSTOM_BM_PAYLOAD';
export const RESET_FORM = 'RESET_FORM';
export const PRE_VALIDATION_CHECK = 'PRE_VALIDATION_CHECK';
export const RESET_PRE_VALIDATION_CHECK = 'RESET_PRE_VALIDATION_CHECK';
