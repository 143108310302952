import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Button, FormControlLabel, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import c from 'classnames';

import { getStaticText } from '../../../utils/constants';
import { assignGraphColor, assignGraphColorOhi4, getKeyByValue } from '../../../utils/functions';

const DragDrop = ({ cors, res_BU, closeModal, closeAndUpdate, defaultSettings }) => {
    const { lang, survey_version } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { Nsize = {}, Header = {} } = res_BU;
    const [headerItems, updateHeader] = useState([]);
    const [hideScore, setScoreToggle] = useState(false);
    const [rowItems, updateRows] = useState([]);
    const { SAVE_GROUP, CANCEL_TEXT, DRAG_DROP_HEAD, SHOW_SCORE, N_SIZE_MAIN } = SITE_TEXT || {};
    const mainKeysIndex = Object.keys(cors);
    const isOhi4 = survey_version === '4' || survey_version === '3_2';

    const updateHeadWidth = () => {
        setTimeout(() => {
            const firstList = document.getElementById('row0');
            if (firstList) {
                document.getElementById('headDrop').style.width = `${firstList.offsetWidth}px`;
            }
        }, 100);
    };

    useEffect(() => {
        const mainKeys = Object.keys(cors);
        updateHeader(mainKeys);
        updateRows(mainKeys);
        updateHeadWidth();
        // eslint-disable-next-line
    }, []);

    const setToggle = toggleVal => {
        setScoreToggle(toggleVal);
        updateHeadWidth();
    };

    const reorder = (result, startIndex, endIndex) => {
        const newResult = [...result];
        const [removed] = newResult.splice(startIndex, 1);
        newResult.splice(endIndex, 0, removed);
        return newResult;
    };

    const sortHeader = result => {
        const { destination, source } = result;
        if (!destination || !source) return;
        const items = reorder(headerItems, result.source.index, result.destination.index);
        updateHeader(items);
        updateRows(items);
    };

    const sortRows = result => {
        const { destination, source } = result;
        if (!destination || !source) return;
        const items = reorder(rowItems, result.source.index, result.destination.index);
        updateHeader(items);
        updateRows(items);
    };

    const getHeading = (dataSet, dataSetIndex) => {
        const indexToDisplay = mainKeysIndex.findIndex(original => original === dataSet);
        return (
            <Draggable key={`head${dataSet}`} draggableId={`head${dataSet}`} index={dataSetIndex}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={c({ dragActive: snapshot.isDragging })}
                    >
                        <span>{indexToDisplay + 1}</span>
                        <h4>{dataSet}</h4>
                        {provided.placeholder}
                    </div>
                )}
            </Draggable>
        );
    };

    const getRow = (rowSet, rowSetIndex) => {
        const keyToUse = getKeyByValue(Header, rowSet);
        const nSize = keyToUse ? Nsize[keyToUse] : '';
        const indexToDisplay = mainKeysIndex.findIndex(original => original === rowSet);
        return (
            <Draggable key={`row${rowSet}`} draggableId={`row${rowSet}`} index={rowSetIndex}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={c({ dragActive: snapshot.isDragging })}
                    >
                        <div className="rowHead ellipsisPro">
                            <span>{indexToDisplay + 1}</span> {rowSet}
                        </div>
                        <span>{nSize}</span>
                        <ul id={`row${rowSetIndex}`}>
                            {headerItems.map((headerKey, headerIndex) => {
                                const score = cors[rowSet][headerKey];
                                const scoreToUse = rowSetIndex > headerIndex && !hideScore ? score : '\u00A0';
                                const { lineGraphColor: backgroundColor = '', textColor: color = '' } = !isOhi4
                                    ? assignGraphColor(score)
                                    : assignGraphColorOhi4(score);
                                const blockStyle = {
                                    backgroundColor: rowSetIndex > headerIndex && backgroundColor,
                                    color,
                                    border: '1px solid #ffffff',
                                };
                                const midBlock = {
                                    color: '#F2F2F2',
                                    backgroundColor: '#F2F2F2',
                                    border: '1px solid #ffffff',
                                };
                                return (
                                    <li
                                        className="contentList"
                                        style={rowSetIndex === headerIndex ? midBlock : blockStyle}
                                        // style={{
                                        //     backgroundColor: assignGraphColor(score).lineGraphColor,
                                        //     color: assignGraphColor(score).textColor,
                                        // }}
                                        key={headerKey}
                                    >
                                        {scoreToUse}
                                    </li>
                                );
                            })}
                        </ul>
                        {provided.placeholder}
                    </div>
                )}
            </Draggable>
        );
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            fullWidth
            maxWidth="sm"
            classes={{
                root: c('dragDropRoot', { hideScore }),
            }}
        >
            <section className="headContent">
                <h2>
                    <span>{DRAG_DROP_HEAD}</span>
                </h2>
                <FormControlLabel
                    control={
                        <Switch
                            checked={!hideScore}
                            onChange={() => setToggle(!hideScore)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={SHOW_SCORE}
                />
                <IconButton aria-label="close" className="closeButton" onClick={() => closeModal(false)}>
                    <CloseIcon />
                </IconButton>
            </section>

            <section className="upperHeader">
                <h4 className="nSizeHead">{N_SIZE_MAIN}</h4>

                <DragDropContext onDragEnd={sortHeader}>
                    <Droppable droppableId="headDrop" direction="horizontal">
                        {provided => (
                            <section
                                id="headDrop"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="headDrop"
                            >
                                {headerItems.map((dataSet, dataSetIndex) => getHeading(dataSet, dataSetIndex))}
                            </section>
                        )}
                    </Droppable>
                </DragDropContext>
            </section>

            <DragDropContext onDragEnd={sortRows}>
                <Droppable droppableId="rowDrop">
                    {provided => (
                        <section ref={provided.innerRef} {...provided.droppableProps} className="rowDrop">
                            {rowItems.map((dataSet, dataSetIndex) => getRow(dataSet, dataSetIndex))}
                        </section>
                    )}
                </Droppable>
            </DragDropContext>

            <section className="buttonContent clearfix">
                <Button data-testid="closeDrapModalBtn" onClick={() => closeModal(false)}>
                    {CANCEL_TEXT}
                </Button>
                <Button
                    data-testid="setSortingBtn"
                    onClick={() => closeAndUpdate(headerItems.join('$#$'), rowItems.join('$#$'))}
                >
                    {SAVE_GROUP}
                </Button>
            </section>
        </Dialog>
    );
};

DragDrop.propTypes = {
    closeAndUpdate: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    res_BU: PropTypes.object.isRequired,
    cors: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default DragDrop;
