import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import getStaticTextDiagnose from '../constants';
import { getStaticText } from '../../../utils/constants';
import { assignGraphColor, assignGraphColorOhi4, getKeyByValue } from '../../../utils/functions';
import ArrowLeft from '../assets/ArrowLeft.svg';
import ArrowRight from '../assets/ArrowRight.svg';
import '../DemographicBoard/index.scss';

const AcrossBus = ({ res_BU, cors = {}, defaultSettings }) => {
    const { Nsize = {}, Header = {} } = res_BU;
    const { lang, survey_version = '' } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { SLICE_MAX_VALUE, SLICE_MIN_VALUE } = staticTextDiagnose;
    const [minSliceValue, setMinSliceValue] = useState(0);
    const [maxSliceValue, setMaxSliceValue] = useState(SLICE_MAX_VALUE - SLICE_MIN_VALUE);
    const headers = Object.fromEntries(Object.entries(cors).slice(minSliceValue, maxSliceValue));
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';

    const { N_SIZE_MAIN } = SITE_TEXT || {};

    return (
        <div className="across-bus">
            <div className="demographicBoard">
                <section className="sectionWrapper">
                    <div id="myHeader" className="headerWrapper clearfix">
                        {minSliceValue > SLICE_MIN_VALUE ? (
                            <div
                                data-testid="leftArrow"
                                className="arrow"
                                onClick={() => {
                                    setMaxSliceValue(minSliceValue);
                                    setMinSliceValue(minSliceValue - (SLICE_MAX_VALUE - SLICE_MIN_VALUE));
                                }}
                            >
                                <img className="imgClass" src={ArrowLeft} alt="" />
                            </div>
                        ) : null}
                        <div>
                            <ul>
                                <li className="headerList bu-header">{N_SIZE_MAIN}</li>
                                {Object.keys(headers).map((val, i) => (
                                    <Tooltip key={`${val}${i}`} placement="top" arrow title={val} aria-label={val}>
                                        <li className="headerList bu-header">
                                            {val.substr(0, 18)}
                                            {val.length > 18 ? '...' : ''}
                                        </li>
                                    </Tooltip>
                                ))}
                            </ul>
                        </div>
                        {maxSliceValue < Object.keys(cors).length ? (
                            <div
                                data-testid="rightArrow"
                                className="arrow arrowRight"
                                onClick={() => {
                                    setMinSliceValue(maxSliceValue);
                                    setMaxSliceValue(maxSliceValue + (SLICE_MAX_VALUE - SLICE_MIN_VALUE));
                                }}
                            >
                                <img className="imgClass" src={ArrowRight} alt="" />
                            </div>
                        ) : null}
                    </div>
                </section>
                <div className="contentWrapper clearfix">
                    <div className="sideContent">
                        {Object.keys(cors).map((key, i) => {
                            return (
                                <div className="sectionContent" key={`side_${i}`}>
                                    <Tooltip key={`lefttitle${i}`} placement="right" arrow title={key} aria-label={key}>
                                        <span>
                                            {key.substr(0, 20)}
                                            {key.length > 20 ? '...' : ''}
                                        </span>
                                    </Tooltip>
                                </div>
                            );
                        })}
                    </div>
                    <div className="mainContent">
                        {Object.keys(cors).map((key, i) => {
                            const subData = Object.fromEntries(
                                Object.entries(cors[key]).slice(minSliceValue, maxSliceValue)
                            );
                            const keyToUse = getKeyByValue(Header, key);
                            const nSize = keyToUse ? Nsize[keyToUse] : '';
                            return (
                                <div key={`row${key}`}>
                                    <ul>
                                        <li className="contentList" key={`nsize${i}`}>
                                            {nSize}
                                        </li>
                                        {Object.keys(subData).map((subkey, subindex) => {
                                            const score = cors[key][subkey];
                                            const {
                                                lineGraphColor: backgroundColor = '',
                                                textColor: color = '',
                                            } = !isOhi4 ? assignGraphColor(score) : assignGraphColorOhi4(score);
                                            const blockStyle = {
                                                backgroundColor: i > subindex ? backgroundColor : 'none',
                                                color,
                                            };
                                            const midBlock = {
                                                color: '#F2F2F2',
                                                backgroundColor: '#F2F2F2',
                                                border: '1px solid #ffffff',
                                            };
                                            const scoreToUse = i > subindex ? score : '\u00A0';
                                            return (
                                                <li
                                                    className="contentList"
                                                    style={i === subindex ? midBlock : blockStyle}
                                                    key={`subscore_${i}${subindex}`}
                                                >
                                                    {scoreToUse}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

AcrossBus.propTypes = {
    res_BU: PropTypes.object.isRequired,
    cors: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};
export default AcrossBus;
