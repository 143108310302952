import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Select,
    TextField,
    MenuItem,
    Radio,
    FormGroup,
    RadioGroup,
    FormControlLabel,
    Switch,
    Checkbox,
} from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import c from 'classnames';
import { cloneDeep } from 'lodash';

import InfoIcon from '@material-ui/icons/Info';
import Benchmarks from '../../../components/FilterBar/Benchmarks';
import Demographics from '../../../components/FilterBar/Demographics';
import { getStaticText, USER_ROLES } from '../../../utils/constants';
import { updateFilterParams, getBenchmarkName, getBenchmarkString } from '../../../utils/functions';
import AdditionalSurvey from './AdditionalSurvey';
import CircleAdd from '../assets/CircleAdd.svg';
import { BUILD_REPORT_DISABLED_PAYLOAD, BUILD_REPORT_PAYLOAD, RESOURCE_TYPE } from '../constants';
import SelectBenchmark from '../../../components/SelectBenchmark/SelectBenchmark';
import BulkPastReport from './BulkPastReport';

function GeneralSetting({
    benchState,
    iaBenchState,
    getBenchmarks,
    getDemographics,
    handleBenchState,
    handleIaBenchState,
    submitReport,
    goBack,
    reportApiParams,
    projects,
    filters,
    resetFilterIndex,
    currentOhid,
    updateFilterIndex,
    defaultSettings,
    reportIndex,
    showBulk,
    setBulkToggle,
    bulkDemographics,
    updateBulkFilters,
    getResurveyMapping,
    resurveyDemographics,
    resurveyDemographicDataFetched,
    updateResurveyMapping,
    isCfgClient,
    isNonProfit,
    iaBenchmarkVersions,
    iaBmVersionInUse,
    setBmVersionInUse,
    allowOhi4Modules,
    additionalSettingsLength,
    apiParams,
    getBulkScoreData,
    scoreBulkData,
    setIsDemoChecked,
    isDemoChecked,
    updateNsizeDemo,
    setUpdateNsizeDemo,
    resetBulkAssign,
    targetBenchmarks,
    targetBm,
    scoreBulkDataFetched,
}) {
    const {
        lang: defaultLang,
        lang_data = [],
        ohid: current_ohid,
        ohi_text,
        survey_version = '',
        benchmark_year = '',
        role = '',
    } = defaultSettings;
    const staticText = getStaticText(defaultLang);
    const { SITE_TEXT, TR_OHID_2024, ING_RECENT_OHID } = staticText;
    const {
        BACK,
        NEXT,
        ENTER_COMPANY_NAME,
        ENTER_ORG_NAME,
        GENERAL_SETTINGS,
        BULK_REPORT,
        CHOOSE_BULK_UNITS,
        LANGUAGE,
        SUB_INFO_GENERAL,
        SUB_INFO_INCLUSION,
        BENCHMARK_QUARTILE_COLOR,
        BENCHMARK_YEAR,
        REPORT_PLACEHOLDER,
        CURRENT_REPORT_NAME,
        CURRENT_SURVEY_INFO,
        DATACUBE_TYPE,
        DYNAMIC_TEXT_INFO,
        NO_MAPPED_DEMO,
        BULK_RESURVEY_ERROR,
        BENCHMARK_VERSION,
        NUMBER_CAP_TEXT,
        NUMBER_CAP_SURVEY_TEXT,
        SELECT_BM_VERSION,
        OHI4_HEAD,
        OHI4_META,
        ADD_ANOTHER_SURVEY,
        BENCHMARK_TARGET_SETTING,
        BENCHMARK_TARGET_SETTING_SUB_INFO,
        APPLIED_BENCHMARK_TEXT,
    } = SITE_TEXT || {};
    const { REPORT_TYPE } = DYNAMIC_TEXT_INFO({ ohi_text });
    const {
        report_name: rname,
        lang: lng,
        year: yr,
        benchmarks: bchmrk,
        options: opt,
        additionalSettings: additionSettings,
        resource: resName,
        non_profit_report_full: non_profit_report_full_old,
        includeOhi4Demo: includeOhi4Demo_old,
        build_your_report = BUILD_REPORT_PAYLOAD,
    } = reportApiParams;
    const { ReportType } = opt;
    const { benchmarks: formattedBenchmark } = updateFilterParams([], benchState[0]) || {};
    const { benchmarks: formattedIaBenchmark } = updateFilterParams([], iaBenchState[0]) || {};
    const updatedBenchmarkName = getBenchmarkName(benchState[0]);
    const updatedIaBenchmarkName = getBenchmarkName(iaBenchState[0]);
    const resurveyInclusionReport = ReportType === '6';
    const enterpriseInclusionReport = ReportType === '4';
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    // const showBuildReportOp = isNonProfit || parseInt(ReportType, 10) > 3 || !isOhi4;
    // new
    const [optionsToUse, setOptionsToUse] = useState(targetBenchmarks);
    const [generalSettings, setGeneralSetting] = useState({
        report_name: rname || '',
        lang: lng || '',
        year: yr || '',
        benchmarks: bchmrk || formattedBenchmark,
        options: { BENCHMARK_NAME: updatedBenchmarkName },
        additionalSettings: additionSettings || [{ reportName: '', surveyType: '' }],
        resource: resName,
        non_profit_report_full: non_profit_report_full_old,
        includeOhi4Demo: includeOhi4Demo_old,
        benchmarks_target_setting: getBenchmarkName(optionsToUse),
    });
    const [iaGeneralSettings, setIaGeneralSetting] = useState({
        report_name: rname || '',
        lang: lng || '',
        year: yr || '',
        benchmarks: formattedIaBenchmark,
        options: { BENCHMARK_NAME: updatedIaBenchmarkName },
        additionalSettings: additionSettings || [{ reportName: '', surveyType: '' }],
        resource: resName,
    });
    const { report_name, lang, year, additionalSettings, resource, includeOhi4Demo } =
        ReportType === '4' || ReportType === '5' || ReportType === '6' || ReportType === '9'
            ? iaGeneralSettings
            : generalSettings;
    const [currentBulkDemo, updateBulkDemo] = useState([]);
    const bulkDemoSelected = useMemo(() => {
        if (!showBulk) {
            return false;
        }
        return currentBulkDemo.filter(({ options = [] }) => options.some(({ isSelected }) => isSelected)).length;
    }, [showBulk, currentBulkDemo]);
    const isActive =
        (ReportType === '0' && ((!showBulk && report_name.trim()) || bulkDemoSelected) && lang && year) ||
        (ReportType === '7' && report_name.trim() && lang && year) ||
        (ReportType === '8' && report_name.trim() && lang) ||
        (ReportType === '1' && lang && year) ||
        (ReportType === '4' && year && report_name.trim()) ||
        (ReportType === '6' &&
            year &&
            report_name.trim() &&
            !additionalSettings.filter(({ reportName, surveyType }) => !reportName.trim() || !surveyType).length) ||
        (ReportType === '5' && year && report_name.trim()) ||
        (ReportType === '9' && year && report_name.trim()) ||
        (ReportType === '3' &&
            lang &&
            year &&
            ((!showBulk && report_name.trim()) ||
                (bulkDemoSelected && additionalSettings.length < additionalSettingsLength) ||
                (additionalSettings.length === additionalSettingsLength && report_name.trim())) &&
            !additionalSettings.filter(({ surveyType }) => !surveyType).length);

    const [showBulkReport, setShowBulkReport] = useState(false);
    const resurveyBulkReportType = ReportType === '3';
    // const isNonRole = role === 'Roles.coach';
    // const showForNonProfit = isNonProfit && isNonRole;
    const thomsonOhid2024 = current_ohid === TR_OHID_2024;
    const ingReportOhid = currentOhid === ING_RECENT_OHID;
    const showBuildReportOp =
        isNonProfit || parseInt(ReportType, 10) > 3 || !isOhi4 || (ingReportOhid && ReportType === '3');

    const rolesHide = role === USER_ROLES.CLIENT || role === USER_ROLES.CST;
    const thomsonOhid = thomsonOhid2024 && ReportType === '1';
    useEffect(() => {
        updateBulkDemo(bulkDemographics);
        if (thomsonOhid) {
            const { benchmark = '' } = defaultSettings;
            const ohid = 'OHI13191';
            getDemographics({ ohid, lang }, 1);
            getBenchmarks({ ohid, year: 2023, lang }, 1, benchmark);
            updateFilterIndex(1);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (ReportType === '3') {
            updateBulkDemo(resurveyDemographics);
        }
        updateResurveyMapping();
        // eslint-disable-next-line
    }, [resurveyDemographicDataFetched]);

    useEffect(() => {
        setGeneralSetting({
            ...generalSettings,
            benchmarks_target_setting: getBenchmarkString(optionsToUse),
        });
    }, [optionsToUse]);

    const handleSettingsData = ({ name, value }) => {
        setGeneralSetting({
            ...generalSettings,
            [name]: value,
        });
    };

    const handleIaSettingsData = ({ name, value }) => {
        setIaGeneralSetting({
            ...iaGeneralSettings,
            [name]: value,
        });
    };

    const handleAddClickInclusion = () => {
        setIaGeneralSetting({
            ...iaGeneralSettings,
            additionalSettings: [...additionalSettings, { reportName: '', surveyType: '' }],
        });
    };

    const handleFilterState = (updatedDemoState, name) => {
        const { benchmarks: formattedBenchmarks } = updateFilterParams([], updatedDemoState);
        const benchName = getBenchmarkName(updatedDemoState);
        setGeneralSetting({
            ...generalSettings,
            [name]: formattedBenchmarks,
            options: {
                ...generalSettings.options,
                BENCHMARK_NAME: benchName,
            },
        });
        handleBenchState(updatedDemoState, 0);
    };

    const handleIaFilterState = (updatedDemoState, name) => {
        const { benchmarks: formattedIaBenchmarks } = updateFilterParams([], updatedDemoState);
        const benchName = getBenchmarkName(updatedDemoState);
        setIaGeneralSetting({
            ...iaGeneralSettings,
            [name]: formattedIaBenchmarks,
            options: {
                ...iaGeneralSettings.options,
                BENCHMARK_NAME: benchName,
            },
        });
        handleIaBenchState(updatedDemoState, 0);
    };

    const handleNext = () => {
        const updatedBenchmarks = cloneDeep(currentBulkDemo);
        const openedBenchmark = updatedBenchmarks.findIndex(({ isOpen: oldOpen }) => oldOpen);
        if (openedBenchmark !== -1) {
            updatedBenchmarks[openedBenchmark].isOpen = false;
        }
        if (!showBuildReportOp) {
            if (ReportType === '1') {
                const buPayload = {
                    ...build_your_report,
                    ...BUILD_REPORT_DISABLED_PAYLOAD,
                };
                reportApiParams.build_your_report = buPayload;
            }
        }
        updateBulkFilters(updatedBenchmarks, ReportType === '3');
        if (
            ReportType === '4' ||
            ReportType === '6' ||
            (ReportType === '5' && resource === 'inclusion') ||
            ReportType === '9'
        ) {
            submitReport(iaGeneralSettings);
        } else {
            submitReport(generalSettings);
        }
    };

    const isReportType = !!(ReportType === '0' || ReportType === '3');

    const handleAllData = () => {
        if (showBulk && !showBulkReport && isDemoChecked) {
            return setShowBulkReport(true);
        }
        return handleNext();
    };

    const handleNextForBulk = () => {
        if (isReportType) {
            handleAllData();
        }
        if (!isReportType) {
            handleNext();
        }
    };

    const handleAdditionalChange = ({ target: { name, value } }, index) => {
        const settings = [...additionalSettings];
        settings[index][name] = value;
        if (name === 'surveyType') {
            if (!value) return;
            const { benchmark = [] } = defaultSettings;
            const splitArr = value.split('+');
            const ohid = splitArr[0];
            const newYear = Number(splitArr[splitArr.length - 1]);
            getDemographics({ ohid, lang }, index + 1);
            getBenchmarks({ ohid, year: newYear, lang }, index + 1, benchmark);
            updateFilterIndex(index + 1);
            if (ReportType === '6' && index === 0) {
                getResurveyMapping({ current_ohid, previous_ohid: ohid, type: 'full' }, index);
            }
            if (ReportType !== '6') {
                getResurveyMapping({ current_ohid, previous_ohid: ohid, type: 'full' }, index);
            }
        }
        setGeneralSetting({
            ...generalSettings,
            additionalSettings: settings,
        });
    };

    const handleAddClick = () => {
        setGeneralSetting({
            ...generalSettings,
            additionalSettings: [...additionalSettings, { reportName: '', surveyType: '' }],
        });
    };

    const removeSection = (e, index) => {
        handleBenchState([], index + 1, true);
        resetFilterIndex({ indexToUpdate: index + 1 });
        setGeneralSetting({
            ...generalSettings,
            additionalSettings: [...additionalSettings.slice(0, index), ...additionalSettings.slice(index + 1)],
        });
        if (ReportType === '6') {
            setIaGeneralSetting({
                ...iaGeneralSettings,
                additionalSettings: [...additionalSettings.slice(0, index), ...additionalSettings.slice(index + 1)],
            });
        }
    };

    const hideBulk = isCfgClient || isNonProfit;

    return (
        <div data-testid="generalSettingsComponent">
            <section className="generalSection">
                <div className="generalDemoSection">
                    <h2>{REPORT_TYPE[reportIndex]} - </h2>
                    <h2>{GENERAL_SETTINGS}</h2>
                </div>
                <div className="generalSettingWrapper">
                    {!hideBulk && ReportType === '0' && (
                        <FormControlLabel
                            classes={{
                                root: 'bulkUpload',
                            }}
                            control={
                                <Switch
                                    checked={showBulk}
                                    onChange={() => setBulkToggle(!showBulk)}
                                    inputProps={{
                                        'aria-label': 'showBulkSwitch',
                                        'data-testid': 'showBulkSwitch',
                                    }}
                                />
                            }
                            label={BULK_REPORT}
                            labelPlacement="start"
                        />
                    )}
                    {showBulk && ReportType === '0' ? (
                        <div className="bulkDemoContent">
                            <h3>{CHOOSE_BULK_UNITS}</h3>
                            <Demographics
                                defaultSettings={defaultSettings}
                                demographics={currentBulkDemo}
                                updateDemographics={e => updateBulkDemo(e)}
                                isOhiReport={ReportType === '0'}
                                showBulk={showBulk}
                                setIsDemoChecked={setIsDemoChecked}
                                updateDisableDemo={updateNsizeDemo}
                            />
                        </div>
                    ) : (
                        (ReportType === '0' || ReportType > 3) && (
                            <div className="settingContent">
                                <span className="title">{isNonProfit ? ENTER_ORG_NAME : ENTER_COMPANY_NAME}</span>
                                <TextField
                                    autoComplete="false"
                                    name="report_name"
                                    value={report_name}
                                    onChange={({ target }) => {
                                        handleIaSettingsData(target);
                                        handleSettingsData(target);
                                    }}
                                    placeholder={REPORT_PLACEHOLDER}
                                    data-testid="reportName"
                                    className={c('reportNameContent companyNameContent', {
                                        reportDiv: report_name,
                                        companyWrapper: resurveyInclusionReport || enterpriseInclusionReport,
                                    })}
                                />
                            </div>
                        )
                    )}
                    {!isNonProfit && (
                        <div className="settingContent">
                            <span className="title">{LANGUAGE}</span>
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="lang"
                                data-testid="language"
                                value={lang}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                onChange={({ target }) => {
                                    handleSettingsData(target);
                                    handleIaSettingsData(target);
                                }}
                                className={c('langContent languageContent', {
                                    resurveyLang: resurveyInclusionReport || enterpriseInclusionReport,
                                })}
                            >
                                {lang_data.map(({ key, value }) => (
                                    <MenuItem
                                        data-testid="lang-option"
                                        classes={{ root: 'demographicOptions' }}
                                        key={key}
                                        value={parseInt(key, 10)}
                                    >
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )}

                    {ReportType === '6' ? (
                        <Fragment>
                            {additionalSettings.map(({ reportName, surveyType }, i) => (
                                <div key={i}>
                                    <AdditionalSurvey
                                        defaultSettings={defaultSettings}
                                        reportName={reportName}
                                        surveyType={surveyType}
                                        filters={filters}
                                        removeSection={removeSection}
                                        index={i}
                                        currentOhid={currentOhid}
                                        handleBenchState={handleBenchState}
                                        additionalSettings={additionalSettings}
                                        projects={projects}
                                        benchState={benchState}
                                        handleAdditionalChange={handleAdditionalChange}
                                        reportApiParams={reportApiParams}
                                    />
                                </div>
                            ))}
                            {additionalSettings.length !== additionalSettingsLength && (
                                <div className="addSection" onClick={handleAddClickInclusion}>
                                    <img src={CircleAdd} alt="" />
                                    <div>{ADD_ANOTHER_SURVEY}</div>
                                </div>
                            )}
                        </Fragment>
                    ) : null}
                    {ReportType === '4' || ReportType === '6' ? (
                        <Fragment>
                            <div className={c('settingContent', { surveyHeading: ReportType === '6' })}>
                                <span className="title">{BENCHMARK_YEAR}</span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharpIcon}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="year"
                                    value={year}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target }) => handleIaSettingsData(target)}
                                    className="langContent"
                                >
                                    <MenuItem classes={{ root: 'demographicOptions' }} disabled key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                </Select>
                            </div>
                            <div className="settingContent">
                                <span className="title">{BENCHMARK_VERSION}</span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharpIcon}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="year"
                                    value={iaBmVersionInUse}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target }) => {
                                        const { value = 2 } = target;
                                        setBmVersionInUse(value);
                                        handleIaSettingsData({ name: 'benchmark_sub_version_id', value });
                                    }}
                                    className={c('reportNameContent', { reportDiv: iaBmVersionInUse === 1 })}
                                >
                                    <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                        <em>{SELECT_BM_VERSION}</em>
                                    </MenuItem>
                                    {iaBenchmarkVersions.map(
                                        ({
                                            BenchmarkPreCalculationSubVersionId,
                                            BenchmarkPreCalculationSubVersionName,
                                            SurveyCount,
                                            RespondentCount,
                                        }) => {
                                            return (
                                                <MenuItem
                                                    key={BenchmarkPreCalculationSubVersionId}
                                                    classes={{ root: 'demographicOptions' }}
                                                    className="benchmarkList"
                                                    value={BenchmarkPreCalculationSubVersionId}
                                                >
                                                    <Fragment>
                                                        <span>
                                                            {NUMBER_CAP_TEXT} = {RespondentCount}
                                                        </span>
                                                        <span>
                                                            {NUMBER_CAP_SURVEY_TEXT} : {SurveyCount}
                                                        </span>
                                                        <span>{BenchmarkPreCalculationSubVersionName}</span>
                                                    </Fragment>
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </div>
                            <div className="settingContent">
                                <span className="title">{BENCHMARK_QUARTILE_COLOR}</span>
                                <div className="subInfo">{SUB_INFO_INCLUSION}</div>
                                <div className="rightSideFilters">
                                    <Benchmarks
                                        showHeader={false}
                                        benchmarks={iaBenchState[0]}
                                        defaultSettings={defaultSettings}
                                        isInclusion={ReportType === '4' || ReportType === '6'}
                                        updateBenchmarks={e => handleIaFilterState(e, 'benchmarks')}
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ) : null}
                    {ReportType > 3 || (ReportType === '0' && isNonProfit) ? null : (
                        <div className="settingContent">
                            <span className="title">{BENCHMARK_YEAR}</span>
                            <TextField
                                IconComponent={KeyboardArrowDownSharpIcon}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="year"
                                value={benchmark_year}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                onChange={({ target }) => handleSettingsData(target)}
                                className="benchInformation"
                            />
                            <div className="benchmarkContent">
                                <InfoIcon className="benchImg" />
                                <div className="benchInfo">{APPLIED_BENCHMARK_TEXT}</div>
                            </div>
                        </div>
                    )}
                </div>
                {ReportType > 3 ? null : (
                    <div className="gnLowerWrapper">
                        {ReportType === '3' && (
                            <div className="generalSettingWrapper settingContent">
                                <div className="titleHeading subHeading">{CURRENT_SURVEY_INFO}</div>
                                <span className="title">{CURRENT_REPORT_NAME}</span>
                                <TextField
                                    autoComplete="false"
                                    name="report_name"
                                    value={report_name}
                                    onChange={({ target }) => handleSettingsData(target)}
                                    placeholder={REPORT_PLACEHOLDER}
                                    data-testid="reportName"
                                />
                            </div>
                        )}

                        {!isNonProfit && (
                            <Fragment>
                                <div>
                                    <div className="title">{BENCHMARK_QUARTILE_COLOR}</div>
                                    <div className="subInfo textInfo">{SUB_INFO_GENERAL}</div>
                                    <div className="rightSideFilters benchContent">
                                        <Benchmarks
                                            defaultSettings={defaultSettings}
                                            showHeader={false}
                                            benchmarks={benchState[0] || []}
                                            updateBenchmarks={e => handleFilterState(e, 'benchmarks')}
                                        />
                                    </div>
                                </div>
                                {!rolesHide && !thomsonOhid && !ingReportOhid && (
                                    <>
                                        <div className="title">{BENCHMARK_TARGET_SETTING}</div>
                                        <div className="subInfo textInfo">{BENCHMARK_TARGET_SETTING_SUB_INFO}</div>
                                        <div className="settingContent">
                                            <SelectBenchmark
                                                benchmarks={targetBenchmarks}
                                                targetBm={targetBm}
                                                optionsToUse={optionsToUse}
                                                setOptionsToUse={setOptionsToUse}
                                            />
                                        </div>
                                    </>
                                )}
                            </Fragment>
                        )}
                    </div>
                )}
                {ReportType === '3' ? (
                    <Fragment>
                        {additionalSettings.map(({ reportName, surveyType }, i) => (
                            <div key={i}>
                                <AdditionalSurvey
                                    defaultSettings={defaultSettings}
                                    reportName={reportName}
                                    surveyType={surveyType}
                                    filters={filters}
                                    removeSection={removeSection}
                                    index={i}
                                    currentOhid={currentOhid}
                                    handleBenchState={handleBenchState}
                                    additionalSettings={additionalSettings}
                                    additionalSettingsLength={additionalSettingsLength}
                                    projects={projects}
                                    benchState={benchState}
                                    handleAdditionalChange={handleAdditionalChange}
                                    reportApiParams={reportApiParams}
                                    showBulk={showBulk}
                                />
                            </div>
                        ))}
                        {additionalSettings.length !== additionalSettingsLength && (
                            <div className="addSection" onClick={handleAddClick}>
                                <img src={CircleAdd} alt="" />
                                <div>{ADD_ANOTHER_SURVEY}</div>
                            </div>
                        )}
                    </Fragment>
                ) : null}
                {ReportType === '5' ? (
                    <Fragment>
                        <div className="dataCubeSettings">
                            <h3>{DATACUBE_TYPE}</h3>
                            <div className="reportWrapper resourceWrap">
                                <FormGroup classes={{ root: 'radioGroup' }}>
                                    <RadioGroup
                                        aria-label="ReportType"
                                        name="resource"
                                        value={resource}
                                        onChange={({ target }) => {
                                            handleSettingsData(target);
                                            handleIaSettingsData(target);
                                        }}
                                    >
                                        {RESOURCE_TYPE.map(({ label, value }) => (
                                            <FormControlLabel
                                                key={value}
                                                value={value}
                                                control={<Radio disableRipple />}
                                                label={label}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormGroup>
                            </div>
                        </div>
                        {resource === 'inclusion' ? (
                            <div className="generalSettingWrapper">
                                <div className="settingContent">
                                    <span className="title">{BENCHMARK_YEAR}</span>
                                    <Select
                                        IconComponent={KeyboardArrowDownSharpIcon}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="year"
                                        value={year}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        onChange={({ target }) => handleIaSettingsData(target)}
                                    >
                                        <MenuItem
                                            classes={{ root: 'demographicOptions' }}
                                            disabled
                                            key={year}
                                            value={year}
                                        >
                                            {year}
                                        </MenuItem>
                                    </Select>
                                </div>
                                <div className="settingContent">
                                    <span className="title">{BENCHMARK_VERSION}</span>
                                    <Select
                                        IconComponent={KeyboardArrowDownSharpIcon}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="year"
                                        value={iaBmVersionInUse}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        onChange={({ target }) => {
                                            const { value = 2 } = target;
                                            setBmVersionInUse(value);
                                            handleIaSettingsData({ name: 'benchmark_sub_version_id', value });
                                        }}
                                    >
                                        <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                            <em>{SELECT_BM_VERSION}</em>
                                        </MenuItem>
                                        {iaBenchmarkVersions.map(
                                            ({
                                                BenchmarkPreCalculationSubVersionId,
                                                BenchmarkPreCalculationSubVersionName,
                                                SurveyCount,
                                                RespondentCount,
                                            }) => {
                                                return (
                                                    <MenuItem
                                                        key={BenchmarkPreCalculationSubVersionId}
                                                        classes={{ root: 'demographicOptions' }}
                                                        className="benchmarkList"
                                                        value={BenchmarkPreCalculationSubVersionId}
                                                    >
                                                        <Fragment>
                                                            <span>
                                                                {NUMBER_CAP_TEXT} = {RespondentCount}
                                                            </span>
                                                            <span>
                                                                {NUMBER_CAP_SURVEY_TEXT} : {SurveyCount}
                                                            </span>
                                                            <span>{BenchmarkPreCalculationSubVersionName}</span>
                                                        </Fragment>
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                </div>
                                <div className="settingContent">
                                    <span className="title">{BENCHMARK_QUARTILE_COLOR}</span>
                                    <div className="subInfo">{SUB_INFO_GENERAL}</div>
                                    <div className="rightSideFilters">
                                        <Benchmarks
                                            showHeader={false}
                                            benchmarks={iaBenchState[0]}
                                            defaultSettings={defaultSettings}
                                            isInclusion
                                            updateBenchmarks={e => handleIaFilterState(e, 'benchmarks')}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {resource === 'ohi_data_cube' ? (
                            <Fragment>
                                <div className="generalSettingWrapper">
                                    <div className="settingContent">
                                        <span className="title">{BENCHMARK_YEAR}</span>
                                        <Select
                                            IconComponent={KeyboardArrowDownSharpIcon}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="year"
                                            value={year}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                transformOrigin: {
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                            }}
                                            onChange={({ target }) => handleSettingsData(target)}
                                        >
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                disabled
                                                key={year}
                                                value={year}
                                            >
                                                {year}{' '}
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div className="gnLowerWrapper">
                                    <div className="title">{BENCHMARK_QUARTILE_COLOR}</div>
                                    <div className="subInfo">{SUB_INFO_GENERAL}</div>
                                    <div className="rightSideFilters">
                                        <Benchmarks
                                            defaultSettings={defaultSettings}
                                            showHeader={false}
                                            benchmarks={benchState[0] || []}
                                            updateBenchmarks={e => handleFilterState(e, 'benchmarks')}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ) : null}
                    </Fragment>
                ) : null}

                {!hideBulk && ReportType === '3' && (
                    <FormControlLabel
                        classes={{
                            root: 'bulkUpload',
                        }}
                        control={
                            <Switch
                                checked={showBulk}
                                onChange={() => setBulkToggle(!showBulk)}
                                inputProps={{
                                    'aria-label': 'showBulkSwitchResurvey',
                                }}
                            />
                        }
                        label={BULK_REPORT}
                        labelPlacement="start"
                    />
                )}
                {showBulk && ReportType === '3' ? (
                    <Fragment>
                        {additionalSettings.length < 2 ? (
                            <div className="bulkDemoContent">
                                {!currentBulkDemo.length ? (
                                    <div className="noMapDemo">{NO_MAPPED_DEMO}</div>
                                ) : (
                                    <Fragment>
                                        <h3>{CHOOSE_BULK_UNITS}</h3>
                                        <Demographics
                                            defaultSettings={defaultSettings}
                                            demographics={currentBulkDemo}
                                            updateDemographics={e => updateBulkDemo(e)}
                                            isOhiReport={ReportType === '3'}
                                            showBulk={showBulk}
                                            setIsDemoChecked={setIsDemoChecked}
                                            updateDisableDemo={updateNsizeDemo}
                                        />
                                    </Fragment>
                                )}
                            </div>
                        ) : (
                            <div className="noMapDemo">{BULK_RESURVEY_ERROR}</div>
                        )}
                    </Fragment>
                ) : null}

                {showBulkReport && isReportType && (
                    <BulkPastReport
                        showBulk={showBulk}
                        showBu={showBulkReport}
                        defaultSettings={defaultSettings}
                        submitReport={submitReport}
                        isOhiReport={ReportType === '0' || ReportType === '3'}
                        currentBulkDemo={currentBulkDemo}
                        getBulkScoreData={getBulkScoreData}
                        scoreBulkData={scoreBulkData}
                        updateBulkDemo={updateBulkDemo}
                        apiParams={apiParams}
                        setShowBu={setShowBulkReport}
                        bulkDemographics={bulkDemographics}
                        setDemoChecked={setIsDemoChecked}
                        checkDemoChecked={isDemoChecked}
                        setUpdateNsizeDemo={setUpdateNsizeDemo}
                        updateBulkFilters={updateBulkFilters}
                        resurveyDemographics={resurveyDemographics}
                        resurveyBulkReportType={resurveyBulkReportType}
                        resetBulkAssign={resetBulkAssign}
                        scoreBulkDataFetched={scoreBulkDataFetched}
                        demoGraphicState={[]}
                    />
                )}

                {(ReportType === '0' || ReportType === '1' || ReportType === '3') && allowOhi4Modules && !isOhi4 && (
                    <FormControlLabel
                        classes={{
                            root: 'ohi4CheckWrap',
                        }}
                        control={
                            <Checkbox
                                name="includeOhi4Demo"
                                checked={includeOhi4Demo}
                                onChange={({ target }) => {
                                    const { name, checked: value } = target;
                                    handleSettingsData({ name, value });
                                }}
                            />
                        }
                        label={
                            <span>
                                {OHI4_HEAD}
                                <span className="metaInfo">{OHI4_META}</span>
                            </span>
                        }
                        labelPlacement="end"
                    />
                )}
                <div className="buttonWrapperReports">
                    <div className="outlineBtn" onClick={goBack}>
                        {BACK}
                    </div>
                    <div className={c('blueButton', { active: isActive })} onClick={handleNextForBulk}>
                        {NEXT}
                    </div>
                </div>
            </section>
        </div>
    );
}

GeneralSetting.defaultProps = {
    handleIaBenchState: () => {},
    iaBenchmarkVersions: [],
    iaBmVersionInUse: 2,
    setBmVersionInUse: () => {},
};

GeneralSetting.propTypes = {
    benchState: PropTypes.array.isRequired,
    handleBenchState: PropTypes.func.isRequired,
    submitReport: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    resurveyDemographics: PropTypes.array.isRequired,
    isCfgClient: PropTypes.bool.isRequired,
    resurveyDemographicDataFetched: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    currentOhid: PropTypes.string.isRequired,
    resetFilterIndex: PropTypes.func.isRequired,
    updateFilterIndex: PropTypes.func.isRequired,
    reportIndex: PropTypes.number.isRequired,
    handleIaBenchState: PropTypes.func,
    iaBenchState: PropTypes.array.isRequired,
    showBulk: PropTypes.bool.isRequired,
    updateBulkFilters: PropTypes.func.isRequired,
    setBulkToggle: PropTypes.func.isRequired,
    getResurveyMapping: PropTypes.func.isRequired,
    updateResurveyMapping: PropTypes.func.isRequired,
    bulkDemographics: PropTypes.array.isRequired,
    isNonProfit: PropTypes.bool.isRequired,
    allowOhi4Modules: PropTypes.bool.isRequired,
    additionalSettingsLength: PropTypes.number.isRequired,
    iaBenchmarkVersions: PropTypes.array,
    iaBmVersionInUse: PropTypes.number,
    setBmVersionInUse: PropTypes.func,
    apiParams: PropTypes.object.isRequired,
    getBulkScoreData: PropTypes.func.isRequired,
    scoreBulkData: PropTypes.object.isRequired,
    isDemoChecked: PropTypes.bool.isRequired,
    updateNsizeDemo: PropTypes.array.isRequired,
    setUpdateNsizeDemo: PropTypes.func.isRequired,
    setIsDemoChecked: PropTypes.func.isRequired,
    resetBulkAssign: PropTypes.func.isRequired,
    targetBenchmarks: PropTypes.array.isRequired,
    targetBm: PropTypes.string.isRequired,
    scoreBulkDataFetched: PropTypes.bool.isRequired,
};
export default GeneralSetting;
