import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { assignGraphColor, assignGraphColorOhi4 } from '../../../utils/functions';

const AcrossPractices = ({ practices, defaultSettings }) => {
    const { Header = {}, Nsize = {}, correlations = {} } = practices;
    const { survey_version } = defaultSettings;
    const isOhi4 = survey_version === '4' || survey_version === '3_2';

    return (
        <div className="across-practices">
            {Object.keys(Header).map(key => {
                const { lineGraphColor } = isOhi4
                    ? assignGraphColorOhi4(correlations[key])
                    : assignGraphColor(correlations[key]);
                return (
                    <Grid container spacing={3} key={key} className="practice-row">
                        <Grid item xs={3}>
                            {Header[key]}
                        </Grid>
                        <Grid item xs={6}>
                            <div className="practice-graph">
                                <div
                                    className="graph"
                                    style={{
                                        width: `${correlations[key] * 100}%`,
                                        backgroundColor: lineGraphColor,
                                    }}
                                />
                                <div className="score">
                                    {correlations[key]} <small>(N = {Nsize[key]})</small>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                );
            })}
        </div>
    );
};

AcrossPractices.propTypes = {
    practices: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};
export default AcrossPractices;
