import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import c from 'classnames';
import { Helmet } from 'react-helmet';
import moment from 'moment';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Tooltip } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import AuthStore from '../../../common/AuthStore';
import SelectModal from '../../../components/SelectModal';
import MetaChartModal from '../MetaChartModal';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import { getPptValueForStyles, getBenchmarkName, getPptValues, replacePssKeys } from '../../../utils/functions';
import colors from '../../../sass/colors';
import FilterSvg from '../assets/Filter.svg';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import BulletList from '../assets/BulletList.svg';
import Target from '../assets/Target.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import Group from '../assets/Group.svg';
import Down from '../assets/Down.svg';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import ProfileInActive from '../assets/Profile_inactive1.svg';
import ProfileModal from '../Profile/ProfileModal';
import DisclaimerWarning from '../../Analytics/assets/DisclaimerWarning1.svg';

function Scoreboard({
    scoreData,
    scoreDataFetched,
    getScoreData,
    toggleFilterView,
    apiParams,
    apiLoadingCount,
    cohesionScore,
    cohesionDemographic,
    setReport,
    callReSurveyApi,
    setChartData,
    projects,
    projectsFetched,
    selectedIds,
    activeResetFilter,
    resetBuDataFetched,
    errorInfo,
    getPptData,
    filters,
    isDragDisabled,
    currentTheme,
    toggleCreateTheme,
    defaultSettings,
    pulseSurvey,
    appliedFiltersInfo,
    getClientEngagementData,
    clientEngagement,
    clientEngagementFetched,
    getReport,
    getRawPptData,
    reportError,
    singlePastReport,
    getSinglePastReport,
    localPractice,
    // targetBenchmarks,
}) {
    const { demographics = [] } = filters[0] || {};
    const [showMetaChart, setShowMetaChart] = useState(false);
    const [showReSurveyModal, setReSurveyModal] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [isPracticeMeta, setPraticeMeta] = useState(false);
    const [cohesionText, setCohesionText] = useState('');
    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const [showAccessScoreCard, setShowAccessScoreCard] = useState(true);

    const {
        quartileColors,
        report_name = '',
        report_name_or_client_name: rName = '',
        template_name: template_file_name,
        lang,
        engagement = '',
        ohi_text = '',
        report_id = '',
        survey_version = '',
        checkme_mapping = {},
        demographic = '',
        is_ohi_pss_module,
        ohid,
        year,
        report_type,
        valid_till = '',
        role: userRole = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { recipeImages } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const {
        SITE_TEXT,
        NON_PROFIT_SURVEY,
        BLANCO_DEFAULT_DEMO,
        BANCO_REPORT_ID,
        ING_REPORT_ID,
        SINGLE_PAGE_EXPORT_PARAMS,
        SCORECARD_REPLACEMENTS,
        USER_ROLES,
        // PERCENT_FAVORABLE,
        // PERCENTILES,
        ING_RECENT_OHID,
    } = staticText;

    const updatedCheckmeMapping = replacePssKeys(
        checkme_mapping,
        is_ohi_pss_module,
        SCORECARD_REPLACEMENTS,
        'scorecard'
    );

    const {
        SCORECARD,
        OUTCOMES_TEXT,
        FILTERS,
        BENCHMARK_HEAD,
        STRONG_TEXT,
        VERY_STRONG_TEXT,
        NO_ALIGNMENT,
        RESURVEY_TEXT,
        COMPARE_BU,
        DOWNLOAD,
        RECIPE_NOTE,
        COHESION_SCORE,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        PPT_VALUES,
        DYNAMIC_TEXT_INFO,
        BENCHMARK_NA,
        ORG_TITLE,
        SCORECARD_GROUP_TEXTS,
        SCORECARD_GROUP_HEAD,
        HEALTH_SCORECARD,
        SURVEY_NAME,
        DEMOGRAPHIC,
        TOTAL_RESPONDENTS,
        PROFILE_VIEW,
        PERCENT_FAVORABLE,
        PERCENTILES,
        NOTE,
        TARGET_SETTING,
        ACCESS_EXPIRE,
        OHI_VALADITY,
    } = SITE_TEXT || {};
    const { OHI_SCORE_TEXT_PPT } = DYNAMIC_TEXT_INFO({ ohi_text });
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const isCST = userRole === USER_ROLES.CST;
    const isClient = userRole === USER_ROLES.CLIENT;
    const { qbyq = {}, recipe = {} } = scoreData;
    const { records = {}, size = '', n_respondents, n_survey } = qbyq;
    const { ohi_score, ...dataToIterate } = records;
    const [dwnld, setdwnld] = useState(false);
    const receipeKeys = Object.keys(recipe);
    const highScoreKey = receipeKeys.length
        ? receipeKeys.reduce((a, b) => (recipe[a].score > recipe[b].score ? a : b))
        : '';
    const highScoreKeyIndex = receipeKeys.findIndex(item => item === highScoreKey);
    const isBanco = report_id === BANCO_REPORT_ID;
    const headerOld = [ohi_score, { score: cohesionScore, title: COHESION_SCORE }, recipe[highScoreKey]];
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
    };

    const [ohiLoginCountNum, setohiLoginCountNum] = useState('1');

    // new
    const hideForRecentIng = ohid === ING_RECENT_OHID;

    const handleOhiClose = () => {
        setShowAccessScoreCard(false);
        setohiLoginCountNum('2');
        // setohiLoginCountNum(null);
    };

    useEffect(() => {
        if (ohiLoginCountNum === '2') {
            AuthStore.ohiloginNumber = ohiLoginCountNum || '1';
        }
    }, [ohiLoginCountNum]);

    const newohiLogin = AuthStore.ohiloginNumber === null || AuthStore.ohiloginNumber === '3';

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAccessScoreCard(false);
            setohiLoginCountNum('2');
        }, 9000);
        return () => clearTimeout(timer);
    }, []);

    // console.log("newOhiLogin", ne);

    // useEffect(() => {
    //     if (ohiLoginCountNum === '2') {
    //         AuthStore.ohiloginNumber = ohiLoginCountNum || '1';
    //     }
    // }, [ohiLoginCountNum]);

    const AccessScoreCard = () => {
        const formattedDate = moment(valid_till).format('DD-MMM-YYYY');
        return (
            <div className="accessDateContent">
                <div className="accessFormat">
                    <img src={DisclaimerWarning} alt="" />
                    <div className="accessText">{ACCESS_EXPIRE} : </div>
                    <div className="accessDate">{formattedDate}</div>
                </div>
                <div className="accessValidity">
                    <div>{OHI_VALADITY}</div>
                    <div onClick={() => handleOhiClose()}>
                        <CloseIcon />
                    </div>
                </div>
            </div>
        );
    };

    const headerVersion = [
        ohi_score,
        { score: cohesionScore, title: COHESION_SCORE },
        { score: size.toLocaleString('en-US'), title: TOTAL_RESPONDENTS },
    ];
    const headerContent = isNonProfit ? headerOld.slice(0, 2) : headerOld;
    const version = survey_version;

    const headerContents = version === '4' ? headerVersion : headerContent;
    const filterCheck = apiParams.filters || [];

    useEffect(() => {
        toggleCreateTheme(true);
        return () => {
            toggleCreateTheme(false);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!scoreDataFetched && !apiLoadingCount) {
            getScoreData(apiParams);
        }
        // if (scoreDataFetched) {
        //     setTargetSettingBm(benchmarks_target_setting);
        // }
        // eslint-disable-next-line
    }, [scoreDataFetched, apiParams]);

    useEffect(() => {
        if (!clientEngagementFetched && isBanco) {
            getClientEngagementData({
                ...apiParams,
                type: 'outcome',
                ...addOns,
                demographic: BLANCO_DEFAULT_DEMO,
            });
        }
        // eslint-disable-next-line
    }, [clientEngagementFetched]);

    const toggleMetaDataPopup = (modalState = false, scores = {}, isPractice = false) => {
        setShowMetaChart(modalState);
        setMetaData(scores);
        setPraticeMeta(isPractice);
    };

    const selectProject = (ohId, reportType) => {
        setReSurveyModal(false);
        callReSurveyApi([ohId]);
        setReport(reportType);
    };

    const getStylesArr = () => {
        const styleArr = [];
        quartileColors.forEach(({ background = '', border = '', color = '' }, index) => {
            if (index === 0) {
                styleArr.push({
                    bgcolor: background,
                    text_color: color,
                    border_color: 'FFFFFF',
                    gradient_stoplist: [
                        {
                            position: 3000,
                            bgcolor: border,
                        },
                        {
                            position: 5000,
                            bgcolor: background,
                        },
                    ],
                });
            } else {
                styleArr.push({
                    border_color: 'FFFFFF',
                    text_color: color,
                    bgcolor: background,
                });
            }
        });
        styleArr.push({
            border_color: 'FFFFFF',
            text_color: '000000',
            bgcolor: 'FFFFFF',
        });
        return styleArr;
    };

    const SCORECARD_SILDES_CHECKME = ['scorecard', 'highlevel'];
    const ING_SCORECARD_SLIDES_CHECKME = ['outcome_profile_checkme', 'practice_profile_checkme'];
    const isIngClient = report_id === parseInt(ING_REPORT_ID, 10);

    // new

    const getPpt = (data = [], highLevelData) => {
        const { pptAccessToken = '' } = AuthStore;
        const checkme = isIngClient ? ING_SCORECARD_SLIDES_CHECKME : SCORECARD_SILDES_CHECKME;
        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides: [
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: checkme[0],
                    data,
                    styles: getStylesArr(),
                },
                {
                    identify_shape_name: 'Checkme',
                    identify_shape_value: checkme[1],
                    data: highLevelData,
                    styles: getStylesArr(),
                },
            ],
        };
        getPptData(output, pptAccessToken, `${report_name} Scorecard`);
    };

    const getDataForPpt = () => {
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const { quartile: ohiQuartile, score: ohiInnerScore, score_percentile = '' } = ohi_score;
        const { background: bgcolor, border: border_color } = quartileColors[ohiQuartile] || {};
        const keysToRemove = receipeKeys
            .filter(rec => rec !== highScoreKey)
            .map(fRec => `${fRec}_image`)
            .join(',');
        const { score: recScore = '', title = '' } = recipe[highScoreKey];
        const pre = recScore >= 7 ? VERY_STRONG_TEXT : STRONG_TEXT;
        const end = recScore >= 5 ? pre : NO_ALIGNMENT;
        const { GLOBAL_VALS, highlevel, scorecard } = PPT_VALUES({ rName });
        const commonEle = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${size}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            {
                name: 'recipe_alignment_text',
                value: RECIPE_NOTE,
            },
            {
                name: 'Engagetxt',
                is_delete: 'true',
            },
            {
                name: 'EnageGrp',
                is_delete: 'true',
            },
            ...getPptValues(GLOBAL_VALS),
        ];

        if (isIngClient) {
            commonEle.push({
                name: 'footnote',
                value: localPractice,
            });
        }

        const pptArray = [
            {
                name: 'Title_text',
                value: scorecard.title,
            },
            {
                name: 'outcome_practice_hdr',
                value: OUTCOMES_TEXT,
            },
            {
                name: 'ohi_score_big',
                value: score_percentile || ohiInnerScore,
                text_color: bgcolor,
            },
            {
                name: 'ohi_score_big_left',
                bgcolor,
                border_color,
            },
            {
                name: 'ohi_score_big_right',
                bgcolor,
                border_color,
            },
            {
                name: 'talent_knowledge_core_text',
                value: title,
            },
            {
                name: keysToRemove,
                is_delete: 'true',
            },
            {
                name: `${highScoreKey}_image`,
                top_position: '16038',
                left_position: '104123',
            },
            {
                name: 'strong_text',
                value: `${end}: ${recScore}`,
            },
            ...commonEle,
            {
                name: 'ohi_score_text',
                value: OHI_SCORE_TEXT_PPT,
            },
        ];

        const highLevelData = [...commonEle, ...getPptValues(highlevel)];

        Object.keys(dataToIterate).forEach(innerContent => {
            const valueInRecord = records[innerContent];
            const { children = {} } = valueInRecord;
            const parentArray = getPptValueForStyles(quartileColors, valueInRecord, innerContent);
            pptArray.push(...parentArray);
            highLevelData.push(...parentArray);
            Object.keys(children).forEach(childVal => {
                const valueInChild = children[childVal];
                const { range_end, range_start } = valueInChild;
                const childArray = [
                    {
                        name: 'Title_text',
                        value: scorecard.title,
                    },
                    ...getPptValueForStyles(quartileColors, valueInChild, childVal, isIngClient),
                ];
                pptArray.push(...childArray);
                highLevelData.push(...childArray, {
                    name: `${childVal}_quartile`,
                    value: `${range_start}-${range_end}`,
                });
            });
        });
        getPpt(pptArray, highLevelData);
    };

    const getHeading = (dataSet, dataSetIndex) => {
        const { display_name, score, score_percentile = '', quartile } = dataToIterate[dataSet];
        const { background, color, border: borderColor = colors.$grey200 } = quartileColors[quartile] || {};

        return (
            <Draggable draggableId={dataSet} index={dataSetIndex} isDragDisabled={isDragDisabled}>
                {(provided, snapshot) => (
                    <Fragment>
                        <h4
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            data-testid={dataSet}
                            className={c('ellipsisPro', { active: currentTheme.includes(dataSet) })}
                            onClick={() => toggleMetaDataPopup(true, dataToIterate[dataSet])}
                        >
                            <div
                                style={{
                                    background: isOhi4
                                        ? `linear-gradient(135deg, ${borderColor} 25%, ${background} 25%)`
                                        : background,
                                    color,
                                    borderColor: isOhi4 ? 'transparent' : borderColor,
                                    borderWidth: quartile === '' ? 1 : 2,
                                    borderStyle: isOhi4 ? 'none' : 'solid',
                                }}
                                className="triangleOutcome"
                            >
                                <div>{score_percentile || score}</div>
                            </div>
                            {display_name}
                            {provided.placeholder}
                        </h4>
                        {snapshot.isDragging && (
                            <h4 className="ellipsisPro">
                                <span style={{ background, color, borderColor }}>{score_percentile || score}</span>
                                {display_name}
                            </h4>
                        )}
                    </Fragment>
                )}
            </Draggable>
        );
    };

    const v4PptDownload = () => {
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            report_id,
            single_page_meta: {
                checkme: updatedCheckmeMapping.scorecard,
                name: 'scorecard',
            },
        };
        getReport(valueToSend);
        setdwnld(true);
    };

    const getListItems = (childSet, childInd, children) => {
        const { display_name, score, score_percentile = '', quartile } = children[childSet];
        const { background, color, border: borderColor = colors.$grey200 } = quartileColors[quartile] || {};
        const ohi4Border = isOhi4 && quartile !== '999';
        return (
            <Draggable draggableId={childSet} key={childSet} index={childInd} isDragDisabled={isDragDisabled}>
                {(provided, snapshot) => (
                    <Fragment>
                        {score === '-' ? (
                            <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                data-testid={childSet}
                                style={{
                                    background,
                                    color,
                                    border: '1px solid #e6e6e6',
                                    ...provided.draggableProps.style,
                                }}
                                className={c('ellipsisPro', { active: currentTheme.includes(childSet) })}
                                onClick={() => toggleMetaDataPopup(true, children[childSet], true)}
                            >
                                {display_name}
                                <span>{score_percentile || score}</span>
                                {provided.placeholder}
                            </li>
                        ) : (
                            <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                data-testid={childSet}
                                style={{
                                    background: ohi4Border
                                        ? `linear-gradient(135deg, ${borderColor} 5%, ${background} 5%)`
                                        : background,
                                    color,
                                    borderColor: ohi4Border ? 'transparent' : borderColor,
                                    borderWidth: quartile === '' ? 1 : 2,
                                    borderStyle: ohi4Border ? 'none' : 'solid',
                                    ...provided.draggableProps.style,
                                }}
                                className={c('ellipsisPro', { active: currentTheme.includes(childSet) })}
                                onClick={() => toggleMetaDataPopup(true, children[childSet], true)}
                            >
                                <div>
                                    {display_name}
                                    <span>{score_percentile || score}</span>
                                    {provided.placeholder}
                                </div>
                            </li>
                        )}

                        {snapshot.isDragging && (
                            <li style={{ background, color, borderColor }} className="ellipsisPro">
                                {display_name}
                                <span>{score_percentile || score}</span>
                            </li>
                        )}
                    </Fragment>
                )}
            </Draggable>
        );
    };

    const oldSelectedId = selectedIds.length ? selectedIds[0] : '';

    useEffect(() => {
        const value = demographics.filter(({ code }) => {
            return code === cohesionDemographic ? code : null;
        });
        setCohesionText(value[0]?.label);
    }, [demographics, cohesionDemographic]);

    return (
        <>
            {showAccessScoreCard && newohiLogin && Object.keys(scoreData).length ? (
                <div>
                    <div>{AccessScoreCard()}</div>
                </div>
            ) : null}
            <div className="scoreBoard clearfix" data-testid="scoreBoardContent">
                <Helmet>
                    <title>{HEALTH_SCORECARD}</title>
                </Helmet>

                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">
                            {isNonProfit ? ORG_TITLE : rName} {SCORECARD}
                        </div>
                        <div className="nRes">
                            {SURVEY_NAME} :
                            <span>
                                {ohid} ({year})
                            </span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {report_type !== 'percentile' && !isCST && !isClient ? (
                            <li
                                data-testid="setReSurveyModalBtn"
                                onClick={() => {
                                    setReport(5);
                                }}
                                className="fImage resurveyImg"
                            >
                                <img src={Target} alt="" />
                                <span className="upperHead">{TARGET_SETTING}</span>
                            </li>
                        ) : null}
                        <li
                            data-testid="setReSurveyModalBtn"
                            onClick={() => setReSurveyModal(true)}
                            className="fImage resurveyImg"
                        >
                            <img src={BulletList} alt="" />
                            <span className="upperHead">{RESURVEY_TEXT}</span>
                        </li>
                        <li
                            data-testid="setBuReportBtn"
                            onClick={() => {
                                resetBuDataFetched(false);
                                setReport(4);
                            }}
                            className="resurveyImg"
                        >
                            <img src={Group} alt="" />
                            <span className="upperHead">{COMPARE_BU}</span>
                        </li>
                        {!errorInfo && (
                            <li
                                data-testid="getPptBtn"
                                onClick={isOhi4 ? v4PptDownload : getDataForPpt}
                                className="resurveyImg"
                            >
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img
                                src={activeResetFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg}
                                alt=""
                            />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>

                {errorInfo && <ErrorToast message={errorInfo} />}
                {dwnld && !reportError && (
                    <DwnldModal
                        getRawPptData={getRawPptData}
                        reportId={report_id}
                        open={dwnld}
                        setOpen={setdwnld}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        defaultSettings={defaultSettings}
                    />
                )}
                {Object.keys(scoreData).length ? (
                    <Fragment>
                        {appliedFiltersInfo()}
                        <ul className={c('headContent', 'headList', 'cardsList', { pulseSurvey, isBanco })}>
                            {headerContents.map(
                                (
                                    { display_name = '', score = 0, score_percentile = '', title = '', quartile = '' },
                                    index
                                ) => {
                                    const background = quartileColors[quartile]
                                        ? quartileColors[quartile].background
                                        : colors.$white;
                                    const color = quartileColors[quartile]
                                        ? quartileColors[quartile].color
                                        : colors.$darkBlue100;
                                    const borderColor = quartileColors[quartile]
                                        ? quartileColors[quartile].border
                                        : colors.$white;
                                    const ohi4Styles = {
                                        background: isOhi4
                                            ? `linear-gradient(135deg, ${borderColor} 5%, ${background} 5%)`
                                            : background,
                                        color,
                                        borderColor: isOhi4 ? 'transparent' : borderColor,
                                        borderWidth: quartile === '' ? 1 : 2,
                                        borderStyle: isOhi4 ? 'none' : 'solid',
                                    };

                                    const strong = score >= 7 ? VERY_STRONG_TEXT : STRONG_TEXT;
                                    const subHeader = score >= 5 ? strong : NO_ALIGNMENT;
                                    if (isBanco && index === 0) {
                                        const { title: clientTitle = '', score: clientScore = '' } =
                                            clientEngagement || {};
                                        return (
                                            <Fragment>
                                                <li
                                                    data-testid={`headContent${index}`}
                                                    key={index}
                                                    className="cardList"
                                                    style={{
                                                        background: isOhi4
                                                            ? `linear-gradient(135deg, ${borderColor} 5%, ${background} 5%)`
                                                            : background,
                                                        color,
                                                        borderColor: isOhi4 ? 'transparent' : borderColor,
                                                        borderWidth: quartile === '' ? 1 : 2,
                                                        borderStyle: isOhi4 ? 'none' : 'solid',
                                                    }}
                                                >
                                                    <h3 className="headScore">{score_percentile || score}</h3>
                                                    <div className="ellipsisPro">
                                                        <span className="scoreContent" style={{ color }}>
                                                            {display_name || title}
                                                        </span>
                                                    </div>
                                                </li>
                                                <li
                                                    style={{
                                                        background: colors.$white,
                                                        color: colors.$black,
                                                    }}
                                                    className="cardList"
                                                >
                                                    <h3>{clientScore}</h3>
                                                    <div className="ellipsisPro" style={{ color: colors.$black }}>
                                                        <span className="scoreContent">{clientTitle}</span>
                                                    </div>
                                                </li>
                                            </Fragment>
                                        );
                                    }

                                    return (
                                        <li
                                            data-testid={`headContent${index}`}
                                            key={index}
                                            style={!index ? ohi4Styles : { background, color }}
                                            onClick={() => {
                                                if (index === 2 && survey_version !== '4') {
                                                    setChartData(recipe, highScoreKey);
                                                }
                                            }}
                                            className={c(
                                                { isNonProfit: (isNonProfit || hideForRecentIng) && index === 1 },
                                                'cardList'
                                            )}
                                        >
                                            <h3 className="headScore">
                                                {score_percentile.toLocaleString('en-US') ||
                                                    score.toLocaleString('en-US')}
                                            </h3>
                                            <div className="ellipsisPro">
                                                {index === 2 && survey_version !== '4' && <span>{RECIPE_NOTE}</span>}
                                                {index === 2 && survey_version !== '4' && <div>{subHeader}</div>}
                                                <span className="scoreContent">{display_name || title}</span>
                                                {COHESION_SCORE === title && (
                                                    <div className="cohesion-label-container">
                                                        <Tooltip title={cohesionText} placement="right" arrow>
                                                            <div className="cohesion-label">
                                                                {DEMOGRAPHIC}: {cohesionText}
                                                            </div>
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                            {index === 2 && survey_version !== '4' && (
                                                <img src={recipeImages[highScoreKeyIndex]} alt="" />
                                            )}
                                        </li>
                                    );
                                }
                            )}
                        </ul>

                        <div className="outcomeProfileContent">
                            <h3 className="outcomeText">{OUTCOMES_TEXT}</h3>
                            <div className="profileTabContent" onClick={() => setShowProfileDialog(true)}>
                                <img src={ProfileInActive} alt="" />
                                <span className="profileTab">{PROFILE_VIEW}</span>
                            </div>
                        </div>
                        {isOhi4 ? (
                            SCORECARD_GROUP_TEXTS.map((text, index) => {
                                const obj =
                                    index === 0
                                        ? Object.keys(dataToIterate).slice(0, 3)
                                        : Object.keys(dataToIterate).slice(3, 7);
                                const objToUse = index === 2 ? Object.keys(dataToIterate).slice(7) : obj;

                                return (
                                    <div className="scoreDatacard">
                                        <h3>
                                            <strong>{SCORECARD_GROUP_HEAD[index]}</strong> {text}
                                        </h3>
                                        <section className="scoreData clearfix">
                                            {objToUse.map((dataSet, dataSetIndex) => {
                                                const { children = {} } = dataToIterate[dataSet];
                                                return (
                                                    <Droppable isDropDisabled key={dataSet} droppableId={dataSet}>
                                                        {provided => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                className="scoreTile clearfix"
                                                            >
                                                                {getHeading(dataSet, dataSetIndex)}
                                                                <ul>
                                                                    {Object.keys(children).map((childSet, childInd) =>
                                                                        getListItems(childSet, childInd, children)
                                                                    )}
                                                                </ul>
                                                                {provided.placeHolder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                );
                                            })}
                                        </section>
                                    </div>
                                );
                            })
                        ) : (
                            <section className="scoreData clearfix">
                                {Object.keys(dataToIterate).map((dataSet, dataSetIndex) => {
                                    const { children = {} } = dataToIterate[dataSet];
                                    return (
                                        <Droppable isDropDisabled key={dataSet} droppableId={dataSet}>
                                            {provided => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    className="scoreTile clearfix"
                                                >
                                                    {getHeading(dataSet, dataSetIndex)}
                                                    <ul>
                                                        {Object.keys(children).map((childSet, childInd) =>
                                                            getListItems(childSet, childInd, children)
                                                        )}
                                                    </ul>
                                                    {provided.placeHolder}
                                                </div>
                                            )}
                                        </Droppable>
                                    );
                                })}
                            </section>
                        )}
                        {isIngClient && !hideForRecentIng ? (
                            <div>
                                <div>{localPractice}</div>
                            </div>
                        ) : null}
                        {isOhi4 ? (
                            <>
                                {report_type === 'percent favorable' && (
                                    <div>
                                        <b>{NOTE} : </b> {PERCENT_FAVORABLE}
                                    </div>
                                )}
                                {report_type === 'percentile' && (
                                    <div>
                                        <b>{NOTE} : </b> {PERCENTILES}
                                    </div>
                                )}
                            </>
                        ) : null}
                    </Fragment>
                ) : null}
                {showMetaChart && (
                    <MetaChartModal
                        metaData={metaData}
                        closeModal={toggleMetaDataPopup}
                        isPracticeMeta={isPracticeMeta}
                        totalSize={size.toLocaleString('en-US')}
                        defaultSettings={defaultSettings}
                    />
                )}
                {showReSurveyModal && (
                    <SelectModal
                        defaultSettings={defaultSettings}
                        oldSelectedId={oldSelectedId}
                        projects={projects}
                        projectsFetched={projectsFetched}
                        closeModal={setReSurveyModal}
                        selectProject={selectProject}
                    />
                )}

                {showProfileDialog && (
                    <ProfileModal
                        defaultSettings={defaultSettings}
                        showDialog={showProfileDialog}
                        setShowProfileDialog={setShowProfileDialog}
                        totalProfileSize={size.toLocaleString('en-US')}
                        profileData={dataToIterate}
                        errorInfo={errorInfo}
                        apiParams={apiParams}
                        getReport={getReport}
                        scoreData={scoreData}
                        singlePastReport={singlePastReport}
                        getPptData={getPptData}
                        reportError={reportError}
                        getRawPptData={getRawPptData}
                        getSinglePastReport={getSinglePastReport}
                        filters={filters}
                    />
                )}
            </div>

            {/* new  */}
            {isOhi4 ? (
                <>
                    <ul className="benchmark clearfix fixedScoreCardSurvey">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === 'Bottom decile';
                                const BenchmarkTitle = title === 'No benchmarks';
                                return (
                                    <li
                                        key={title}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                        <li>
                            <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                            {BENCHMARK_NA}
                        </li>
                    </ul>
                </>
            ) : (
                <ul className="benchmark clearfix fixedScoreCardSurvey">
                    <li>{BENCHMARK_HEAD} : </li>
                    {quartileColors
                        .slice()
                        .reverse()
                        .map(({ title, background, border: borderColor }) => {
                            const bottomDecileVar = title === 'Bottom decile';
                            const BenchmarkTitle = title === 'No benchmarks';
                            return (
                                <li
                                    key={title}
                                    style={{ color: background }}
                                    className={c({
                                        hide: bottomDecileVar || BenchmarkTitle,
                                    })}
                                >
                                    <span
                                        className="benchmarkTriangle"
                                        style={{
                                            background,
                                            borderWidth: '13px 13px 0 0',
                                            border: borderColor,
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <div style={{ color: 'black' }}>{title}</div>
                                </li>
                            );
                        })}
                    <li>
                        <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                        {BENCHMARK_NA}
                    </li>
                </ul>
            )}
        </>
    );
}

Scoreboard.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    currentTheme: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    cohesionScore: PropTypes.number.isRequired,
    cohesionDemographic: PropTypes.string.isRequired,
    scoreData: PropTypes.object.isRequired,
    pulseSurvey: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    isDragDisabled: PropTypes.bool.isRequired,
    filters: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    getScoreData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
    callReSurveyApi: PropTypes.func.isRequired,
    setChartData: PropTypes.func.isRequired,
    resetBuDataFetched: PropTypes.func.isRequired,
    toggleCreateTheme: PropTypes.func.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getClientEngagementData: PropTypes.func.isRequired,
    clientEngagement: PropTypes.object.isRequired,
    clientEngagementFetched: PropTypes.bool.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    localPractice: PropTypes.string.isRequired,
    // new
    reportsData: PropTypes.array.isRequired,
    newItem: PropTypes.string.isRequired,
};

export default Scoreboard;
