import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    getSingleQuestion,
    setSelectedDemographic,
    getIeHeatmap,
    getSinglePastReport,
    setAddOnTabs,
    resetEmpExpResDataFetched,
} from '../actions';
import { getReport, getRawPptData } from '../../Reports/actions';

import {
    selectApiCount,
    getErrorInfo,
    selectFilters,
    selectFiltersApplied,
    selectScoreData,
    selectSingleQuestion,
    selectSingleQuestionFetched,
    selectApiParams,
    selectSelectedDemographic,
    selectPastReport,
    selectAddOnTabs,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import IndExperience from './IndExperience';
import { selectReportError } from '../../Reports/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    filters: selectFilters(),
    activeResetFilter: selectFiltersApplied(),
    scoreData: selectScoreData(),
    singleQuestion: selectSingleQuestion(),
    singleQuestionFetched: selectSingleQuestionFetched(),
    apiParams: selectApiParams(),
    selectedDemographics: selectSelectedDemographic(),
    reportError: selectReportError(),
    singlePastReport: selectPastReport(),
    addOnTab: selectAddOnTabs(),
});

const dispatchToProps = {
    getSingleQuestion,
    setSelectedDemographic,
    getIeHeatmap,
    getReport,
    getRawPptData,
    getSinglePastReport,
    setAddOnTabs,
    resetEmpExpResDataFetched,
};

export default connect(stateToProps, dispatchToProps)(IndExperience);
