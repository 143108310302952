import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    getIeHeatmap,
    sortIeHeatmap,
    getEmpExpResurveyHeatmapData,
    getDemographics,
    getBenchmarks,
    updateHeatMapInfo,
    getMappedDemographics,
    setSelectedDemographic,
    updateFilters,
    resetEmpExpResDataFetched,
} from '../../actions';

import {
    selectApiCount,
    getErrorInfo,
    selectFilters,
    selectSelectedDemographic,
    selectIeHeatmap,
    selectIeHeatmapFetched,
    selectEmpExpResurveyHeatmapData,
    selectEmpExpResurveyHeatmapDataFetched,
    selectApiParams,
    selectProjects,
    selectHeatMapResurveyInfo,
} from '../../selector';

import HeatmapBoard from './HeatmapBoard';
import { getDefaultSettings } from '../../../Login/selector';

const stateToProps = createStructuredSelector({
    apiLoadingCount: selectApiCount(),
    errorInfo: getErrorInfo(),
    filters: selectFilters(),
    selectedDemographics: selectSelectedDemographic(),
    ieHeatmap: selectIeHeatmap(),
    ieHeatmapFetched: selectIeHeatmapFetched(),
    defaultSettings: getDefaultSettings(),
    empExpResurveyHeatmapData: selectEmpExpResurveyHeatmapData(),
    empExpResurveyHeatmapDataFetched: selectEmpExpResurveyHeatmapDataFetched(),
    apiParams: selectApiParams(),
    projects: selectProjects(),
    heatMapResurveyInfo: selectHeatMapResurveyInfo(),
});

const dispatchToProps = {
    getIeHeatmap,
    sortIeHeatmap,
    getEmpExpResurveyHeatmapData,
    // resetReSurveyDataFetched,
    getDemographics,
    getBenchmarks,
    updateHeatMapInfo,
    getMappedDemographics,
    setSelectedDemographic,
    updateFilters,
    resetEmpExpResDataFetched,
};

export default connect(stateToProps, dispatchToProps)(HeatmapBoard);
