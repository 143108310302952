import React from 'react';
import PropTypes from 'prop-types';
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area, ResponsiveContainer, Label } from 'recharts';
import moment from 'moment';
import { getStaticText } from '../../utils/constants';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const { payload: currentData = {}, value = [0, 0], name = '', color = '#000000' } = payload[0] || {};
        const header = moment(label).format('dddd, MMM Do, YYYY');
        return (
            <div
                className="custom-tooltip"
                style={{ background: '#ffffff', borderRadius: '4px', padding: '5px', border: '1px solid #027ab1' }}
            >
                <p className="label">{`${header}`}</p>
                <p className="intro">
                    <span style={{ color }}>●</span> {name}:{' '}
                    <b>
                        {currentData.completed} / {currentData.total} ({value[1]}%)
                    </b>
                </p>
            </div>
        );
    }

    return null;
};

const MyChart = ({ data, defaultSettings }) => {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { RESPONSE_RATE_CUMULATIVE } = SITE_TEXT || {};

    return (
        <ResponsiveContainer width="100%" height={600}>
            <AreaChart width={1200} height={700} data={data}>
                <CartesianGrid strokeLinejoin="miter" />
                <XAxis dataKey="date" tickFormatter={tick => `${moment(tick).format('DD-MMM')}`}>
                    <Label value="Complete" offset={0} position="insideBottom" />
                </XAxis>
                <YAxis
                    domain={[0, 100]}
                    tickCount={11}
                    tickFormatter={tick => `${tick}%`}
                    label={{ value: RESPONSE_RATE_CUMULATIVE, angle: -90, position: 'insideLeft' }}
                />
                <Area dataKey="Completed" stroke="#027ab1" fill="#027ab1" />
                <Tooltip content={<CustomTooltip />} />
                {/* <Line type="monotone" dataKey="value" name="People" stroke="#8884d8" dot={false} /> */}
            </AreaChart>
        </ResponsiveContainer>
    );
};

MyChart.propTypes = {
    data: PropTypes.array.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

CustomTooltip.propTypes = {
    active: PropTypes.bool.isRequired,
    payload: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
};

export default MyChart;
