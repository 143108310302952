import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import AuthStore from '../../common/AuthStore';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import { getStaticText } from '../../utils/constants';
import './index.scss';
import ErrorToast from '../../components/InformationToast/ErrorToast';
import ArrowRight from './assets/arrowRight.svg';
// import up from './assets/up.svg';
import searchBar from './assets/zoom.svg';
import smallDown from './assets/smallDown.svg';
import DisclaimerWarning from '../Analytics/assets/DisclaimerWarning1.svg';

function UserReports({
    errorInfo,
    apiLoadingCount,
    loginLoadingCount,
    reportsData,
    reportsDataFetched,
    getReports,
    updateApiParams,
    defaultSettings,
    fetchDefaultSettings,
    loginErrorInfo,
}) {
    const staticText = getStaticText();
    const { SITE_TEXT, USER_ROLES } = staticText;
    const {
        REPORT_TEXT,
        REPORT_VIEW,
        BU_REPORT_TEXT,
        SURVEY_ID,
        SURVEY_NAME,
        COACH_TEXT,
        YR_TEXT,
        NO_ACCESS,
        ANALYTICS_LANDING_PAGE,
        ACTIONS,
        ANALYTICS_TEXT,
        EXPLORE_DASHBOARD,
        EXPLORER_TEXT,
        EXPLORE_PARAGRAPH,
        EXPLORE,
        ACTION_TEXT,
        ACTION_PARAGRAPH,
        SURVEY_PID,
        // REPORT_ID,
    } = SITE_TEXT;
    const { CLIENT, CST, USER } = USER_ROLES;
    const USERS_IN_CHECK = [CLIENT, CST, USER];
    const [doRedirect, redirectPage] = useState('');
    const [errorMsg, updateError] = useState('');
    const [expandedList, updateExpandedList] = useState([0]);
    const [makeRedirect, setRedirectParam] = useState(false);
    const [redirectBenchmarkExplorer, setRedirectBenchmarkExplorer] = useState(false);
    const [redirectActionTab, setRedirectActionTab] = useState(false);
    const { accessToken, userRole } = AuthStore;
    const helpdeskMail = 'OHI-Helpdesk@mckinsey.com';

    const [showTabAction, setShowAction] = useState(true);
    const [showBenchTab, setShowBenchTab] = useState(true);
    const [searchInput, setSearchInput] = useState('');
    const reportUserLength = Object.keys(reportsData).length || {};
    const [filteredData, setFilteredData] = useState([]);
    const { gsodAccess = false, actionAccess = false } = AuthStore;

    useEffect(() => {
        if (Object.keys(reportsData).length > 0) {
            setFilteredData(reportsData);
        }
    }, [reportsData]);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
        },
    }))(Tooltip);

    const normalizeString = str => {
        return str.toLowerCase().replace(/\s+/g, '');
    };

    const handleSearchChange = e => {
        const { value } = e.target;
        setSearchInput(value);
        const normalizedInput = normalizeString(value);

        const filtered = {};
        Object.keys(reportsData).forEach(key => {
            const items = reportsData[key].filter(
                item =>
                    normalizeString(item.report_name).includes(normalizedInput) ||
                    normalizeString(item.survey_id).includes(normalizedInput)
            );
            if (items.length > 0) {
                filtered[key] = items;
            }
        });

        setFilteredData(filtered);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        updateExpandedList(newList);
    };

    useEffect(() => {
        if (!reportsDataFetched && accessToken) {
            getReports();
        }
        AuthStore.deleteOhId();
        AuthStore.deleteReportId();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { ohid, health_access, response_rate_access } = defaultSettings;
        if (ohid && makeRedirect) {
            if (USERS_IN_CHECK.includes(userRole)) {
                if (health_access) {
                    redirectPage('/diagnose');
                } else if (response_rate_access) {
                    redirectPage('/response-rate');
                } else {
                    scrollToTop();
                    updateError(NO_ACCESS);
                }
            } else {
                redirectPage('/diagnose');
            }
        }
        // eslint-disable-next-line
    }, [defaultSettings]);

    // const newItem = null;

    const selectReport = (ohid, report_id, survey_name) => {
        AuthStore.ohid = ohid;
        AuthStore.report_id = report_id;
        AuthStore.buName = BU_REPORT_TEXT;
        AuthStore.practiceBU = BU_REPORT_TEXT;
        AuthStore.surveyName = survey_name;
        // new
        AuthStore.ohiloginNumber = '3';

        updateApiParams({ ohid });
        fetchDefaultSettings({ ohid });
        setTimeout(() => {
            // setShowBenchTab(false);
            // setShowAction(false);
            // AuthStore.ohiloginNumber = null;
            setRedirectParam(true);
        }, 1);
    };

    useEffect(() => {
        if (redirectActionTab) {
            redirectPage('/action');
        }
    }, [redirectActionTab]);

    useEffect(() => {
        if (redirectBenchmarkExplorer) {
            redirectPage('/gsod');
        }
    }, [redirectBenchmarkExplorer]);

    const selectAction = () => {
        setTimeout(() => {
            setShowAction(true);
            setRedirectActionTab(true);
        }, 1);
    };

    const selectBenchmark = useCallback(() => {
        setTimeout(() => {
            setShowBenchTab(true);
            setRedirectBenchmarkExplorer(true);
        }, 1);
    }, []);

    useEffect(() => {
        if (gsodAccess && !actionAccess && reportUserLength <= 0) {
            selectBenchmark();
        }
    }, [gsodAccess, actionAccess, reportUserLength, selectBenchmark]);

    return (
        <Layout showHeader showTabAction={showTabAction} showBenchTab={showBenchTab}>
            <div className="exploreSection">
                <div className="exploreHeading">{EXPLORE_DASHBOARD}</div>
                <div className="exploreBox">
                    {gsodAccess === 'true' && (
                        <div className="exploreContent">
                            <div className="benchmarkHeading">{EXPLORER_TEXT}</div>
                            <div className="exploreParagraph">{EXPLORE_PARAGRAPH}</div>
                            <div className="exploreBtn" onClick={selectBenchmark}>
                                <div className="explore">{EXPLORE}</div>
                                <img src={ArrowRight} alt="" />
                            </div>
                        </div>
                    )}

                    {actionAccess === 'true' && (
                        <div className="exploreContent">
                            <div className="benchmarkHeading">{ACTION_TEXT}</div>
                            <div className="exploreParagraph">{ACTION_PARAGRAPH}</div>
                            <div
                                className="exploreBtn"
                                onClick={() => {
                                    selectAction();
                                }}
                            >
                                <div className="explore">{EXPLORE}</div>
                                <img src={ArrowRight} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Helmet>
                <title>{ANALYTICS_LANDING_PAGE}</title>
            </Helmet>
            <div className="userReports clearfix">
                {apiLoadingCount || loginLoadingCount ? (
                    <Loader position="absolute" showBackground background="transparent" />
                ) : null}

                {reportUserLength > 0 && (
                    <div className="analyticsBox">
                        <div className="analyticsHeading">{ANALYTICS_TEXT}</div>
                        <div className="searchBar">
                            <input
                                type="text"
                                placeholder="Search Organization name, Survey ID or OHI ID"
                                value={searchInput}
                                onChange={handleSearchChange}
                                className="search"
                            />
                            <div className="searchIcon">
                                <img src={searchBar} alt="" />
                            </div>
                        </div>
                    </div>
                )}
                {errorInfo && loginErrorInfo && <ErrorToast message={errorInfo} />}
                {errorMsg && (
                    <div className="error">
                        {errorMsg} (<a href={`mailto:${helpdeskMail}`}>{helpdeskMail}</a>)
                    </div>
                )}
                {Object.keys(filteredData).map((dataSet, index) => {
                    const { report_id = '', report_name = '', valid_till = '' } = filteredData[dataSet][0];
                    const currentDate = moment();
                    const targetDate = moment(valid_till);

                    const isWithinOneMonth =
                        targetDate.diff(currentDate, 'months') < 1 && targetDate.isAfter(currentDate);

                    return (
                        <div className="userReportsContent">
                            <Accordion
                                classes={{ root: 'accordionRoot' }}
                                key={report_id}
                                expanded={expandedList.includes(index)}
                            >
                                <AccordionSummary
                                    data-testid={`Accordion${report_id}_${index}`}
                                    expandIcon={<img src={smallDown} alt="" />}
                                    onClick={() => updateExpanded(index)}
                                    classes={{ content: 'accordionHead' }}
                                >
                                    <div className="userContent">
                                        <div>{report_name || REPORT_TEXT}</div>
                                        {isWithinOneMonth && (
                                            <LightTooltip
                                                title="Reach out to OHI Helpdesk to extend your validity"
                                                arrow
                                            >
                                                <div className="userAccessDate">
                                                    <img src={DisclaimerWarning} alt="" />
                                                    <div className="accessDate">Access expires : </div>
                                                    <div className="formatDate">{targetDate.format('DD-MMM-YYYY')}</div>
                                                </div>
                                            </LightTooltip>
                                        )}
                                    </div>
                                    <div className="reportIdHead">{report_id}</div>
                                </AccordionSummary>
                                <AccordionDetails classes={{ root: 'clearfix' }}>
                                    <div className="userSection">
                                        <div className="userContent">
                                            <div className="headerSection">
                                                <div className="headerHeading">{SURVEY_ID}</div>
                                                <div className="headerHeading">{SURVEY_PID}</div>
                                                <div className="headerHeading">{SURVEY_NAME}</div>
                                                <div className="headerHeading">{COACH_TEXT}</div>
                                                <div className="headerHeading">{YR_TEXT}</div>
                                                <div className="headerHeading">{ACTIONS}</div>
                                            </div>

                                            {filteredData[dataSet].map(
                                                (
                                                    {
                                                        coach_name = '',
                                                        survey_id = '',
                                                        survey_name = '',
                                                        survey_pid = '',
                                                        year = '',
                                                    },
                                                    indexRep
                                                ) => {
                                                    return (
                                                        <div key={survey_id} className="userDataSection">
                                                            <div className="userTableContent">{survey_id}</div>
                                                            <div className="userTableContent">{survey_pid}</div>
                                                            <div className="userTableContent">{survey_name}</div>
                                                            <div className="userTableContent">{coach_name}</div>
                                                            <div className="userTableContent">{year}</div>
                                                            <div
                                                                data-testid={`reportTab${report_id}${indexRep}`}
                                                                onClick={() =>
                                                                    selectReport(survey_id, report_id, survey_name)
                                                                }
                                                                className="arrowContent"
                                                            >
                                                                <div className="reportView">{REPORT_VIEW}</div>
                                                                <div>
                                                                    <img src={ArrowRight} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    );
                })}
                {doRedirect && <Redirect to={doRedirect} />}
            </div>
        </Layout>
    );
}

UserReports.propTypes = {
    apiLoadingCount: PropTypes.number.isRequired,
    loginLoadingCount: PropTypes.number.isRequired,
    reportsData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    reportsDataFetched: PropTypes.bool.isRequired,
    errorInfo: PropTypes.string.isRequired,
    getReports: PropTypes.func.isRequired,
    updateApiParams: PropTypes.func.isRequired,
    fetchDefaultSettings: PropTypes.func.isRequired,
    loginErrorInfo: PropTypes.string.isRequired,
};

export default UserReports;
